import React, { useEffect } from "react";
import { bool, func, shape } from "prop-types";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { compose } from "redux";
import * as i18n from "util/i18n";
import classNames from "classnames";
import Button from "pages/_components/Button";
import { goBack, replace } from "react-router-redux";
import { PREFIX } from "./hooks/servicePaymentFormHooks";
import Info from "pages/_components/Info";

export const FORM_ID = "servicePayments.registeredServices.list";

const RegisteredServiceDetail = ({ isDesktop, service, dispatch, serviceDetail }) => {

    const {
        category,
        alias,
        companyLabel,
        agreementLabel,
        identificationLabel,
        identificationValue,
        dueDate,
        clientName,
    } = serviceDetail;

    const handleBack = () => {
        dispatch(goBack());
    };

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <Head title={"servicePayments.registeredServices.detail.title"} textBack="servicePayments.return.enrolled" onBack={handleBack} />
            <MainContainer
                className={classNames(
                    "main-container border-radius-lg box-shadow-small background-white texture-header service-payments",
                    {
                        "mb-login-layout": isDesktop,
                    },
                    {
                        "p-3": !isDesktop,
                    },
                )}>
                <Box className="mt-8 mb-5">
                    <Text bold size="6" labelKey="servicePayments.massive.pay.detail.service.title" />
                </Box>
                <Box>

                    <Info
                        labelKey="servicePayments.pay.category"
                        //labelText="Nombre"
                        text={i18n.get(`servicePayment.category.${category}`)}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey={`${PREFIX}.service`}
                        //labelText="Nombre"
                        text={companyLabel}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey={`${PREFIX}.agreement`}
                        //labelText="Nombre"
                        text={agreementLabel}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey={"servicePayments.pay.identificationType"}
                        //labelText="Nombre"
                        text={identificationLabel}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelText={identificationLabel}
                        //labelText="Nombre"
                        text={identificationValue}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey={`${PREFIX}.alias`}
                        //labelText="Nombre"
                        text={alias}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey={"servicePayments.pay.titular"}
                        //labelText="Nombre"
                        text={clientName || "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />
                    <Info
                        labelKey={`${PREFIX}.dueDate`}
                        //labelText="Nombre"
                        text={!dueDate && "-"}
                        flex={!isDesktop}
                        className={!isDesktop && "flex-direction-column"}
                    />



                    {/* {renderDataModal({
                              xs: 4,
                              labelKey: "servicePayments.pay.titular",
                              data: clientName || "-",
                          })} */}
                    {/* {renderDataModal({
                              labelKey: `${PREFIX}.dueDate`,
                              date: dueDate,
                              data: !dueDate && "-",
                          })} */}
                </Box>

                <Box className="text-center mt-8">
                    <Button
                        bsStyle="outline"
                        label="global.return"
                        className="btn-width-lg"
                        onClick={() => {
                            handleBack();
                        }}
                    />
                </Box>
            </MainContainer>
        </>
    );
};

RegisteredServiceDetail.propTypes = {
    service: shape({}).isRequired,
    serviceDetail: shape({}).isRequired,
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
};

const mapStateToProps = (state) => ({
    service: payServiceSelectors.getServiceAction(state),
    serviceDetail: payServiceSelectors.getServiceDetail(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(RegisteredServiceDetail));