import React from "react";
import Heading from "pages/_components/Heading";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import FormattedAmount from "pages/_components/FormattedAmount";
import { maskLabelAccountProduct, maskPhoneNumber, simpleMaskEmail } from "util/format";
import { Col } from "react-bootstrap";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";

export const steps = [
    {
        stepNumber: 1,
        label: "administration.users.invite.step.1",
    },
    {
        stepNumber: 2,
        label: "administration.users.invite.step.2",
    },
    {
        stepNumber: 3,
        label: "administration.users.invite.step.3",
    },
];

export const renderName = (fullName) => (
    <div className="my-3">
        <Heading.DataGroup
            containerClassName="data-wrapper data-confirm mb-0 data-confirm-head"
            label="administration.users.invite.fullName.label"
            data={fullName}
        />
    </div>
);

const renderData = (labelKey, data) => (
    <div>
        <Heading.DataGroup containerClassName="data-wrapper data-confirm" label={labelKey} data={data} />
    </div>
);

export const renderNameAction = (fullName, document) => (
    <div className="my-3">
        <Heading.DataGroup containerClassName="data-wrapper data-confirm mb-0" labelText={fullName} data={document} />
    </div>
);

export const renderSubtitleAction = (userAction) => (
    <Box display="flex">
        <Text
            labelKey={`administration.users.singular.action.subtitle.${userAction}`}
            className="mt-5"
            size="5"
            color="heading-color"
        />
    </Box>
);

export const renderNameMassiveAction = (subtitleActionKey, usersNamesToApplyAction) => (
    <Box display="column">
        <Box display="flex" className="pt-2 pb-2 my-5">
            <Text labelKey={`administration.users.action.subtitle.${subtitleActionKey}`} className="m-0" size="5" />
        </Box>
        <Box display="flex" gap="4" className="pt-2 pb-2 my-5">
            {usersNamesToApplyAction.map((username) => (
                <Box
                    display="flex"
                    background="background-primary"
                    className="py-4 px-5 my-auto"
                    gap="3"
                    borderRadius="xxl"
                    fitWidth>
                    <Text color="primary-active-color" size="6">
                        {username}
                    </Text>
                </Box>
            ))}
        </Box>
    </Box>
);

const renderGroups = (groups) => (
    <div>
        <Box display="flex" alignX="between" fullWidth>
            <Col xs="6">
                <Text
                    component="label"
                    labelKey="administration.users.invite.groups.label"
                    className="m-0 mb-4"
                    size="6"
                    regular
                />
            </Col>
            <Col xs="6">
                <Box display="flex" gap="3" className="flex-wrap" alignX="end">
                    {groups.length >= 1 &&
                        groups.map((group) => (
                            <Box
                                key={group.name}
                                display="flex"
                                background="primary-background-color"
                                className="py-4 px-5 my-auto"
                                border="primary-focus-color"
                                borderRadius="xxl"
                                gap="3"
                                fitWidth>
                                <Text color="primary-active-color" size="6">
                                    {group.name}
                                </Text>
                            </Box>
                        ))}
                </Box>
            </Col>
        </Box>
    </div>
);

export const renderAdditionalData = (
    identification,
    email,
    mobilePhone,
    signatureLevel,
    groups,
    maskContacts = true,
) => (
    <Box>
        <Box display="flex" className="mt-5">
            <Text
                component="h2"
                className="m-0 mt-1 mb-4"
                size="6"
                bold
                labelKey="administration.users.invite.personal.data.subtitle"
            />
        </Box>
        {identification && renderData("administration.users.invite.document.label", identification)}
        {email &&
            renderData(
                "administration.users.invite.email.label",
                maskContacts ? simpleMaskEmail(email)?.toLowerCase() : email?.toLowerCase(),
            )}
        {mobilePhone &&
            renderData(
                "administration.users.invite.mobilePhone.label",
                maskContacts ? maskPhoneNumber(mobilePhone) : mobilePhone,
            )}
        <Box display="flex" className="mt-5">
            <Text
                component="h2"
                className="m-0 mb-4"
                size="6"
                bold
                labelKey="administration.users.invite.initial.configuration.subtitle"
            />
        </Box>
        {signatureLevel &&
            renderData(
                "administration.users.invite.transaction.signature",
                i18n.get(`administration.signatures.create.signatureLevelsCounts.${signatureLevel}.label`),
            )}
        {groups && renderGroups(groups)}
    </Box>
);

export const renderMainData = (name, labelId = null) => (
    <div>
        <Heading.DataGroup
            containerClassName="data-wrapper data-confirm m-0 data-confirm-head"
            label={labelId || "administration.advanced.user.update.name.label"}
            data={name}
        />
    </div>
);

export const renderAdditionalDataContacts = (email, mobileNumber) => (
    <>
        <Box>
            <Text labelKey="administration.advanced.user.update.data.title" size="5" bold />
        </Box>
        {email && (
            <div className="mb-5">
                <Heading.DataGroup
                    containerClassName="data-wrapper data-confirm"
                    label="administration.advanced.user.modify.contacts.email.label"
                    data={simpleMaskEmail(email)?.toLowerCase()}
                />
            </div>
        )}
        {mobileNumber && (
            <div className="mb-5">
                <Heading.DataGroup
                    containerClassName="data-wrapper data-confirm"
                    label="administration.advanced.user.modify.contacts.mobileNumber.label"
                    data={maskPhoneNumber(mobileNumber)}
                />
            </div>
        )}
    </>
);

export const renderMainDataLimits = (isConfirmationStep, caps, type, capsProd, products) => (
    <>
        <Box display="flex" className="mb-7">
            <Text labelKey="administration.forms.confirm.credentials.description" className="m-0" size="5" />
        </Box>
        <Box display="flex" gap={isConfirmationStep ? "4" : "5"}>
            {Object.entries(caps)
                .filter(([channel]) => channel !== "all")
                .map(([channel, { amount, frequency, currency }]) => (
                    <Box
                        display="flex"
                        background="background-primary"
                        className="py-4 px-5 my-auto"
                        borderRadius="xxl"
                        gap="3"
                        fitWidth>
                        <Text labelKey={`channels.${channel}`} color="primary-active-color" size="6" light />
                        <Box display="flex">
                            <FormattedAmount
                                className="color-primary-active-color size-6 text-light"
                                quantity={
                                    amount === undefined
                                        ? parseFloat(configUtils.get(`default_cap_user_${type}`))
                                        : amount
                                }
                                currency={currency}
                                size="6"
                                noAmountMargin
                            />
                            <Text
                                labelKey={`administration.channels.${frequency}`}
                                color="primary-active-color"
                                size="6"
                                light
                            />
                        </Box>
                    </Box>
                ))}
        </Box>
        <Box gap={isConfirmationStep ? "4" : "5"}>
            {capsProd &&
                capsProd.map((caps) => {
                    return Object.entries(caps).map(([idProduct, { amount }]) => {
                        const product = products?.find((p) => p.id === idProduct);
                        return (
                            <Box
                                display="flex"
                                background="background-primary"
                                className="py-4 px-5 my-auto"
                                borderRadius="xxl"
                                gap="2"
                                fitWidth>
                                <Text color="primary-active-color" size="6" light>
                                    {product?.type === "TC" ? product?.label : maskLabelAccountProduct(product?.label)}
                                </Text>
                                <Box display="flex">
                                    <FormattedAmount
                                        className="color-primary-active-color size-6 text-light"
                                        quantity={amount}
                                        size="6"
                                        noAmountMargin
                                    />
                                </Box>
                            </Box>
                        );
                    });
                })}
        </Box>
    </>
);

export const renderAdditionalDataLimits = (fullName, documentType, documentNumber, isConfirmationStep = false) => (
    <>
        {isConfirmationStep && (
            <Box
                display="flex"
                alignX="between"
                fullWidth
                className="px-7 py-6 border-radius-lg mb-5 mt-5"
                background="background-disabled">
                <Text labelKey="administration.user.label.configure" className="m-0" size="6" />
                <Text className="m-0" color="heading-color" align="left" size="5" bold>
                    {fullName}
                </Text>
            </Box>
        )}
        <Text
            component="h2"
            labelKey="administration.forms.confirm.user"
            className="mx-0 my-3"
            size="6"
            color="heading-color"
            bold
        />
        <Box display="flex" alignX="between" className="my-3" fullWidth>
            <Text labelKey="administration.users.caps.products.document.label" className="m-0" size="6" />
            <Text color="heading-color" align="left" size="6" bold>
                {documentType} {documentNumber}
            </Text>
        </Box>
    </>
);

export const renderAdditionalDataChannelCaps = (caps) =>
    Object.entries(caps)
        .filter(([channel]) => channel !== "all")
        .map(([channel, { amount, frequency }]) => (
            <>
                <Box alignX="between">
                    <Text
                        component="h2"
                        labelKey={`channels.caps.limits.${channel}`}
                        className="mx-0 my-3"
                        size="6"
                        color="heading-color"
                        bold
                    />
                </Box>
                <Box display="flex" alignX="between" className="my-3" fullWidth>
                    <Text labelKey={`channels.${channel}.frequency`} className="m-0" size="6" />
                    <Text
                        className="m-0"
                        labelKey={`administration.channels.${frequency}`}
                        color="heading-color"
                        align="left"
                        size="6"
                        bold
                    />
                </Box>
                <Box display="flex" alignX="between" className="my-3" fullWidth>
                    <Text labelKey={`channels.${channel}.amount`} className="m-0" size="6" />
                    <FormattedAmount quantity={amount} noCurrency bold size="6" />
                </Box>
            </>
        ));

export const renderAdditionalDataCaps = (capsProd, products) => (
    <Box alignX="between">
        <Text
            component="h2"
            labelKey="administration.forms.confirm.user.products.caps"
            className="mx-0 my-3"
            size="6"
            color="heading-color"
            bold
        />
        {capsProd?.map((caps) => {
            const capKey = Object.keys(caps)[0];
            const product = products.find((p) => p.id === capKey);
            return (
                <>
                    <Box display="flex" alignX="between" className="my-3" fullWidth>
                        <Text className="m-0" size="6">
                            {product?.type === "TC" ? product?.label : maskLabelAccountProduct(product?.label)}
                        </Text>
                        <FormattedAmount quantity={caps[capKey].amount} noCurrency bold size="6" />
                    </Box>
                </>
            );
        })}
    </Box>
);
const getSignatureLevel = (signatureLevel = "N") =>
    signatureLevel === "N" ? i18n.get("administration.users.edit.signatureLevel.dontSign") : signatureLevel;
export const renderAdditionalDataSignature = (signatureLevel) => (
    <>
        <Text
            component="h2"
            labelKey="administration.forms.confirm.update.user"
            className="mx-0 my-3"
            size="5"
            color="heading-color"
            bold
        />
        <Box display="flex" alignX="between" className="my-3" fullWidth>
            <Text labelKey="administration.users.edit.signatureLevel" className="m-0" size="5" />
            <Text className="m-0" color="heading-color" align="left" size="5" bold>
                {getSignatureLevel(signatureLevel)}
            </Text>
        </Box>
    </>
);

export const renderNameDocumentAction = (users) => (
    <Box>
        <Box display="flex">
            <Text labelKey="administration.groups.edit.users" className="m-0" size="5" bold />
        </Box>
        <div>
            {users.map((user) => (
                <Box display="block" alignY="baseline" key={user?.fullName}>
                    <div className="data-wrapper data-confirm mb-2">
                        <span className="data-label">{user?.fullName}</span>

                        <span className="data-desc">
                            <>{`${i18n.get(`documentType.abbreviation.${user?.documentType}`)} ${
                                user?.documentNumber
                            }`}</>
                        </span>
                    </div>
                </Box>
            ))}
        </div>
    </Box>
);
