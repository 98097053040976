import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { compose } from "redux";
import { getInteger } from "util/config";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { getAllQuestions, validateSelectedQuestions } from "pages/securityQuestions/questionsUtils";
import RegisterQuestionsField from "pages/_components/fields/RegisterQuestionsField";
import EnrollmentWrapper from "../_components/EnrollmentWrapper";

const FORM_ID = "enrollment.step5.question";

class Question extends Component {
    state = {
        allQuestions: [],
        maxQuestions: 5,
    };

    componentDidMount() {
        this.setState({ allQuestions: getAllQuestions(), maxQuestions: getInteger("bank.catalog.NPREGUREGIS", 5) });
    }

    cancel = () => {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.showExitEnrollmentModal(true));
    };

    toStart = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment/document"));
    };

    onBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment/selectAvatar"));
    };

    generateInfoMessage = (isDesktop) => (
        <Box
            display="flex"
            borderRadius="lg"
            background="info"
            border="info-border-color"
            className={classNames("mb-7", {
                "py-4 px-6": isDesktop,
                "py-4 px-5 mb-2": !isDesktop,
            })}>
            <Box className="width-info-icon mt-2">
                <Image src="images/icons/info.svg" />
            </Box>
            <Box className="ml-4">
                <Text labelKey="securityQuestions.help.info" size="6" color="heading" light />
            </Box>
        </Box>
    );

    validateFields = (values) => {
        const { maxQuestions } = this.state;

        const { questions } = values;
        let i = 0;
        while (++i <= maxQuestions) {
            if (!questions[i] || questions[i].question === undefined || questions[i].answer === undefined) {
                return true;
            }
        }
        return false;
    };

    content = () => {
        const { isSubmitting, isDesktop, values, hasRegisterQuestions, registeredQuestions } = this.props;
        const { allQuestions, maxQuestions } = this.state;

        const isUpdate = hasRegisterQuestions;
        const title = isUpdate
            ? i18n.get("enrollment.step7.question.update.title")
            : i18n.get("enrollment.step7.question.register.title");
        const subtitle = isUpdate
            ? i18n.get("enrollment.step7.question.update.subtitle")
            : i18n.get("enrollment.step7.question.register.subtitle");

        const alertInfo = this.generateInfoMessage(isDesktop);

        return (
            <>
                <Form className={classNames("display-flex flex-column", { "full-height": !isDesktop })}>
                    <Box display="flex" fullWidth className="login-title-container">
                        {isDesktop && (
                            <Box className="login-title-icon pr-4" onClick={this.onBack} pointer>
                                <Image src="images/arrow_back.svg" />
                            </Box>
                        )}
                        <Box fullWidth className="login-title-text">
                            <Text className="btn-link-span" size={isDesktop ? "3" : "2"} bold>
                                {title}
                            </Text>
                        </Box>
                    </Box>
                    <Box className="mt-4 mb-6">
                        <Text>{subtitle}</Text>
                    </Box>
                    {alertInfo}
                    <Box
                        className={classNames("mb-4 container-questions security-questions", {
                            "custom-scrollbar pr-3": isDesktop,
                        })}>
                        <Field
                            component={RegisterQuestionsField}
                            hideLabel={false}
                            idForm={FORM_ID}
                            name="questions"
                            maxQuestions={maxQuestions}
                            questions={allQuestions}
                            isDesktop={false}
                        />
                    </Box>
                    <Box {...(!isDesktop && { className: "mt-auto" })}>
                        <Row className="mt-7">
                            <Col xs={isDesktop ? 6 : 12}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                    disabled={this.validateFields(values)}
                                    className="full-width"
                                />
                            </Col>
                            <Col xs={isDesktop ? 6 : 12}>
                                <Button
                                    label="global.cancel"
                                    onClick={() => {
                                        this.cancel();
                                    }}
                                    bsStyle="outline"
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Form>
            </>
        );
    };

    render() {
        const { isDesktop, userInfo } = this.props;

        if (!userInfo || Object.keys(userInfo).length === 0) {
            this.toStart();
            return <></>;
        }

        return (
            <EnrollmentWrapper onBack={this.onBack} isDesktop={isDesktop}>
                <>{this.content()}</>
            </EnrollmentWrapper>
        );
    }
}

Question.propTypes = {
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    isValid: bool.isRequired,
    showExitModal: bool.isRequired,
    hasRegisterQuestions: bool.isRequired,
    userInfo: shape({
        idUsuario: string,
        email: string,
        mobileNumber: string,
        firstName: string,
        lastName: string,
        documentNumber: string,
        documentType: string,
        username: string,
    }).isRequired,
    registeredQuestions: shape({}),
};

const mapStateToProps = (state) => ({
    selectedQuestion: enrollmentSelectors.getSelectedQuestion(state),
    hasRegisterQuestions: enrollmentSelectors.hasRegisterQuestions(state),
    registeredQuestions: enrollmentSelectors.getRegisteredQuestions(state),
    userInfo: enrollmentSelectors.getUserInfo(state),
});

const normalizeData = (registeredQuestions) => {
    const data = {};
    if(registeredQuestions){
        Object.keys(registeredQuestions).forEach((key) => {
            data[key] = { question: registeredQuestions[key], answer: "" };
        });
    }
    return data;
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: false,
        mapPropsToValues: (props) => ({
            questions: normalizeData(props?.registeredQuestions) || {},
        }), 
        validationSchema: () =>
            Yup.object().shape({
                questions: Yup.object().required("global.field.required"),
            }),
        handleSubmit: (values, formikBag) => {
            const { questions } = values;
            const { dispatch, hasRegisterQuestions } = formikBag.props;
            const maxQuestions = getInteger("bank.catalog.NPREGUREGIS", 5);
            const hasErrors = validateSelectedQuestions(questions, maxQuestions, formikBag);
            if (hasErrors) {
                formikBag.setSubmitting(false);
                return;
            }
            dispatch(enrollmentActions.saveQuestionAnswers(questions, hasRegisterQuestions?"update":"register"));
            dispatch(push("/enrollment/termsAndConditions"));
        },
    }),
)(Question);
