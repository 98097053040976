import {
    CORPORATE_ENVIRONMENT_TYPE,
    CORPORATE_GROUP_ENVIRONMENT_TYPE,
    FILTER_DATE_TRANSACTION,
    OPERATIONS_TRANSACTION_ENABLED,
    RETAIL_ENVIRONMENT_TYPE,
    TYPE_FILTER,
    TYPE_FILTER_USERS,
} from "constants.js";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component, createElement } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as configUtil from "util/config";
import * as i18n from "util/i18n";
import { format } from "date-fns";
import ChipsButtonsGroup from "pages/_components/ChipsButtonsGroup";
import * as dateUtils from "util/date";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";
import SearchableSelect from "pages/forms/_components/_fields/SearchableSelect";
import NoOrderFilter from "../history/_components/NoOrderFilter";
import PeriodFilter from "../history/_components/PeriodFilter";
import UserFilter from "../history/_components/UserFilter";
import StatusFilter from "../history/_components/StatusFilter";
import AmountFilter from "../history/_components/AmountFilter";

const components = {
    order: NoOrderFilter,
    status: StatusFilter,
    user: UserFilter,
    amount: AmountFilter,
};

class HistoricHiddenFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool,
        onlyPendings: bool,
        onlyProcessing: bool,
        pendingDispatch: bool,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        isTransactionList: bool,
        operationTypeListRetail: arrayOf(string).isRequired,
        operationTypeListCorporate: arrayOf(string).isRequired,
        historic: bool,
        resetHandleOrder: func.isRequired,
        setOperationType: func,
        clearFilters: func,
        setDateFrom: func,
        setDateTo: func,
        lastRegistryNumber: string.isRequired,
        status: arrayOf(string),
        dateFromIsRequired: bool,
        filterValues: shape({}).isRequired,
        setFilterValues: func.isRequired,
        isCreatedByMe: bool,
        operations: arrayOf(),
    };

    static defaultProps = {
        fetching: false,
        onlyPendings: false,
        onlyProcessing: false,
        pendingDispatch: false,
        isTransactionList: false,
        historic: false,
        setOperationType: () => {},
        clearFilters: () => {},
        setDateFrom: () => {},
        setDateTo: () => {},
        status: [],
        dateFromIsRequired: false,
        isCreatedByMe: false,
        operations: OPERATIONS_TRANSACTION_ENABLED,
    };

    state = {
        // eslint-disable-next-line react/no-unused-state
        operationTypeOptions: [],
        // eslint-disable-next-line react/no-unused-state
        transactionTypeOptions: [],
        // eslint-disable-next-line react/destructuring-assignment
        selectedFilter: this.props.historic ? "period" : "all",
        selectedOperationType: "all",
        selectedTransactionType: "all",
        selectedDateFilter: FILTER_DATE_TRANSACTION.TODAY,
        hasDateErrors: false,
    };

    componentDidMount() {
        const { activeEnvironment, filterValues, onlyPendings, operationTypeListRetail } = this.props;

        const { selectedOperationType } = this.state;

        let operationTypeOptions = null;

        let transactionTypeOptions = null;

        const operationsCorporateFinal = configUtil.getArray(
            `transaction.list.operations.options.corporate${onlyPendings ? ".pending" : ".historic"}`,
        );

        if (activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE) {
            operationTypeOptions = operationsCorporateFinal.map((operationType) => ({
                value: operationType,
                label:
                    operationType !== "all"
                        ? i18n.get(`operation.${operationType}`)
                        : i18n.get("transactions.list.filters.operationType.all.label"),
            }));
            transactionTypeOptions = [
                {
                    value: "all",
                    label: i18n.get("transactions.list.filters.operationType.all.label"),
                },
            ];
            let listTransactions = [];

            operationsCorporateFinal.forEach((operation) => {
                const transactionConfig = configUtil.getArray(
                    `transaction.list.transactions.options.corporate.${operation}`,
                );

                if (selectedOperationType === "all") {
                    listTransactions = transactionConfig.map((transaction) => ({
                        value: transaction,
                        label:
                            transaction !== "all"
                                ? i18n.get(`activities.${transaction}`)
                                : i18n.get("transactions.list.filters.operationType.all.label"),
                    }));
                }

                transactionTypeOptions = transactionTypeOptions.concat(listTransactions);
            });
        } else if (activeEnvironment.type === RETAIL_ENVIRONMENT_TYPE) {
            operationTypeOptions = operationTypeListRetail.map((operationType) => ({
                value: operationType,
                label:
                    operationType !== "all"
                        ? i18n.get(`activities.${operationType}`)
                        : i18n.get("transactions.list.filters.operationType.all.label"),
            }));
        }

        this.setState({
            selectedOperationType: filterValues?.selectedOperationType || "all",
            selectedTransactionType: filterValues?.selectedTransactionType || "all",
            selectedFilter: filterValues?.selectedFilter || "all",
            operationTypeOptions,
            transactionTypeOptions,
            selectedDateFilter: filterValues?.selectedDateFilter || FILTER_DATE_TRANSACTION.TODAY,
        });
    }

    handleChange = (selectedOption, formikProps) => {
        const { setFieldValue, setTouched } = formikProps;
        setFieldValue("order", "");
        setFieldValue("name", "");
        setFieldValue("status", "all");
        setFieldValue("minAmount.amount", 0);
        setFieldValue("maxAmount.amount", 0);
        setTouched({});
        this.setState({ selectedFilter: selectedOption.value });
    };

    handleChangeOperationType = (selectedOption) => {
        this.setState({ selectedOperationType: selectedOption.value, selectedTransactionType: "all" });
        const { setOperationType, onlyPendings } = this.props;
        if (setOperationType && selectedOption?.value) {
            setOperationType(selectedOption.value);

            let transactionTypeOptions = null;

            transactionTypeOptions = [
                {
                    value: "all",
                    label: i18n.get("transactions.list.filters.operationType.all.label"),
                },
            ];

            if (selectedOption.value === "all") {
                const operationsCorporateFinal = configUtil.getArray(
                    `transaction.list.operations.options.corporate${onlyPendings ? ".pending" : ".historic"}`,
                );
                operationsCorporateFinal.forEach((operation) => {
                    const transactionConfig = configUtil.getArray(
                        `transaction.list.transactions.options.corporate.${operation}`,
                    );
                    const listTransactions = transactionConfig?.filter(transaction => transaction)?.map((transaction) => ({
                        value: transaction,
                        label:
                            transaction !== "all"
                                ? i18n.get(`activities.${transaction}`)
                                : i18n.get("transactions.list.filters.operationType.all.label"),
                    }));

                    transactionTypeOptions = transactionTypeOptions.concat(listTransactions);
                });
            } else {
                const transactionConfig = configUtil.getArray(
                    `transaction.list.transactions.options.corporate.${selectedOption.value}`,
                );
                const listTransactions = transactionConfig?.filter(transaction => transaction)?.map((transaction) => ({
                    value: transaction,
                    label:
                        transaction !== "all"
                            ? i18n.get(`activities.${transaction}`)
                            : i18n.get("transactions.list.filters.operationType.all.label"),
                }));

                transactionTypeOptions = transactionTypeOptions.concat(listTransactions);
            }

            this.setState({
                transactionTypeOptions,
            });
        }
    };

    handleChangeTransactionType = (selectedOption) => {
        this.setState({ selectedTransactionType: selectedOption.value });
        // const { setOperationType } = this.props;
        // if (setOperationType && selectedOption?.value) {
        //     setOperationType(selectedOption.value);
        // }
    };

    handleClick = (dataFilter) => {
        const {
            filterValues,
            setFilterValues,
            dispatch,
            pendingDispatch,
            onlyPendings,
            onlyProcessing,
            // resetHandleOrder,
            // isCreatedByMe,
        } = this.props;
        const { selectedOperationType, selectedTransactionType, selectedFilter, selectedDateFilter } = this.state;

        if (selectedFilter === "lastMonth") {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            const dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
            const dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            dispatch(
                transactionsActions.loadListRequest(
                    { operationType: selectedOperationType, dateFrom, dateTo },
                    onlyPendings,
                    onlyProcessing,
                    pendingDispatch,
                    false,
                ),
            );
        } else {
            const { order, status, user, dateFrom, dateTo, maxAmount, minAmount } = dataFilter || {};

            let dateFromFormat = dateFrom ? format(dateFrom, "YYYY-MM-DD HH:mm:ss") : null;
            let dateToFormat = dateTo ? format(dateTo, "YYYY-MM-DD HH:mm:ss") : null;

            if (!onlyPendings && selectedDateFilter === FILTER_DATE_TRANSACTION.TODAY) {
                dateFromFormat = dateFromFormat = moment()
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss");
                dateToFormat = moment()
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm:ss");
            } else if (!onlyPendings && selectedDateFilter === FILTER_DATE_TRANSACTION.LAST_7_DAYS) {
                dateFromFormat = moment()
                    .subtract(7, "days")
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss");
                dateToFormat = moment()
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm:ss");
            } else if (!onlyPendings && selectedDateFilter === FILTER_DATE_TRANSACTION.LAST_30_DAYS) {
                dateFromFormat = moment()
                    .subtract(30, "days")
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss");
                dateToFormat = moment()
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm:ss");
            } else if (selectedDateFilter === FILTER_DATE_TRANSACTION.OTHER) {
                dateFromFormat = moment(dateFrom)
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss");
                dateToFormat = moment(dateTo)
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm:ss");
            }

            const data = {
                order,
                status,
                user,
                dateFrom: dateFromFormat,
                dateTo: dateToFormat,
                maxAmount: maxAmount?.amount,
                minAmount: minAmount?.amount,
            };
            setFilterValues({
                ...filterValues,
                selectedFilter,
                selectedOperationType,
                selectedTransactionType,
                selectedDateFilter,
                ...data,
            });
        }

        // resetHandleOrder();
    };

    setLastMonthFilter = (selectedFilter) => {
        if (selectedFilter === "lastMonth") {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            const dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
            const dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            const { setDateFrom, setDateTo } = this.props;
            if (setDateFrom && setDateTo) {
                setDateFrom(moment(dateFrom).format("YYYY-MM-DD"));
                setDateTo(moment(dateTo).format("YYYY-MM-DD"));
            }
        }
    };

    handlerDateFilterValue = (value) => {
        this.setState({ selectedDateFilter: value });
        this.setState({ selectedFilter: "all" });
    };

    renderFilter = () => {
        const { selectedFilter, selectedOperationType, hasDateErrors } = this.state;
        const {
            dispatch,
            fetching,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            resetHandleOrder,
            historic,
            dateFromIsRequired,
            lastRegistryNumber,
            filterValues,
            setFilterValues,
        } = this.props;

        let props = {
            dispatch,
            isDesktop: true,
            fetching,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            resetHandleOrder,
            historic,
            dateFromIsRequired,
            lastRegistryNumber,
            filterValues,
            setFilterValues,
        };

        if (selectedFilter === "period") {
            props = { ...props, selectedOperationType };
        }

        if (selectedFilter === "status") {
            props = { ...props, selectedOperationType };
        }

        if (selectedFilter === "amount") {
            props = { ...props, selectedOperationType };
        }

        if (selectedFilter === "all" || selectedFilter === "lastMonth") {
            this.setLastMonthFilter(selectedFilter);
        }

        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const {
            selectedFilter,
            selectedOperationType,
            selectedTransactionType,
            transactionTypeOptions,
            operationTypeOptions,
            selectedDateFilter,
            hasDateErrors,
        } = this.state;
        const {
            activeEnvironment,
            isTransactionList,
            /* eslint-disable react/prop-types */
            isDesktop,
            fetching,
            historic,
            onlyPendings,
        } = this.props;

        let options = isTransactionList
            ? [
                  {
                      value: "all",
                      label: i18n.get("transactions.list.filters.operationType.all.label"),
                  },
                  {
                      value: "order",
                      label: i18n.get("transactions.list.filter.searchBy.order"),
                  },
                  {
                      value: "user",
                      label: i18n.get("transactions.list.filter.searchBy.user"),
                  },
                  {
                      value: "status",
                      label: i18n.get("transactions.list.filter.searchBy.status"),
                  },
                  {
                      value: "amount",
                      label: i18n.get("transactions.list.filter.searchBy.amount"),
                  },
              ]
            : [
                  {
                      value: "all",
                      label: i18n.get("transactions.list.filters.operationType.all.label"),
                  },
                  {
                      value: "period",
                      label: i18n.get("transactions.list.filter.searchBy.period"),
                  },
                  {
                      value: "order",
                      label: i18n.get("transactions.list.filter.searchBy.order"),
                  },
                  {
                      value: "user",
                      label: i18n.get("transactions.list.filter.searchBy.user"),
                  },
              ];

        if (activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE) {
            options = options.concat({
                value: "environment",
                label: i18n.get("accounts.movements.filters.searchBy.client"),
            });
        }

        const buttonsGroup = [
            {
                id: FILTER_DATE_TRANSACTION.TODAY,
                label: "transactions.list.filter.date.label.today",
                labelDefault: "Hoy",
            },
            {
                id: FILTER_DATE_TRANSACTION.LAST_7_DAYS,
                label: "transactions.list.filter.date.label.last7Days",
                labelDefault: "Últimos 7 días",
            },
            {
                id: FILTER_DATE_TRANSACTION.LAST_30_DAYS,
                label: "transactions.list.filter.date.label.last30Days",
                labelDefault: "Últimos 30 días",
            },
            {
                id: FILTER_DATE_TRANSACTION.OTHER,
                label: "transactions.list.filter.date.label.other",
                labelDefault: "Otra fecha",
            },
        ];

        const getValidationSchemas = () => {
            if (selectedFilter === "order") {
                return Yup.object().shape({
                    order: Yup.string()
                        .nullable()
                        .required(i18n.get("global.field.required")),
                });
            }
            if (selectedFilter === "user") {
                return Yup.object().shape({
                    user: Yup.string()
                        .nullable()
                        .required(i18n.get("global.field.required")),
                });
            }
            if (selectedFilter === "status") {
                return Yup.object().shape({
                    status: Yup.string().required(i18n.get("transactions.list.filters.status.searchBy.required")),
                });
            }
            if (selectedFilter === "amount") {
                return Yup.lazy((values) =>
                    Yup.object().shape({
                        minAmount: Yup.object().shape({
                            amount: values.maxAmount.amount
                                ? Yup.number()
                                      .transform((cv, ov) => (ov === "" ? undefined : cv))
                                      .nullable()
                                      .max(
                                          values.maxAmount.amount,
                                          i18n.get("creditCards.movements.filters.maxAmount.error"),
                                      )
                                : Yup.number()
                                      .transform((cv, ov) => (ov === "" ? undefined : cv))
                                      .required(i18n.get("global.field.required")),
                        }),
                        maxAmount: Yup.object().shape({
                            amount: values.minAmount.amount
                                ? Yup.number()
                                      .transform((cv, ov) => (ov === "" ? undefined : cv))
                                      .nullable()
                                      .min(
                                          values.minAmount.amount,
                                          i18n.get("creditCards.movements.filters.minAmount.error"),
                                      )
                                : Yup.number()
                                      .transform((cv, ov) => (ov === "" ? undefined : cv))
                                      .nullable()
                                      .required(i18n.get("global.field.required")),
                        }),
                    }),
                );
            }
            return Yup.object().shape({});
        };

        return (
            <>
                <>
                    <Col xs={12} md={!historic ? 5 : 3}>
                        <Formik
                            initialValues={{
                                dateFrom: this.props.filterValues?.dateFrom || moment(),
                                dateTo: this.props.filterValues?.dateTo || moment(),
                                order: this.props.filterValues?.order || "",
                                user: this.props.filterValues?.user || "",
                                status: this.props.filterValues?.status || "all",
                                minAmount: { amount: 0 },
                                maxAmount: { amount: 0 },
                            }}
                            validationSchema={getValidationSchemas}
                            onSubmit={(values) => {
                                const newValues = {};
                                if (selectedDateFilter === FILTER_DATE_TRANSACTION.OTHER) {
                                    if (hasDateErrors) {
                                        return;
                                    }
                                    newValues.dateFrom = values?.dateFrom;
                                    newValues.dateTo = values?.dateTo;
                                }
                                if (selectedFilter === "order") {
                                    newValues.order = values?.order;
                                }
                                if (selectedFilter === "user") {
                                    newValues.user = values?.user;
                                }
                                if (selectedFilter === "status") {
                                    newValues.status = values?.status;
                                }
                                if (selectedFilter === "amount") {
                                    newValues.minAmount = values?.minAmount;
                                    newValues.maxAmount = values?.maxAmount;
                                }
                                this.handleClick(newValues);
                            }}>
                            {(props) => {
                                this.handleFormikValueChange = (value) => {
                                    this.handlerDateFilterValue(value);
                                    if (value === FILTER_DATE_TRANSACTION.OTHER) {
                                        const dateFromFormat = moment().startOf("day");
                                        const dateToFormat = moment().endOf("day");
                                        const data = { dateFrom: dateFromFormat, dateTo: dateToFormat };
                                        const currentValues = props.values; // Obtener los valores actuales
                                        props.setValues({
                                            ...currentValues,
                                            ...data,
                                        }); // Actualizar los valores
                                    }
                                };

                                return (
                                    <Form>
                                        <Box
                                            background="component-background"
                                            className="mx-n-5 p-5 mt-3 mt-md-0 mb-3 mb-md-0">
                                            <ChipsButtonsGroup
                                                buttonsGroup={buttonsGroup}
                                                onClickHandler={this.handleFormikValueChange}
                                                defaultSelect={selectedDateFilter}
                                            />
                                        </Box>
                                        <Row gapY="3" gapX={!historic ? "7" : "0"}>
                                            <Col xs={12} {...(!historic && { md: 3 })}>
                                                <Box className="form-group form-group--select">
                                                    <Box
                                                        display="flex"
                                                        alignY="center"
                                                        className="data-label-special-mb">
                                                        <Text
                                                            component="label"
                                                            htmlFor="react-select-operationType-input"
                                                            labelKey="transactions.list.filter.operationType.label"
                                                            className="data-label"
                                                        />
                                                    </Box>
                                                    <Box className="input-group ">
                                                        <Select
                                                            id="operationType"
                                                            name="operationType"
                                                            label="transactions.list.filter.operationType"
                                                            onChange={this.handleChangeOperationType}
                                                            options={operationTypeOptions}
                                                            value={selectedOperationType}
                                                            placeholder={i18n.get("global.all")}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Col>
                                            <Col xs={12} {...(!historic && { md: 6 })}>
                                                <Box className="form-group form-group--select form-group--select-with-search">
                                                    <Box
                                                        display="flex"
                                                        alignY="center"
                                                        className="data-label-special-mb">
                                                        <Text
                                                            component="label"
                                                            htmlFor="react-select-transactionType-input"
                                                            labelKey="transactions.list.filter.transactionType.label"
                                                            className="data-label"
                                                        />
                                                    </Box>
                                                    {/* <Select
                                                        id="transactionType"
                                                        name="transactionType"
                                                        label="transactions.list.filter.transactionType"
                                                        onChange={this.handleChangeTransactionType}
                                                        options={transactionTypeOptions}
                                                        value={selectedTransactionType}
                                                        searchable={!!isDesktop}
                                                        
                                                    /> */}
                                                    <SearchableSelect
                                                        id="transactionType"
                                                        name="transactionType"
                                                        label="transactions.list.filter.transactionType"
                                                        onChange={this.handleChangeTransactionType}
                                                        options={transactionTypeOptions}
                                                        value={selectedTransactionType}
                                                    />
                                                </Box>
                                            </Col>
                                            <Col xs={12} {...(!historic && { md: 3 })}>
                                                <Box className="form-group form-group--select">
                                                    <Box
                                                        display="flex"
                                                        alignY="center"
                                                        className="data-label-special-mb">
                                                        <Text
                                                            component="label"
                                                            htmlFor="react-select-searchBy-input"
                                                            labelKey="transactions.list.filter.searchBy.label"
                                                            className="data-label"
                                                        />
                                                    </Box>
                                                    <Box className="input-group ">
                                                        <Select
                                                            id="searchBy"
                                                            name="searchBy"
                                                            label="transactions.list.filter.searchBy.label"
                                                            onChange={(selectedOption) =>
                                                                this.handleChange(selectedOption, props)
                                                            }
                                                            options={options}
                                                            value={selectedFilter}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Col>

                                            {selectedFilter === "order" && (
                                                <Col xs={12} md={6}>
                                                    <NoOrderFilter />
                                                </Col>
                                            )}
                                            {selectedFilter === "user" && (
                                                <Col xs={12} md={6}>
                                                    <UserFilter />
                                                </Col>
                                            )}
                                            {selectedFilter === "status" && (
                                                <Col xs={12} md={3}>
                                                    <StatusFilter />
                                                </Col>
                                            )}
                                            {selectedFilter === "amount" && (
                                                <Col xs={12} md={6}>
                                                    <AmountFilter />
                                                </Col>
                                            )}
                                            {selectedDateFilter === "other" && (
                                                <Col xs={12} md={6}>
                                                    <PeriodFilter
                                                        {...props}
                                                        handleErrors={(value) =>
                                                            this.setState({ hasDateErrors: value })
                                                        }
                                                    />
                                                </Col>
                                            )}
                                            {((selectedFilter !== "all" && selectedDateFilter !== "other") ||
                                                (selectedFilter === "all" && selectedDateFilter === "other")) && (
                                                <Col xs={12} md={3} style={{ alignSelf: "flex-end" }}>
                                                    <Button
                                                        bsStyle="primary"
                                                        label="global.search"
                                                        block
                                                        loading={fetching}
                                                        btnUppercase={false}
                                                        type="submit"
                                                        className={!isDesktop && "mt-3"}
                                                    />
                                                </Col>
                                            )}
                                            {((selectedFilter !== "all" && selectedDateFilter === "other") ||
                                                (selectedFilter === "all" && selectedDateFilter !== "other")) && (
                                                <Row className={isDesktop && "mt-5"}>
                                                    <Col xs={12} {...(!historic && { md: 5 })} />
                                                    <Col xs={12} {...(!historic && { md: 2 })}>
                                                        <Button
                                                            bsStyle="primary"
                                                            label="global.search"
                                                            block
                                                            loading={fetching}
                                                            type="submit"
                                                            btnUppercase={false}
                                                        />
                                                    </Col>
                                                    <Col xs={12} {...(!historic && { md: 5 })} />
                                                </Row>
                                            )}
                                        </Row>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Col>
                </>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    transactionsAdmin: configUtil.getArray("transaction.list.transactions.options.corporate.Admin"),
    operationTypeListRetail: configUtil.getArray("transaction.list.operationType.options.retail"),
    operationTypeListCorporate: configUtil.getArray("transaction.list.operationType.options.corporate"),
    lastRegistryNumber: transactionsSelectors.getLastRegistryNumber(state),
});

export default connect(mapStateToProps)(withTransactionFilterContext(HistoricHiddenFilters));
