/** @jsx jsx */
import { ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { jsx } from "@emotion/react";
import * as i18n from "util/i18n";
import Box from "pages/_components/Box";

import SelectReact from "react-select";

import Button from "pages/_components/Button";
import { customStyles, CustomDropdownIndicator, CustomNoOptionsMessage } from "pages/forms/_components/_fields/Select";

// const selectStyles = {
//     control: (provided) => ({
//         ...provided,
//         minWidth: 240,
//         flexDirection: "row-reverse",
//     }),
//     menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
// };

const Select = ({
    id,
    value,
    defaultValue,
    disabled,
    clearable,
    multi,
    options,
    field,
    form,
    onChange,
    placeholderKey,
    labelKey,
    valueKey,
    containerClassName,
    noOptionsKey,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const ref = useRef();

    useEffect(() => {
        if (options && options.length > 0 && onChange) {
            if (typeof value === "string" || value) {
                const option = options?.find((option) => option[valueKey] === value);
                onChangeSelect(option);
            } else {
                onChangeSelect(options[0]);
            }
        }
    }, [options, value, onChange]);

    const onChangeSelect = (option) => {
        if (onChange) {
            onChange(option);
        } else if (form && form.setFieldValue) {
            form.setFieldValue(field.name, !option ? "" : option.value);
        }
        setSelectedValue(option);
        setIsOpen(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setIsOpen(true);
        } else if (e.key === "Escape") {
            setIsOpen(false);
        }
    };

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onKeyPress={handleKeyDown}
            target={
                <Button
                    image={isOpen ? "images/selectArrowUp.svg" : "images/selectArrowDown.svg"}
                    imageRight
                    onClick={() => setIsOpen((prev) => !prev)}
                    isSelected={isOpen}
                    regular
                    bsStyle="inverse"
                    style={{ "justify-content": "space-between", width: "100%", "text-align": "left" }}
                    removeMinHeight>
                    {selectedValue
                        ? `${selectedValue.label}`
                        : placeholderKey
                        ? i18n.get(placeholderKey)
                        : i18n.get("forms.placeholder.select")}
                </Button>
            }>
            <Box className="input-group ">
                <SelectReact
                    ref={ref}
                    unstyled
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{
                        DropdownIndicator: CustomDropdownIndicator,
                        NoOptionsMessage: (noOptionsKey) => <CustomNoOptionsMessage noOptionsKey={noOptionsKey} />,
                    }}
                    hideSelectedOptions={false}
                    instanceId={id}
                    // isDisabled={disabled}
                    isClearable={clearable}
                    // isMulti={multi}
                    menuIsOpen
                    onChange={onChangeSelect}
                    classNames={{
                        container: () => classNames("Select full-width", containerClassName),
                        control: () => "Select-control",
                        dropdownIndicator: () => "Select-arrow-zone",
                        input: () => "Select-input",
                        menu: () => "Select-menu-outer",
                        menuList: () => "Select-menu",
                        option: () => "Select-option",
                        placeholder: () => "Select-placeholder",
                        valueContainer: () => "Select-multi-value-wrapper",
                    }}
                    {...(!multi
                        ? typeof value === "string" || value === null
                            ? {
                                  value: options?.find((option) => option[valueKey] === value),
                              }
                            : {
                                  value: value && options?.find((option) => option[valueKey] === value[valueKey]),
                              }
                        : // HAY QUE ARMAR EL ARRAY
                          { value: field.value })}
                    {...(!(!onChange && form && form.setFieldValue) && { controlShouldRenderValue: !!value })}
                    getOptionLabel={(option) => option[labelKey]}
                    getOptionValue={(option) => option[valueKey]}
                    options={options}
                    defaultValue={defaultValue}
                    {...{
                        placeholder: placeholderKey ? i18n.get(placeholderKey) : i18n.get("forms.placeholder.select"),
                        ...props,
                    }}
                    styles={customStyles}
                />
            </Box>
        </Dropdown>
    );
};

Select.defaultProps = {
    className: null,
    clearable: false,
    containerClassName: null,
    disabled: false,
    field: {},
    form: {},
    labelKey: "label",
    multi: false,
    onChange: null,
    options: null,
    searchable: false,
    selectClassName: null,
    setMultiSelectRefrence: null,
    value: null,
    valueKey: "value",
    withSelectedLabel: null,
    withSelectedLabelNoMoreOptions: null,
    avoidChangeOneOption: false,
    placeholderKey: null,
    noOptionsKey: null,
    applyCustomStyles: false,
};

export default Select;

// styled components

const Menu = (props) => {
    const shadow = "hsla(218, 50%, 10%, 0.1)";
    return (
        <div
            className="dropdown-menu"
            css={{
                backgroundColor: "white",
                borderRadius: 4,
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                marginTop: 0,
                width: "100%",
                position: "absolute",
                zIndex: 10001,
            }}
            {...props}
        />
    );
};
const Blanket = (props) => (
    <div
        css={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: "fixed",
            zIndex: 1,
        }}
        {...props}
    />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div className="searchable-select" css={{ position: "relative" }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);
const Svg = (p) => <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />;
const DropdownIndicator = () => (
    <div css={{ height: 6, width: 10, marginLeft: 12 }} className="dropdon-indicator">
        <Svg>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Svg>
    </div>
);
