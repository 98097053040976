import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field } from "formik";
import classNames from "classnames";
import withRouter from "react-router-dom/withRouter";
import { bool, func, string, shape, arrayOf, oneOfType } from "prop-types";
import { Col } from "react-bootstrap";
import { push } from "react-router-redux";
import * as Yup from "yup";
import { useDebitAccountSelectorOptions } from "pages/forms/customForms/hooks/PayCreditCardHooks";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import {
    actions as creditCardOtherBankActions,
    selectors as creditCardOtherBankSelectors,
} from "reducers/creditCardOtherBank";

import { resizableRoute } from "pages/_components/Resizable";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import Info from "pages/_components/Info";
import Loader from "pages/_components/Loader";
import TicketData from "pages/_components/TicketData";
import ExitModal from "pages/_components/modal/ExitModal";
import Select from "pages/forms/_components/_fields/Select";
import TextField from "pages/_components/fields/TextField";
import AmountField from "pages/_components/fields/formik/AmountField";
import AlertInformation from "pages/_components/AlertInformation";
import FormTransition from "pages/forms/_components/FormTransition";

import * as i18n from "util/i18n";
import * as config from "util/config";
import { maskCardNumber } from "util/mask";
import { formatNumber } from "util/format";
import { USD_CURRENCY } from "constants.js";

const ID_FORM = "creditCard.otherBanks.payment";
const ID_ACTIVITY = `${ID_FORM}.send`;
const TITLE_FORM = `${ID_FORM}.title`;

const PayCardOtherBank = (props) => {
    const { dispatch, mode, preDataForm, idSelectedCard, fetchingForm, formProducts } = props;

    const [showExitModal, setShowExitModal] = useState(false);
    const [enabledSubmit, setEnabledSubmit] = useState(false);
    const [selectedCard, setSelectedCard] = useState(idSelectedCard);
    const [selectedAccount, setSelectedAccount] = useState("");
    const [selectedCreditCard, setSelectedCreditCard] = useState();

    const [metadata, setMetadata] = useState({
        draftsEnabled: false,
        templatesEnabled: false,
        schedulable: true,
        programable: true,
        idActivity: ID_ACTIVITY,
    });

    const accountsOptions = useDebitAccountSelectorOptions(formProducts || [], true);

    const transferRoute = config.get("transfer.route.creditCards.otherBanks", "Banco Central");

    useEffect(() => {
        dispatch(formActions.preCustomForm(["creditcard.payment.otherbanks.pay"]));
    }, []);

    useEffect(() => {
        dispatch(settingsActions.getEnvironmentsRequest());
        dispatch(creditCardOtherBankActions.getCreditCards(0, ""));
    }, []);

    useEffect(() => {
        const { nonWorkingDays, enabledWeekDays, firstWorkingDate = new Date(), maxDaysToSchedule } = preDataForm;
        setMetadata((state) => ({
            ...state,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
        }));
    }, [preDataForm]);

    const handlerBackAction = () => {
        dispatch(push("/creditCardOtherBanks"));
    };

    const handlerCancelAction = () => {
        setShowExitModal(true);
    };

    const handleAcceptModal = () => {
        dispatch(push("/creditCardOtherBanks"));
        setShowExitModal(false);
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const initialValuesCardEnroll = () => {
        let values = {};

        values = {
            card: "",
            cardLabel: "",
            cardNumber: "",
            debitAccount: "",
            amount: null,
            concept: "",
        };

        return values;
    };

    const validationSchema = () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                debitAccount: Yup.string().required(i18n.get(`${ID_FORM}.account.error.required`)),
                amount: Yup.object().shape({
                    quantity: values.amount.quantity
                        ? Yup.number()
                              .transform((cv, ov) => (ov === "" ? undefined : cv))
                              .min(1, i18n.get(`${ID_FORM}.amount.error.mustBePositive`))
                              .required(i18n.get(`${ID_FORM}.amount.error.required`))
                        : Yup.number()
                              .transform((cv, ov) => (ov === "" ? undefined : cv))
                              .nullable(),
                }),
                concept: Yup.string()
                    .required(i18n.get(`${ID_FORM}.concept.error.required`))
                    .matches(/^[a-zA-Z0-9 ]*$/, i18n.get(`${ID_FORM}.concept.error.invalid`))
                    .test(
                        "no-special-chars",
                        i18n.get(`${ID_FORM}.concept.error.specialCharactersProhibited`),
                        (value) => {
                            if (!value) {
                                return true;
                            }
                            return /^[a-zA-Z0-9 ]*$/.test(value);
                        },
                    ),
            }),
        );

    const validateFormServiceRegistration = (values) => {
        if (
            !selectedAccount ||
            !values.concept ||
            !selectedCard ||
            !values.amount ||
            !values.amount?.quantity ||
            values.amount?.quantity <= 0
        ) {
            return false;
        }
        return true;
    };

    const renderTicket = () => <></>;

    const renderFields = (setFieldValue, values, setValues) => {
        const { isDesktop, creditCards, environments, previewData } = props;

        const getOptionsCard = () =>
            creditCards.map(({ id, cardBrand, number }) => ({
                value: id.toString(),
                label: `${i18n.get(`creditCard.branches.${cardBrand}`)} ${maskCardNumber(number)}`,
            }));

        // const getOptionsAccount = () =>
        //     accounts.map(({ idProduct, numberMask, client, productTypeBackend, currency }) => ({
        //         value: idProduct,
        //         label: `${numberMask} - ${
        //             isDesktop ? formatNameClient(client.name) : client.name
        //         } - ${productTypeBackend} - ${currency}`,
        //     }));

        const creditCardData = creditCards.find((e) => e.id === +selectedCard);

        if (creditCardData) {
            setSelectedCreditCard(creditCardData);
        }

        const onChangeCard = (label, value) => {
            setSelectedCard(value);
            setFieldValue("card", value);
            setFieldValue("cardLabel", label);
        };

        const onChangeAccount = (label, value) => {
            setSelectedAccount(value);
            setFieldValue("debitAccount", value);
            setFieldValue("accountLabel", label);
        };

        const onChangeAmount = (value) => {
            setFieldValue("amount", { currency: USD_CURRENCY, quantity: value });
            setValues({
                ...values,
                idCreditCard: creditCardData.id,
                amount: { currency: USD_CURRENCY, quantity: value },
            });
        };

        const urlImage = () => `images/creditcards/${creditCardData.cardBrand.toLowerCase()}-icon-creditCard.svg`;

        if (mode === "edit" && validateFormServiceRegistration(values)) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }

        if (!creditCardData || fetchingForm) {
            return <Loader />;
        }

        return (
            <>
                {mode === "edit" && (
                    <Box
                        fullWidth
                        background="white"
                        borderRadius="lg"
                        className={classNames({
                            "p-7": isDesktop,
                            "p-5": !isDesktop,
                        })}>
                        <Box>
                            <Box>
                                <Text bold labelKey={`${ID_FORM}.info.card`} />
                            </Box>
                            <Col xs={12} md={9}>
                                <Box>
                                    <Row alignY="flex-end" gapX={7} gapY={3}>
                                        <Col>
                                            <Box className="form-group form-group--select">
                                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                                    <Text
                                                        component="label"
                                                        htmlFor="react-select-card-input"
                                                        labelKey={`${ID_FORM}.field.card`}
                                                        className="data-label"
                                                        size="5"
                                                        bold
                                                    />
                                                </Box>
                                                <Box className="input-group">
                                                    <Select
                                                        id="card"
                                                        name="card"
                                                        label="transactions.list.filter.card.label"
                                                        onChange={(option) => {
                                                            onChangeCard(option.label, option.value);
                                                        }}
                                                        options={getOptionsCard()}
                                                        value={selectedCard}
                                                        defaultValue={idSelectedCard}
                                                        placeholder={i18n.get("global.placeholder.select")}
                                                    />
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                    <Row alignY="flex-end" gapX={7} gapY={3}>
                                        <Col>
                                            <Box className="mt-5">
                                                <Box fullWidth background="background-disabled border-radius-lg">
                                                    {!isDesktop && (
                                                        <Box display="flex" className="pt-5 px-5">
                                                            <Box className="mr-5">
                                                                <Image
                                                                    className="img-card-mobile"
                                                                    color="text-inverse-color"
                                                                    src={urlImage()}
                                                                />
                                                            </Box>
                                                            <Box fullWidth>
                                                                <Info
                                                                    className="info-account-list"
                                                                    labelText={maskCardNumber(creditCardData.number)}
                                                                    text={creditCardData.bankLabel}
                                                                    boldLabel
                                                                />
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    <Box
                                                        display="flex"
                                                        alignY="center"
                                                        gapX="5"
                                                        fullWidth
                                                        className={
                                                            isDesktop
                                                                ? "mt-7 px-5 justify-content-between"
                                                                : "mt-7 px-5 pb-5 justify-content-between"
                                                        }>
                                                        {isDesktop ? (
                                                            <>
                                                                <Box display="flex" alignY="center" className="mr-4">
                                                                    <Image
                                                                        src={urlImage()}
                                                                        className="img-header-card fill-transparent"
                                                                    />
                                                                </Box>
                                                                <Box className="info-account-list card-list-head flex">
                                                                    <Info
                                                                        className="info-account-list"
                                                                        labelText={maskCardNumber(
                                                                            creditCardData.number,
                                                                        )}
                                                                        text={creditCardData.bankLabel}
                                                                    />
                                                                </Box>

                                                                <Info
                                                                    className="info-account-list flex"
                                                                    labelKey="creditCard.otherBanks.list.item.beneficiary"
                                                                    text={creditCardData.beneficiary}
                                                                />

                                                                <Info
                                                                    className="info-account-list"
                                                                    labelKey="creditCard.otherBanks.list.item.alias"
                                                                    text={creditCardData.alias}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Box
                                                                    className={
                                                                        !isDesktop &&
                                                                        "display-flex justify-content-start"
                                                                    }>
                                                                    <Box className={!isDesktop && "mr-9"}>
                                                                        <Info
                                                                            className="info-account-list flex"
                                                                            labelKey="creditCard.otherBanks.list.item.beneficiary"
                                                                            text={creditCardData.beneficiary}
                                                                        />
                                                                    </Box>
                                                                    <Info
                                                                        className="info-account-list"
                                                                        labelKey="creditCard.otherBanks.list.item.alias"
                                                                        text={creditCardData.alias}
                                                                    />
                                                                </Box>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                </Box>
                            </Col>
                        </Box>
                        <Box fullWidth>
                            <Box className="mt-5">
                                <Text bold labelKey={`${ID_FORM}.info.pay`} />
                            </Box>
                            <Col xs={12} md={9}>
                                <Box>
                                    <Row alignY="flex-end" gapX={7} gapY={3}>
                                        <Col xs={12} md={6}>
                                            <Box className="form-group form-group--select">
                                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                                    <Text
                                                        component="label"
                                                        htmlFor="react-select-account-input"
                                                        labelKey={`${ID_FORM}.field.account`}
                                                        className="data-label"
                                                        size="5"
                                                        bold
                                                    />
                                                </Box>
                                                <Box className="input-group">
                                                    <Select
                                                        id="debitAccount"
                                                        name="debitAccount"
                                                        label="transactions.list.filter.account.label"
                                                        onChange={(option) => {
                                                            onChangeAccount(option.label, option.value);
                                                        }}
                                                        options={accountsOptions}
                                                        value={selectedAccount}
                                                        placeholder={i18n.get(`${ID_FORM}.placeholder.account`)}
                                                    />
                                                </Box>
                                            </Box>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Box>
                                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                                    <Text
                                                        component="label"
                                                        htmlFor="react-select-filterType-input"
                                                        labelKey={`${ID_FORM}.field.amount`}
                                                        className="data-label"
                                                        size="5"
                                                        bold
                                                    />
                                                </Box>
                                                <Box
                                                    className={classNames("input-group", {
                                                        "has-error":
                                                            values?.amount?.quantity !== "" &&
                                                            values?.amount?.quantity <= 0,
                                                    })}>
                                                    <Field
                                                        name="amount"
                                                        idForm={ID_FORM}
                                                        component={AmountField}
                                                        maxLength={15}
                                                        onInputChange={onChangeAmount}
                                                        placeholder={i18n.get(`${ID_FORM}.placeholder.amount`)}
                                                        amount={values?.amount?.quantity}
                                                        clearable={false}
                                                        bold={false}
                                                        hideCurrency
                                                        labelNoMarginTop
                                                        fixedDecimalScale
                                                        hideLabel
                                                        hasError={
                                                            values?.amount?.quantity !== "" &&
                                                            values?.amount?.quantity <= 0
                                                        }
                                                        error={i18n.get(
                                                            `${ID_FORM}.field.error.amount.invalid`,
                                                            "El monto a pagar debe ser mayor a cero",
                                                        )}
                                                    />
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                    <Row alignY="flex-end" gapX={7} gapY={3} className="mt-4">
                                        <Col xs={12} md={6}>
                                            <Box>
                                                <Box display="flex" alignY="center" className="data-label-special-mb">
                                                    <Text
                                                        component="label"
                                                        htmlFor="react-select-concept-input"
                                                        labelKey={`${ID_FORM}.field.concept`}
                                                        className="data-label"
                                                        size="5"
                                                        bold
                                                    />
                                                </Box>
                                                <Box className="input-group">
                                                    <Field
                                                        component={TextField}
                                                        idForm={ID_FORM}
                                                        placeholder={i18n.get(`${ID_FORM}.placeholder.concept`)}
                                                        name="concept"
                                                        type="text"
                                                        pattern="^[a-zA-Z0-9 ]*$"
                                                        labelNoMarginTop
                                                        maxLength={100}
                                                        showLabel={false}
                                                    />
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                </Box>
                            </Col>
                        </Box>
                        <Box className="mb-4 mt-5">
                            <AlertInformation
                                withIcon
                                background="info"
                                title={`${ID_FORM}.alert.info`}
                                textClassName="align-justify"
                            />
                        </Box>
                    </Box>
                )}
                {mode === "preview" || mode === "view" ? (
                    <>
                        <TicketData.Main
                            label={`${ID_FORM}.header.pay`}
                            type="amount"
                            currency={USD_CURRENCY}
                            value={values?.amount?.quantity}
                        />

                        <Box>
                            <Box className="mt-5 mb-4">
                                <Text size="6" bold labelKey={`${ID_FORM}.data.card`} />
                            </Box>
                            <TicketData.Data
                                label={`${ID_FORM}.field.card.ticket`}
                                value={
                                    selectedCreditCard &&
                                    `${i18n.get(
                                        `creditCard.branches.${selectedCreditCard.cardBrand}`,
                                    )} ${maskCardNumber(selectedCreditCard.number)}`
                                }
                            />
                            <TicketData.Data
                                label={`${ID_FORM}.field.bank`}
                                value={selectedCreditCard && selectedCreditCard?.bankLabel}
                            />
                            <Box className="mt-5 mb-4">
                                <Text size="6" bold labelKey={`${ID_FORM}.data.beneficiary`} />
                            </Box>
                            <TicketData.Data
                                label={`${ID_FORM}.field.ruc`}
                                value={environments && environments[0].productGroupId}
                            />
                            <TicketData.Data
                                label={`${ID_FORM}.beneficiary.name`}
                                value={selectedCreditCard && selectedCreditCard?.beneficiary}
                            />
                            <TicketData.Data
                                label={`${ID_FORM}.beneficiary.alias`}
                                value={selectedCreditCard && selectedCreditCard?.alias}
                            />
                            <Box className="mt-5 mb-4">
                                <Text size="6" bold labelKey={`${ID_FORM}.data.payment`} />
                            </Box>
                            <TicketData.Data
                                label={`${ID_FORM}.field.account`}
                                value={values && values?.accountLabel}
                            />
                            <TicketData.Data label={`${ID_FORM}.field.concept`} value={values && values?.concept} />
                            <TicketData.Data
                                label={`${ID_FORM}.field.tax`}
                                value={previewData && formatNumber(previewData.rate ?? 0, 2)}
                            />
                            <TicketData.Data label={`${ID_FORM}.field.channel`} value={transferRoute} />
                        </Box>
                    </>
                ) : null}
                <ExitModal
                    modalShow={showExitModal}
                    acceptFunction={handleAcceptModal}
                    cancelFunction={handleHideModal}
                    headingText={i18n.get("confirm.exit.title")}
                    text={i18n.get("confirm.exit.info")}
                />
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        ticketConfirmation: true,
        cancelAction: handlerCancelAction,
        handleCancelPreview: handlerCancelAction,
        handleBackCustom: handlerBackAction,
        handleBackDesktopCustom: handlerBackAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        showSchedulerMessage: false,
        // validate: validateForm,
        titleFormConfirmation: "OTP CODE",
        data: initialValuesCardEnroll(),
        submitButtonLabel: `${ID_FORM}.button.confirm`,
        validationSchema,
        textBack: "global.return",
        textBackConfirmation: "global.return",
        submitDisabled: !enabledSubmit,
        showFormAcions: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

PayCardOtherBank.defaultProps = {
    mode: "edit",
    formProducts: [],
    creditCards: null,
    preDataForm: {},
    previewData: {},
    fetchingForm: false,
};

PayCardOtherBank.propTypes = {
    environments: arrayOf(arrayOf(oneOfType([string, shape({})]))).isRequired,
    mode: string,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    fetchingForm: bool,
    idSelectedCard: string.isRequired,
    formProducts: arrayOf(shape({})),
    creditCards: arrayOf(shape({})),
    preDataForm: shape({}),
    previewData: shape({}),
};

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;

    return {
        environments: settingsSelectors.getEnvironments(state),
        fetching: creditCardOtherBankSelectors.getFetching(state),
        creditCards: creditCardOtherBankSelectors.getCreditCardsList(state),
        idSelectedCard: creditCardOtherBankSelectors.getIdSelectedCard(id),
        currentLang: i18nSelectors.getLang(state),
        fetchingForm: formSelectors.getFetching(state),
        preDataForm: formSelectors.getPreData(state),
        transaction: formSelectors.getTransaction(state),
        mode: formSelectors.getMode(state),
        previewData: formSelectors.getPreviewData(state),
        formProducts: formSelectors.getCustomProducts(state),
    };
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(PayCardOtherBank));
