import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { resizableRoute } from "pages/_components/Resizable";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import * as i18n from "util/i18n";

import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { push } from "react-router-redux";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { selectors as sessionSelectors } from "reducers/session";
import * as deviceUtils from "util/device";
import { isEmpty } from "lodash";
import { PERMISSION_CREDITCARD_CORPORATE_PAYMENT, PERMISSION_CREDITCARD_CORPORATE_READ } from "constants.js";
import { actions as creditCardsActions } from "reducers/creditCard";
import { get as getMessage } from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import { selectors as accountsSelectors } from "reducers/accounts";

export const MODAL_TEXT_ACTIVATE = "activate";

const CreditCardMenu = ({
    isMobile,
    userFullName,
    dispatch,
    isDeviceWithFingerprint,
    fingerprintConfiguredUserDevices,
    availability,
    deviceHasFingerPrinter,
    permissionCorporatePayment,
    permissionCorporateRead,
    accounts,
}) => {
    const [orderedOptions, setOrderedOptions] = useState([]);
    const [colsInRow, setColsInRow] = useState(null);
    const [showDeleteFingerprintModal, setShowDeleteFingerprintModal] = useState(false);
    const [showFingerDisclaimerModal, setShowFingerDisclaimerModal] = useState(false);
    const [fingerprintAction, setFingerprintAction] = useState("");

    useEffect(() => {
        dispatch(fingerprintActions.fingerprintConfigurationPre());
        dispatch(fingerprintActions.fingerprintAvailability());
    }, []);

    useEffect(() => {
        buildMenu();
    }, [isDeviceWithFingerprint]);

    useEffect(() => {
        if (colsInRow === null) {
            buildMenu();
        }
    }, [isMobile, colsInRow]);

    const handleClickLink = (url) => {
        if (url === "/creditCardOtherBanks" && accounts.length === 0) {
            dispatch(
                notificationActions.showNotification(getMessage("account.operation.NoDispose"), "info", [
                    "settings",
                ]),
            );
        }
        else { dispatch(push(url)); }
    };

    const buildMenu = () => {
        const newColInRow = isMobile ? 1 : 2;
        const newOptions = [];

        newOptions.push({
            icon: "images/icons/consultaTarjetasPropiasIcon.svg",
            title: i18n.get("creditCard.menu.consultation.TC.propias.title", "Consulta de TC propias"),
            description: i18n.get(
                "creditCard.menu.consultation.TC.propias.description",
                "Realice consultas de las tarjetas de crédito propias sean estas titulares o adicionales.",
            ),
            link: "/creditCards",
        });

        newOptions.push({
            icon: "images/icons/pagoTarjetasPropiasIcon.svg",
            title: i18n.get("creditCard.menu.pay.TC.propias.title", "Pago de TC propias"),
            description: i18n.get(
                "creditCard.menu.pay.TC.propias.description",
                "Gestione los pagos de tarjetas de crédito empresariales propias.",
            ),
            link: "/pagoTCPropias",
        });

        newOptions.push({
            icon: "images/icons/pagoTarjetaOtrosBancosIcon.svg",
            title: i18n.get("creditCard.menu.pay.TC.other.title", "Pago de TC otros bancos"),
            description: i18n.get(
                "creditCard.menu.pay.TC.other.description",
                "Gestione los pagos de las tarjetas de crédito emitidas por otros bancos.",
            ),
            link: "/creditCardOtherBanks",
        });

        if(permissionCorporateRead){
        newOptions.push({
            icon: "images/icons/consultaTarjetasCorporativasIcon.svg",
            title: i18n.get("creditCard.menu.consultation.TC.corporative.title", "Consulta de TC corporativas"),
            description: i18n.get(
                "creditCard.menu.consultation.TC.corporative.description",
                "Realice consultas de las tarjetas corporativas o de franquiciados.",
            ),
            link: "/CreditCardsCorporate",
        });
        }

        if (permissionCorporatePayment) {
            dispatch(creditCardsActions.clearCorporateMovementConsumeRequest());
            newOptions.push({
                icon: "images/icons/pagoTarjetasCorporativasIcon.svg",
                title: i18n.get("creditCard.menu.pay.TC.corporative.title", "Pago de TC corporativas"),
                description: i18n.get(
                    "creditCard.menu.pay.TC.corporative.description",
                    "Gestione los pagos de tarjetas corporativas o de franquiciados.",
                ),
                link: "/CreditCardsCorporatePayment",
            });
        }

        const newOrderedOptions = newOptions.reduce(
            (allOrdOpts, opt, i) => {
                const newAllOrdOpts = [...allOrdOpts];

                // Agrupar de a 3 elementos por fila
                if (newAllOrdOpts[newAllOrdOpts.length - 1].length === newColInRow) {
                    newAllOrdOpts.push([]);
                }
                const lastI = newAllOrdOpts.length - 1;
                newAllOrdOpts[lastI].push(opt);

                // En ultima iteración agregar elementos vacios en la ultima fila
                // para mantener mismo tamaño de elemento
                if (newOptions.length - 1 === i) {
                    while (newAllOrdOpts[lastI].length !== newColInRow) {
                        newAllOrdOpts[lastI].push({});
                    }
                }

                return newAllOrdOpts;
            },
            [[]],
        );

        setColsInRow(newColInRow);
        setOrderedOptions(newOrderedOptions);
    };

    const isBiometricAvailable = () =>
        deviceUtils.isMobileNativeFunc() &&
        !isDeviceWithFingerprint &&
        availability &&
        availability.isHardwareDetected &&
        !isDeviceIdInSession();

    const isDeviceIdInSession = () => {
        if (deviceUtils.isMobileNativeFunc() && fingerprintConfiguredUserDevices) {
            const device = fingerprintConfiguredUserDevices.filter(
                (item) => item.deviceId.search(deviceUtils.getDeviceId()) !== -1,
            );
            return !isEmpty(device);
        }
        return false;
    };

    const configureNow = () => {
        dispatch(fingerprintActions.fingerprintVerification());
    };

    const enrollFingerprintOnDevice = () => {
        dispatch(fingerprintActions.enrollFingerprintOnDevice());
    };

    const onClickFingerActionHandler = (fingerprintAction) => {
        setShowFingerDisclaimerModal(true);
        setFingerprintAction(fingerprintAction);
    };

    const activateFingerprintDevice = () => {
        if (deviceUtils.isMobileNativeFunc()) {
            if (isBiometricAvailable()) {
                if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                    configureNow();
                } else {
                    enrollFingerprintOnDevice();
                }
            }
            return;
        }
        onClickFingerActionHandler(MODAL_TEXT_ACTIVATE);
    };

    const removeFingerprintDevices = () => {
        console.log("Remove all user devices: ", fingerprintConfiguredUserDevices);
        dispatch(fingerprintActions.fingerprintDeleteAllDevices());
        hideDeleteFingerprintModal();
    };
    const hideDeleteFingerprintModal = () => {
        setShowDeleteFingerprintModal(false);
        // /settings/fingerprintConfiguration
    };

    return (
        <>
            <Notification scopeToShow="settings" />
            <Head titleText={i18n.get("menu.creditcards", "Tarjetas de crédito")} backLinkTo={isMobile ? "/desktop" : false} />
            <MainContainer
                className={`main-container border-radius-lg mt-3 box-shadow-small background-white texture-header ${isMobile ? 'p-5' : 'p-7'}`}
                showLoader={false}>
                <div className="above-the-fold">
                    <Box fullWidth display="flex" className={`border-radius-lg box-shadow-small background-white ${isMobile ? 'px-5 py-5 mb-5' : 'px-7 py-7 mb-7'}`}>
                        <Box fullWidth>
                            <Box >
                                <Text
                                    component="h2"
                                    defaultValue={i18n.get(
                                        "creditCards.detail.title",
                                        "Administre sus tarjetas de crédito",
                                    )}
                                    bold
                                />
                            </Box>
                            <Box>
                                <Text
                                    defaultValue={i18n.get(
                                        "creditCards.detail.subtitle",
                                        "Revise sus movimientos, saldos y fechas de pago",
                                    )}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Image src="images/creditCards.svg" color="transparent" className="img-header-card" />
                            {/* <Image src="images/creditCards.svg" className="img-header-card" /> */}
                        </Box>
                    </Box>
                    {orderedOptions.map((rowOptions) => (
                        <Box fullWidth display="flex" key={rowOptions[0]?.title}>
                            {rowOptions.map((option, i) => (
                                <Box
                                    flex1
                                    key={option.title || `empty-${i}`}
                                    className={classNames("border-radius-lg ", {
                                        "mr-7": !!((i + 1) % colsInRow),
                                        "box-shadow-small": !!option.title,
                                        "cursor-pointer": !!option.title,
                                        "px-5 py-5 mb-5": isMobile,
                                        "px-7 py-7 mb-7": !isMobile,
                                    })}
                                    onClick={() => (option.link ? handleClickLink(option.link) : {})}>
                                    <Box display="flex" alignX="between">
                                        {option.icon && (
                                            <Box fitWidth className="mb-2">
                                                <Image src={option.icon} color="transparent" />
                                            </Box>
                                        )}
                                        {option.buttonAction && (
                                            <Button
                                                bsStyle={option.buttonStyle}
                                                image={option.buttonIcon}
                                                defaultLabelText={option.buttonText}
                                                onClick={option.buttonAction}
                                                className="mb-2"
                                            />
                                        )}
                                    </Box>
                                    {option.title && (
                                        <Box>
                                            <Text component="h4" bold defaultValue={option.title} />
                                        </Box>
                                    )}
                                    {option.description && (
                                        <Box className={!isMobile && "mr-7"}>
                                            <Text defaultValue={option.description} />
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </div>
            </MainContainer>
            {/* <TextAndButtonsModal
                modalShow={showDeleteFingerprintModal}
                acceptFunction={() => {
                    removeFingerprintDevices();
                }}
                cancelFunction={() => {
                    hideDeleteFingerprintModal();
                }}
                confirmLabel="fingerprint.deactivate.button.accept"
                headingText={i18n.get("fingerprint.deactivate.title")}
                text={i18n.get("fingerprint.deactivate.text")}
                modalId="widgets.update.data.modal"
            />
            <TextAndButtonsModal
                modalShow={showFingerDisclaimerModal}
                acceptFunction={() => {
                    setShowFingerDisclaimerModal(false);
                }}
                confirmLabel="global.exit"
                headingText={i18n.get(`fingerprint.disclaimer.${fingerprintAction.toLowerCase()}.title`)}
                text={`${i18n.get(`fingerprint.disclaimer.${fingerprintAction.toLowerCase()}.text`)} ${i18n.get(
                    `global.disclaimer.note`,
                )}`}
            /> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    availability: fingerprintSelectors.getAvailability(state),
    isDeviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    fingerprintConfiguredUserDevices: fingerprintSelectors.getFingerprintConfiguredUserDevices(state),
    deviceHasFingerPrinter: fingerprintSelectors.deviceHasFingerPrinter(state),
    permissionCorporatePayment: sessionSelectors.hasPermissions(state, [PERMISSION_CREDITCARD_CORPORATE_PAYMENT]),
    permissionCorporateRead: sessionSelectors.hasPermissions(state, [PERMISSION_CREDITCARD_CORPORATE_READ]),
    accounts: accountsSelectors.getAccounts(state),
});

CreditCardMenu.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    userFullName: string,
    isDeviceWithFingerprint: bool,
    fingerprintConfiguredUserDevices: arrayOf(
        shape({
            device: shape({
                deviceId: string.isRequired,
                deviceModel: string.isRequired,
                idSession: string.isRequired,
            }),
        }),
    ),
    availability: shape({
        isHardwareDetected: bool,
        isAvailable: bool,
    }),
    deviceHasFingerPrinter: bool.isRequired,
    permissionCorporatePayment: bool.isRequired,
    permissionCorporateRead: bool.isRequired,
};

CreditCardMenu.defaultProps = {
    userFullName: null,
    isDeviceWithFingerprint: false,
    fingerprintConfiguredUserDevices: null,
    availability: null,
};

export default connect(mapStateToProps)(resizableRoute(CreditCardMenu));
