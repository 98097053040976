import { resizableRoute } from "pages/_components/Resizable";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { push } from "react-router-redux";
import classNames from "classnames";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import { Modal } from "react-bootstrap";
import { isMobileNativeFunc } from "util/device";
import { PERMISSION_DOCUMENT_SWIFT } from "constants.js";

const DocumentsHome = ({ isMobile, userFullName, fingerprintAvailable, dispatch, permissionDocumentsSwift }) => {
    const [orderedOptions, setOrderedOptions] = useState([]);
    const [colsInRow, setColsInRow] = useState(null);
    const [showDeleteAccessMethodModal, setShowDeleteAccessMethodModal] = useState(false);

    useEffect(() => {
        if (colsInRow === null) {
            const newColInRow = isMobile ? 1 : 2;
            const newOptions = [];

            if (permissionDocumentsSwift) {
                newOptions.push({
                    icon: "images/documents/documents_swift.svg",
                    title: i18n.get("documents.swift.header"),
                    description: i18n.get("documents.swift.description"),
                    link: "/documentsSwift",
                });
            }

            newOptions.push({
                icon: "images/documents/documents_operational.svg",
                title: i18n.get("documents.operational.header"),
                description: i18n.get("documents.operational.description"),
                link: "/documentsOperational",
            });

            if (!isMobileNativeFunc()) {
                newOptions.push({
                    icon: "images/documents/documents_tax.svg",
                    title: i18n.get("documents.tax.header"),
                    description: i18n.get("documents.tax.description"),
                    link: "/documentsTax",
                });
            }

            const newOrderedOptions = newOptions.reduce(
                (allOrdOpts, opt, i) => {
                    const newAllOrdOpts = [...allOrdOpts];

                    // Agrupar de a 3 elementos por fila
                    if (newAllOrdOpts[newAllOrdOpts.length - 1].length === newColInRow) {
                        newAllOrdOpts.push([]);
                    }
                    const lastI = newAllOrdOpts.length - 1;
                    newAllOrdOpts[lastI].push(opt);

                    // En ultima iteración agregar elementos vacios en la ultima fila
                    // para mantener mismo tamaño de elemento
                    if (newOptions.length - 1 === i) {
                        while (newAllOrdOpts[lastI].length !== newColInRow) {
                            newAllOrdOpts[lastI].push({});
                        }
                    }

                    return newAllOrdOpts;
                },
                [[]],
            );

            setColsInRow(newColInRow);
            setOrderedOptions(newOrderedOptions);
        }
    }, [isMobile, colsInRow]);

    const handleClickLink = (url) => {
        dispatch(push(url));
    };

    return (
        <>
            <Notification scopeToShow="settings" />
            <Head titleText={i18n.get("documents.home.title")} {...(isMobile && { backLinkTo: "/desktop" })} />
            <MainContainer
                className={`main-container border-radius-lg mt-3 box-shadow-small background-white texture-header ${
                    isMobile ? "p-5" : "p-7"
                }`}
                showLoader={false}>
                <div className="above-the-fold">
                    <Box
                        fullWidth
                        display="flex"
                        className={`border-radius-lg box-shadow-small background-white ${
                            isMobile ? "px-5 py-5 mb-5" : "px-7 py-7 mb-7"
                        }`}>
                        <Box fullWidth>
                            <Box>
                                <Text component="h2" defaultValue={i18n.get("documents.home.description")} bold />
                            </Box>
                            <Box>
                                <Text defaultValue={i18n.get("documents.home.header")} />
                            </Box>
                        </Box>
                        <Box>
                            <Image src="images/documents/docs_header.svg" color="transparent" />
                        </Box>
                    </Box>
                    {orderedOptions.map((rowOptions) => (
                        <Box fullWidth display="flex" key={rowOptions[0]?.title}>
                            {rowOptions.map((option, i) => (
                                <Box
                                    flex1
                                    key={option.title || `empty-${i}`}
                                    className={classNames("border-radius-lg ", {
                                        "mr-7": !!((i + 1) % colsInRow),
                                        "box-shadow-small": !!option.title,
                                        "cursor-pointer": !!option.title,
                                        "px-5 py-5 mb-5": isMobile,
                                        "px-7 py-7 mb-7": !isMobile,
                                    })}
                                    onClick={() => (option.link ? handleClickLink(option.link) : {})}>
                                    <Box display="flex" alignX="between">
                                        {option.icon && (
                                            <Box fitWidth className="mb-2">
                                                <Image src={option.icon} color="transparent" />
                                            </Box>
                                        )}
                                        {option.buttonAction && (
                                            <Button
                                                bsStyle="text"
                                                image={option.buttonIcon}
                                                defaultLabelText={option.buttonText}
                                                onClick={option.buttonAction}
                                                className="mb-2"
                                            />
                                        )}
                                    </Box>
                                    {option.title && (
                                        <Box>
                                            <Text component="h2" bold defaultValue={option.title} />
                                        </Box>
                                    )}
                                    {option.description && (
                                        <Box>
                                            <Text defaultValue={option.description} />
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </div>
            </MainContainer>
            <div className="modal-container">
                <Modal show={showDeleteAccessMethodModal}>
                    <Box className="p-7">
                        <Box isplay="flex" alignX="center" className="my-9">
                            <Image src="images/icons/warning.svg" className="svg-icon" />
                        </Box>
                        <Box display="flex" alignX="center" className="my-9">
                            <Text align="center" size="3" color="text">
                                {i18n.get("", "¿Está seguro que quiere eliminar el dispositivo configurado?")}
                            </Text>
                        </Box>
                        <Box className="my-9 align-center">
                            <Text
                                color="text"
                                defaultValue={i18n.get(
                                    "",
                                    "Si desactiva la identificación biométrica, el único modo que se activara es por medio de la&nbsp;",
                                )}
                            />
                            <Text color="text" bold defaultValue={i18n.get("", "app Banca Empresas.")} />
                        </Box>
                        <Box display="flex" column fullWidth>
                            <Button label="global.delete" bsStyle="primary" onClick={() => {}} block />
                            <Button
                                label="global.cancel"
                                bsStyle="outline"
                                onClick={() => setShowDeleteAccessMethodModal(false)}
                                block
                            />
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    permissionDocumentsSwift: sessionSelectors.hasPermissions(state, [PERMISSION_DOCUMENT_SWIFT]),
});

DocumentsHome.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    userFullName: string,
    fingerprintAvailable: bool.isRequired,
    permissionDocumentsSwift: bool.isRequired,
};

DocumentsHome.defaultProps = {
    userFullName: null,
};

export default connect(mapStateToProps)(resizableRoute(DocumentsHome));
