import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import withRouter from "react-router-dom/withRouter";
import { isDesktop } from "react-device-detect";
import { bool, func, string, shape, arrayOf, oneOfType } from "prop-types";
import { Col } from "react-bootstrap";
import { push } from "react-router-redux";
import { Field } from "formik";
import * as Yup from "yup";

import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import {
    actions as creditCardOtherBankActions,
    selectors as creditCardOtherBankSelectors,
} from "reducers/creditCardOtherBank";

import { resizableRoute } from "pages/_components/Resizable";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import ExitModal from "pages/_components/modal/ExitModal";
import Select from "pages/forms/_components/_fields/Select";
import TicketData from "pages/_components/TicketData";
import TextField from "pages/_components/fields/TextField";
import FormTransition from "pages/forms/_components/FormTransition";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import CatastroInputField from "pages/forms/customForms/_customFields/serviceRegistrationField/CatastroInputField";

import * as i18n from "util/i18n";
import * as config from "util/config";
import { maskCardNumber } from "util/mask";
import * as stringUtils from "util/string";

const ID_FORM = "creditCard.otherBanks.enroll";
const ID_ACTIVITY = `${ID_FORM}.send`;
const TITLE_FORM = `${ID_FORM}.title`;
const LENGTH_CARD_NUMBER = 16;

const NewCardOtherBank = (props) => {
    const { dispatch, mode, banks, preDataForm } = props;

    const [showExitModal, setShowExitModal] = useState(false);
    const [enabledSubmit, setEnabledSubmit] = useState(false);
    const [disabledBank, setDisabledBank] = useState(true);
    const [selectedCardType, setSelectedCardType] = useState("");
    const [selectedCardNumber, setSelectedCardNumber] = useState("");
    const [selectedBank, setSelectedBank] = useState("");
    const [selectedRUC, setSelectedRUC] = useState("");

    const [metadata, setMetadata] = useState({
        draftsEnabled: false,
        templatesEnabled: false,
        schedulable: true,
        programable: true,
        idActivity: ID_ACTIVITY,
    });

    useEffect(() => {
        dispatch(formActions.preCustomForm(["creditcard.payment.otherbanks.pay"]));
    }, []);

    useEffect(() => {
        dispatch(settingsActions.getEnvironmentsRequest());
    }, []);

    useEffect(() => {
        const { nonWorkingDays, enabledWeekDays, firstWorkingDate = new Date(), maxDaysToSchedule } = preDataForm;
        setMetadata((state) => ({
            ...state,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
        }));
    }, [preDataForm]);

    useEffect(() => {
        dispatch(creditCardOtherBankActions.getBanks(selectedCardType));
    }, [selectedCardType, dispatch]);

    const handlerCancelAction = () => {
        setShowExitModal(true);
    };

    const handleAcceptModal = () => {
        dispatch(push("/creditCardOtherBanks"));
        setShowExitModal(false);
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const initialValuesCardEnroll = () => {
        let values = {};

        values = {
            cardType: "",
            cardTypeLabel: "",
            cardNumber: "",
            bank: "",
            bankLabel: "",
            ruc: "",
            beneficiary: "",
            alias: "",
        };

        return values;
    };

    const validationSchema = () =>
        Yup.object().shape({
            cardNumber: Yup.string()
                .transform((value) => {
                    if (value) {
                        return value
                            .trim()
                            .replaceAll("-", "")
                            .replaceAll("_", "");
                    }
                    return "";
                })
                .required(i18n.get(`${ID_FORM}.cardNumber.emptyOrInvalid.error`))
                .test(
                    "max-length",
                    i18n.get(`${ID_FORM}.cardNumber.maximumIdentifierSize.error`, "", {
                        MAX_LENGTH: LENGTH_CARD_NUMBER,
                    }),
                    (value) => {
                        if (!value) {
                            return false;
                        }
                        return value.length === LENGTH_CARD_NUMBER;
                    },
                ),
            beneficiary: Yup.string()
                .required(i18n.get(`${ID_FORM}.beneficiary.empty.error`))
                .matches(/^[a-zA-Z0-9 ]*$/, i18n.get(`${ID_FORM}.beneficiary.invalid.error`))
                .test(
                    "no-special-chars",
                    i18n.get(`${ID_FORM}.beneficiary.specialCharactersProhibited.error`),
                    (value) => {
                        if (!value) {
                            return true;
                        }
                        return /^[a-zA-Z0-9 ]*$/.test(value);
                    },
                ),
            alias: Yup.string()
                .required(i18n.get(`${ID_FORM}.alias.empty.error`))
                .matches(/^[a-zA-Z0-9 ]*$/, i18n.get(`${ID_FORM}.alias.invalid.error`))
                .test("no-special-chars", i18n.get(`${ID_FORM}.alias.specialCharactersProhibited.error`), (value) => {
                    if (!value) {
                        return true;
                    }
                    return /^[a-zA-Z0-9 ]*$/.test(value);
                }),
        });

    const validateFormServiceRegistration = (values) => {
        if (
            !selectedBank ||
            !values.beneficiary ||
            !selectedCardNumber ||
            !selectedCardType ||
            !selectedRUC ||
            !values.alias
        ) {
            return false;
        }
        return true;
    };

    const renderTicket = () => <></>;

    const renderFields = (setFieldValue, values, setValues, renderScheduler, errors) => {
        const { environments } = props;

        if (environments && environments[0] && values.ruc === "") {
            setSelectedRUC(environments[0].productGroupId);
            setFieldValue("ruc", environments[0].productGroupId);
        }

        const getOptionsCardType = () => {
            const cardTypeArray = config.getArray("creditCards.branches", []);

            return cardTypeArray.map((idCardType) => ({
                value: idCardType,
                label: i18n.get(`creditCard.branches.${idCardType}`),
            }));
        };

        const getOptionsBank = () =>
            banks.map(({ id, description }) => ({
                value: id,
                label: description.substring(description.indexOf(" "), description.length),
            }));

        const getValueWithoutMask = (value) => value.replaceAll(" ", "").replaceAll("-", "");

        const onChangeCardType = (label, value) => {
            setSelectedCardType(value);
            setFieldValue("cardType", value);
            setFieldValue("cardTypeLabel", label);
            setFieldValue("bank", "");
            setFieldValue("bankLabel", "");
            setSelectedBank("");
        };

        if (mode === "edit" && validateFormServiceRegistration(values)) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }

        const getErrorMessage = (fieldName) => {
            switch (fieldName) {
                case "cardNumber":
                    return errors.cardNumber ? errors.cardNumber : null;
                case "beneficiary":
                    return errors.beneficiary ? errors.beneficiary : null;
                case "alias":
                    return errors.alias ? errors.alias : null;
                default:
                    return null;
            }
        };

        if (!banks || banks.length === 0) {
            setDisabledBank(true);
        } else {
            setDisabledBank(false);
        }

        return (
            <>
                {mode === "edit" && (
                    <Box
                        fullWidth
                        background="white"
                        borderRadius="lg"
                        className={
                            isDesktop
                                ? "box-shadow-small form-service-payments p-7 mb-11"
                                : "form-service-payments pt-7 mb-5"
                        }>
                        <Box>
                            <Text bold labelKey={`${ID_FORM}.info`} size={isDesktop ? "5" : "3"} />
                        </Box>
                        <Col xs={12} md={9}>
                            <Box>
                                <Row alignY="flex-end" gapX={7} gapY={isDesktop ? 3 : 5}>
                                    <Col xs={12} md={6}>
                                        <Box className="form-group form-group--select">
                                            <Box display="flex" alignY="center" className="data-label-special-mb">
                                                <Text
                                                    component="label"
                                                    htmlFor="react-select-cardType-input"
                                                    labelKey={`${ID_FORM}.field.cardType`}
                                                    className="data-label"
                                                    size={isDesktop ? "5" : "3"}
                                                    semibold
                                                />
                                            </Box>
                                            <Box className="input-group">
                                                <Select
                                                    id="cardType"
                                                    name="cardType"
                                                    label="transactions.list.filter.cardType.label"
                                                    onChange={(option) => {
                                                        onChangeCardType(option.label, option.value);
                                                    }}
                                                    options={getOptionsCardType()}
                                                    value={selectedCardType}
                                                    placeholder={i18n.get("global.placeholder.select")}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Box>
                                            <Box display="flex" alignY="center" className="data-label-special-mb">
                                                <Text
                                                    component="label"
                                                    htmlFor="react-select-filterType-input"
                                                    labelKey={`${ID_FORM}.field.cardNumber`}
                                                    className="data-label"
                                                    size={isDesktop ? "5" : "3"}
                                                    semibold
                                                />
                                            </Box>
                                            <Box className="input-group">
                                                <CatastroInputField
                                                    name="cardNumber"
                                                    mask="9999-9999-9999-9999"
                                                    placeholder="0000-0000-0000-0000"
                                                    value={selectedCardNumber}
                                                    onChange={(e) => {
                                                        setSelectedCardNumber(getValueWithoutMask(e.target.value));
                                                        setFieldValue(
                                                            "cardNumber",
                                                            getValueWithoutMask(e.target.value),
                                                        );
                                                    }}
                                                    error={getErrorMessage("cardNumber")}
                                                    isRequired
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                                <Row alignY="flex-end" gapX={7} gapY={isDesktop ? 3 : 5}>
                                    <Col xs={12} md={6}>
                                        <Box className="form-group form-group--select">
                                            <Box display="flex" alignY="center" className="data-label-special-mb">
                                                <Text
                                                    component="label"
                                                    htmlFor="react-select-bank-input"
                                                    labelKey={`${ID_FORM}.field.bank`}
                                                    className="data-label"
                                                    size={isDesktop ? "5" : "3"}
                                                    semibold
                                                />
                                            </Box>
                                            <Box className="input-group">
                                                <Select
                                                    id="bank"
                                                    name="bank"
                                                    label="transactions.list.filter.bank.label"
                                                    avoidChangeOneOption
                                                    onChange={(option) => {
                                                        setSelectedBank(option.value.toString());
                                                        setFieldValue("bank", option.value.toString());
                                                        setFieldValue("bankLabel", option.label);
                                                    }}
                                                    options={getOptionsBank()}
                                                    value={selectedBank}
                                                    placeholder={i18n.get("global.placeholder.select")}
                                                    disabled={disabledBank}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Box>
                                            <Box display="flex" alignY="center" className="data-label-special-mb">
                                                <Text
                                                    component="label"
                                                    htmlFor="react-select-filterType-input"
                                                    labelKey={`${ID_FORM}.field.ruc`}
                                                    className="data-label"
                                                    size={isDesktop ? "5" : "3"}
                                                    semibold
                                                />
                                            </Box>
                                            <Box className="input-group">
                                                <TextSimpleField
                                                    id="ruc"
                                                    name="ruc"
                                                    value={selectedRUC}
                                                    bold
                                                    onChange={(e) => {
                                                        setSelectedRUC(e.target.value);
                                                        setFieldValue("ruc", e.target.value);
                                                    }}
                                                    disabled
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                                <Row alignY="flex-end" gapX={7} gapY={isDesktop ? 3 : 5}>
                                    <Col xs={12} md={6}>
                                        <Box>
                                            <Box display="flex" alignY="center" className="data-label-special-mb mt-6">
                                                <Text
                                                    component="label"
                                                    htmlFor="react-select-beneficiary-input"
                                                    labelKey={`${ID_FORM}.field.beneficiary`}
                                                    className="data-label"
                                                    size={isDesktop ? "5" : "3"}
                                                    semibold
                                                />
                                            </Box>
                                            <Box className="input-group">
                                                <Field
                                                    component={TextField}
                                                    idForm={ID_FORM}
                                                    placeholder={i18n.get(`${ID_FORM}.placeholder.input`)}
                                                    name="beneficiary"
                                                    type="text"
                                                    pattern="^[a-zA-Z0-9 ]*$"
                                                    labelNoMarginTop
                                                    maxLength={60}
                                                    showLabel={false}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Box>
                                            <Box display="flex" alignY="center" className="data-label-special-mb">
                                                <Text
                                                    component="label"
                                                    htmlFor="react-select-filterType-input"
                                                    labelKey={`${ID_FORM}.field.alias`}
                                                    className="data-label"
                                                    size={isDesktop ? "5" : "3"}
                                                    semibold
                                                />
                                            </Box>
                                            <Box className="input-group">
                                                <Field
                                                    component={TextField}
                                                    idForm={ID_FORM}
                                                    placeholder={i18n.get(`${ID_FORM}.placeholder.input`)}
                                                    name="alias"
                                                    type="text"
                                                    pattern="^[a-zA-Z0-9 ]*$"
                                                    labelNoMarginTop
                                                    maxLength={20}
                                                    showLabel={false}
                                                    spellCheck="false"
                                                    nestedErrorsObject
                                                    showError={errors && errors?.alias}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Col>
                    </Box>
                )}
                {mode === "preview" || mode === "view" ? (
                    <>
                        <TicketData.Main
                            label={`${ID_FORM}.header.card`}
                            value={`${values.cardTypeLabel} ${maskCardNumber(values.cardNumber)}`}
                        />

                        <Box>
                            <Box className="mt-5 mb-4">
                                <Text size="6" bold labelKey={`${ID_FORM}.data.card`} />
                            </Box>

                            <TicketData.Data
                                label={`${ID_FORM}.field.bank`}
                                value={
                                    values &&
                                    values.bankLabel
                                        .split(" ")
                                        .map((word) => stringUtils.capitalizeFirstLetter(word))
                                        .join(" ")
                                }
                            />

                            <Box className="mt-5 mb-4">
                                <Text size="6" bold labelKey={`${ID_FORM}.data.beneficiary`} />
                            </Box>
                            <TicketData.Data label={`${ID_FORM}.field.ruc`} value={values && values.ruc} />
                            <TicketData.Data
                                label={`${ID_FORM}.beneficiary.name`}
                                value={values && values.beneficiary}
                            />
                            <TicketData.Data label={`${ID_FORM}.field.alias`} value={values && values.alias} />
                        </Box>
                    </>
                ) : null}
                <ExitModal
                    modalShow={showExitModal}
                    acceptFunction={handleAcceptModal}
                    cancelFunction={handleHideModal}
                    headingText={i18n.get("confirm.exit.title")}
                    text={i18n.get("confirm.exit.info")}
                />
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        ticketConfirmation: true,
        cancelAction: handlerCancelAction,
        handleCancelPreview: handlerCancelAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        showSchedulerMessage: false,
        // validate: validateForm,
        titleFormConfirmation: "OTP CODE",
        data: initialValuesCardEnroll(),
        submitButtonLabel: `${ID_FORM}.button.confirm`,
        validationSchema,
        textBack: "global.return",
        textBackConfirmation: "global.return",
        submitDisabled: !enabledSubmit,
        showFormAcions: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

NewCardOtherBank.defaultProps = {
    mode: "edit",
    banks: null,
    preDataForm: {},
    previewData: {},
};

NewCardOtherBank.propTypes = {
    environments: arrayOf(arrayOf(oneOfType([string, shape({})]))).isRequired,
    mode: string,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    banks: arrayOf(shape({})),
    preDataForm: shape({}),
    previewData: shape({}),
};

const mapStateToProps = (state) => ({
    environments: settingsSelectors.getEnvironments(state),
    fetching: creditCardOtherBankSelectors.getFetching(state),
    banks: creditCardOtherBankSelectors.getListBank(state),
    currentLang: i18nSelectors.getLang(state),
    fetchingForm: formSelectors.getFetching(state),
    preDataForm: formSelectors.getPreData(state),
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    previewData: formSelectors.getPreviewData(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(NewCardOtherBank));
