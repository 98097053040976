import classNames from "classnames";
import RadioButton from "pages/_components/fields/Radio";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Tooltip from "pages/_components/Tooltip";

class RadioButtonGroup extends Component {
    static propTypes = {
        cControlClassName: string,
        className: string,
        disabled: bool,
        inLineControl: bool,
        labelKey: string,
        name: string.isRequired,
        onChange: func.isRequired,
        options: arrayOf(shape({})),
        radioButtonGroupFlex: bool,
        radioLabelClassName: string,
        selectorId: string.isRequired,
        tooltipText: string,
        value: string,
        valueKey: string,
        column: bool,
        gap: string,
        showIcon: bool,
    };

    static defaultProps = {
        cControlClassName: null,
        className: "",
        disabled: false,
        inLineControl: false,
        labelKey: "label",
        options: [],
        radioButtonGroupFlex: false,
        radioLabelClassName: null,
        tooltipText: null,
        value: "",
        valueKey: "id",
        column: false,
        gap: null,
        showIcon: true,
    };

    handleRadioChange = (event) => {
        const { onChange } = this.props;
        onChange(event.target.value);
    };

    render() {
        const {
            cControlClassName,
            className,
            disabled,
            inLineControl,
            labelKey,
            name,
            options,
            radioButtonGroupFlex,
            radioLabelClassName,
            selectorId,
            tooltipText,
            value,
            valueKey,
            column,
            gap,
            showIcon,
        } = this.props;

        return (
            <div
                className={classNames("form-group-control-list", className, {
                    "form-group-control-list-flex": radioButtonGroupFlex,
                    "flex-column": column,
                    [`gap-${gap}`]: gap,
                })}>
                {/* <fieldset> */}
                {options.map((option) => (
                    <RadioButton
                        id={`${selectorId}_${option[valueKey]}`}
                        checked={option[valueKey] === value}
                        inLineControl={inLineControl}
                        key={`${selectorId}_${option[valueKey]}`}
                        label={option[labelKey]}
                        name={name}
                        onChange={this.handleRadioChange}
                        value={option[valueKey]}
                        radioLabelClassName={radioLabelClassName}
                        className={cControlClassName}
                        disabled={disabled || option.disabled}
                        icon={option.icon}
                        showIcon={showIcon}
                    />
                ))}
                {/* </fieldset> */}
                {tooltipText && <Tooltip {...(radioButtonGroupFlex && { className: "mb-2" })} text={tooltipText} />}
            </div>
        );
    }
}

export default RadioButtonGroup;
