import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import withRouter from "react-router-dom/withRouter";
import { bool, func, string, shape, arrayOf } from "prop-types";
import { push } from "react-router-redux";

import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as creditCardOtherBankSelectors } from "reducers/creditCardOtherBank";

import { resizableRoute } from "pages/_components/Resizable";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import TicketData from "pages/_components/TicketData";
import FormTransition from "pages/forms/_components/FormTransition";

import * as i18n from "util/i18n";
import { maskCardNumber } from "util/mask";
import ExitModal from "pages/_components/modal/ExitModal";

const ID_FORM = "creditCard.otherBanks.delete";
const ID_ACTIVITY = `${ID_FORM}.send`;
const TITLE_FORM = `${ID_FORM}.title`;

const DeleteCardOtherBank = (props) => {
    const { dispatch, mode, preDataForm } = props;

    const [metadata, setMetadata] = useState({
        draftsEnabled: false,
        templatesEnabled: false,
        schedulable: true,
        programable: true,
        idActivity: ID_ACTIVITY,
    });

    const [showExitModal, setShowExitModal] = useState(false);

    useEffect(() => {
        dispatch(formActions.preCustomForm(["creditcard.payment.otherbanks.pay"]));
    }, []);

    useEffect(() => {
        if (mode === "edit") {
            dispatch(formActions.deleteCreditCardOtherBank(ID_ACTIVITY));
        }
    }, []);

    useEffect(() => {
        const { nonWorkingDays, enabledWeekDays, firstWorkingDate = new Date(), maxDaysToSchedule } = preDataForm;
        setMetadata((state) => ({
            ...state,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
        }));
    }, [preDataForm]);

    const handlerBackAction = () => {
        dispatch(push("/creditCardOtherBanks"));
    };

    const handlerCancelAction = () => {
        setShowExitModal(true);
    };

    const handleAcceptModal = () => {
        dispatch(push("/creditCardOtherBanks"));
        setShowExitModal(false);
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const initialValuesCardEnroll = () => {
        let values = {};

        values = {
            cardType: "",
            cardTypeLabel: "",
            cardNumber: "",
            bank: "",
            bankLabel: "",
            ruc: "",
            beneficiary: "",
            alias: "",
        };

        return values;
    };

    const renderTicket = () => <></>;

    const renderFields = () => {
        const { creditCardData } = props;

        return (
            <>
                {mode === "edit" && <></>}
                {mode === "preview" ? (
                    <>
                        <TicketData.Main
                            label={`${ID_FORM}.header.card`}
                            value={`${i18n.get(`creditCard.branches.${creditCardData.cardBrand}`)} ${maskCardNumber(
                                creditCardData.cardNumber,
                            )}`}
                        />

                        <Box>
                            <Box className="mt-5 mb-4">
                                <Text size="6" bold labelKey={`${ID_FORM}.data.card`} />
                            </Box>
                            <TicketData.Data
                                label={`${ID_FORM}.field.bank`}
                                value={creditCardData && creditCardData.bankLabel}
                            />
                            <Box className="mt-5 mb-4">
                                <Text size="6" bold labelKey={`${ID_FORM}.data.beneficiary`} />
                            </Box>
                            <TicketData.Data
                                label={`${ID_FORM}.beneficiary.name`}
                                value={creditCardData && creditCardData.beneficiary}
                            />
                            <TicketData.Data
                                label={`${ID_FORM}.field.alias`}
                                value={creditCardData && creditCardData.alias}
                            />
                        </Box>
                    </>
                ) : null}
                <ExitModal
                    modalShow={showExitModal}
                    acceptFunction={handleAcceptModal}
                    cancelFunction={handleHideModal}
                    headingText={i18n.get("confirm.exit.title")}
                    text={i18n.get("confirm.exit.info")}
                />
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: false,
        preData: preDataForm,
        showFilterChips: false,
        ticketConfirmation: true,
        cancelAction: handlerCancelAction,
        handleCancelPreview: handlerCancelAction,
        handleBackCustom: handlerBackAction,
        handleBackDesktopCustom: handlerBackAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        showSchedulerMessage: false,
        // validate: validateForm,
        titleFormConfirmation: "OTP CODE",
        data: initialValuesCardEnroll(),
        submitButtonLabel: `${ID_FORM}.button.confirm`,
        // validationSchema,
        textBack: "global.return",
        textBackConfirmation: "global.return",
        submitDisabled: false,
        showFormAcions: true,
        hideSecondFactor: true,
        onlyBackToDesktopBtn: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

DeleteCardOtherBank.defaultProps = {
    mode: "preview",
    banks: null,
    preDataForm: {},
    previewData: {},
};

DeleteCardOtherBank.propTypes = {
    mode: string,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    banks: arrayOf(shape({})),
    preDataForm: shape({}),
    previewData: shape({}),
    creditCardData: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    fetching: creditCardOtherBankSelectors.getFetching(state),
    creditCardData: creditCardOtherBankSelectors.getCreditCardData(state),
    currentLang: i18nSelectors.getLang(state),
    fetchingForm: formSelectors.getFetching(state),
    preDataForm: formSelectors.getPreData(state),
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    previewData: formSelectors.getPreviewData(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(DeleteCardOtherBank));
