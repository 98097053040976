import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import classNames from "classnames";
import * as Yup from "yup";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FormTransition from "pages/forms/_components/FormTransition";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push, replace } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as payServiceSelectors } from "reducers/payService";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as config from "util/config";
import {
    useMetaData,
    initialValuesEditAlias,
    validateEditAliasFormService,
    useEnrolledServiceData,
} from "./hooks/servicePaymentFormHooks";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import { isMobile } from "react-device-detect";
import Info from "pages/_components/Info";
import { Field } from "formik";
import TextField from "pages/_components/fields/TextField";

const ID_FORM = "editAliasService";
const ID_ACTIVITY = "editAliasService.send";
const ID_ACTIVITY_PRE = "editAliasService.pre";
const TITLE_FORM = `${ID_FORM}.title`;

const EditAliasService = (props) => {
    const {
        mode,
        preDataForm,
        isDesktop,
        dispatch,
        queryBill,
        selectedBiller,
        fetchingForm,
        field,
        currentLang,
        transaction,
        massivePayService,
        checkAliasExists,
        previewData,
        service,
        ...rest
    } = props;

    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [enabledSubmit, setEnabledSubmit] = useState(false);

    const {
        agreementId,
        agreementLabel,
        company,
        category,
        companyLabel,
        identificationLabel,
        identificationType,
        identificationValue,
    } = useEnrolledServiceData(preDataForm?.enrolledService);

    const { alias, id } = props.service;

    const initialValues = initialValuesEditAlias({
        alias,
        id,
        category,
        agreementId,
        agreementLabel,
        company,
        companyLabel,
        identificationLabel,
        identificationType,
        identificationValue,
    });

    const [newAlias, setNewAlias] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        if (service === null) {
            dispatch(replace("/servicePayments"));
        }
    }, []);

    useEffect(() => {
        console.log(mode);
        if (service && service.id != null) {
            if (mode === "edit") {
                setSelectedId(service.id);
                let requestData = { clientEnrolledServiceId: service.id };
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
            }
        }
    }, [mode, service]);

    const validationSchema = () =>
        Yup.object().shape({
            newAlias: Yup.string()
                .required(i18n.get("serviceRegistration.alias.empty.error"))
                .trim()
                .min(1, i18n.get("serviceRegistration.alias.empty.error"))
                .matches(/^[a-zA-Z0-9 ]*$/, i18n.get("serviceRegistration.alias.invalid.error")),
        });

    const handlerCancelAction = () => {
        dispatch(push("/servicePayments"));
    };

    const renderTicket = () => {
        return <></>;
    };

    const handleChangeAlias = (value, setFieldValue) => {
        setNewAlias(value);
        setFieldValue("newAlias", value);
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler, errors) => {
        if (mode !== "view" && (!preDataForm || fetchingForm)) {
            return <PageLoading loading classicStyle={false} />;
        }

        if (mode === "edit" && validateEditAliasFormService(values)) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }

        const getErrorMessage = (errors, fieldName) => {
            switch (fieldName) {
                case "newAlias":
                    return errors.newAlias ? errors.newAlias : null;
                default:
                    return null;
            }
        };

        return (
            <>
                {mode === "edit" && (
                    <>
                        <Box
                            fullWidth
                            background="white"
                            borderRadius="lg"
                            className={
                                isMobile
                                    ? "form-service-payments pt-7 mb-5"
                                    : "box-shadow-small form-service-payments p-7 mb-11"
                            }>
                            <Box background="background-disabled" className="p-5 mb-7" borderRadius="lg">
                                <Box className="mb-5">
                                    <Text bold labelKey={`${ID_FORM}.info`} />
                                </Box>
                                <Col xs={12} md={9}>
                                    <Row
                                        className={classNames({
                                            "row-template-1fr gap-0": !isDesktop,
                                        })}>
                                        <Col xs={12} md={2}>
                                            <Box
                                                className={classNames({
                                                    "py-4": isDesktop,
                                                })}>
                                                <Info
                                                    labelKey="editAliasService.head.serviceType"
                                                    text={category}
                                                    classNameValue="size-6"
                                                    flex={!isDesktop}
                                                />
                                            </Box>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Box
                                                className={classNames({
                                                    "py-4": isDesktop,
                                                })}>
                                                <Info
                                                    labelKey="editAliasService.head.serviceCompany"
                                                    text={companyLabel}
                                                    classNameValue="size-6"
                                                    flex={!isDesktop}
                                                />
                                            </Box>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Box
                                                className={classNames({
                                                    "py-4": isDesktop,
                                                })}>
                                                <Info
                                                    labelKey="editAliasService.head.agreement"
                                                    text={agreementLabel}
                                                    classNameValue="size-6"
                                                    flex={!isDesktop}
                                                />
                                            </Box>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Box
                                                className={classNames({
                                                    "py-4": isDesktop,
                                                })}>
                                                <Info
                                                    labelKey="editAliasService.head.identifierType"
                                                    text={identificationType}
                                                    classNameValue="size-6"
                                                    flex={!isDesktop}
                                                />
                                            </Box>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={classNames({
                                            "row-template-1fr gap-0": !isDesktop,
                                        })}>
                                        <Col xs={12} md={3}>
                                            <Box
                                                className={classNames({
                                                    "py-4": isDesktop,
                                                })}>
                                                <Info
                                                    labelText={identificationLabel}
                                                    text={identificationValue}
                                                    classNameValue="size-6"
                                                    flex={!isDesktop}
                                                />
                                            </Box>
                                        </Col>
                                    </Row>
                                </Col>
                            </Box>

                            <Box>
                                <Row alignY="flex-end" gapX={7} gapY={3}>
                                    <Col xs={12} md={6}>
                                        <Box className="form-group">
                                            <Box display="flex" alignY="center" className="data-label-special-mb">
                                                <Text
                                                    component="label"
                                                    htmlFor="react-select-accountBy-input"
                                                    className="data-label"
                                                    size="4"
                                                    bold
                                                    labelKey="serviceRegistration.alias.input.label"
                                                />
                                            </Box>
                                            <Box className="input-group">
                                                {/* ALIAS */}
                                                <Field
                                                    component={TextField}
                                                    idForm={ID_FORM}
                                                    name="newAlias"
                                                    type="text"
                                                    autoComplete="off"
                                                    showLabel={false}
                                                    nestedErrorsObject
                                                    showError={errors && errors?.newAlias}
                                                    placeholder={i18n.get(
                                                        "serviceRegistration.alias.placeholder.label",
                                                    )}
                                                    pattern={config.get("regex.pattern.valid.alphanumeric")}
                                                    copyEnabled={false}
                                                    maxLength="20"
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </>
                )}
                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box background="background-disabled" className="p-5 mb-5" borderRadius="lg">
                            <Row>
                                <Col xs={6} className="d-flex align-items-center">
                                    <Text labelKey={`editAliasService.view.typeOfService`} />
                                </Col>
                                <Col xs={6}>
                                    <Box display="flex" fullWidth className="size-0 text-semibold justify-content-end">
                                        {values && values.category}
                                    </Box>
                                </Col>
                            </Row>
                        </Box>
                        <Box>
                            <Box className="mb-6">
                                <Text size="8" bold labelKey={`${ID_FORM}.serviceData.label`} />
                            </Box>

                            <Box fullWidth className="pt-3 pb-5 mt-n-min">
                                <Row className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.serviceCompany.label`} />
                                    </Col>
                                    <Col xs={6}>
                                        <Box
                                            display="flex"
                                            fullWidth
                                            className="size-0 text-semibold justify-content-end">
                                            {values && values.companyLabel}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.agreement.label`} />
                                    </Col>
                                    <Col xs={6} className="d-flex justify-content-end">
                                        <Box className="size-0 text-semibold" style={{ whiteSpace: "nowrap" }}>
                                            {values && values.agreementLabel}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.identifierType.label`} />
                                    </Col>
                                    <Col xs={6}>
                                        <Box
                                            display="flex"
                                            fullWidth
                                            className="size-0 text-semibold justify-content-end">
                                            {values && values.identificationLabel}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.supplyNumber.label`} />
                                    </Col>
                                    <Col xs={6}>
                                        <Box
                                            display="flex"
                                            fullWidth
                                            className="size-0 text-semibold justify-content-end">
                                            {values && values.identificationValue}
                                        </Box>
                                    </Col>
                                </Row>
                                <Row className="p-5 mb-5">
                                    <Col xs={6} className="d-flex align-items-center">
                                        <Text labelKey={`${ID_FORM}.alias.label`} />
                                    </Col>
                                    <Col xs={6}>
                                        <Box
                                            display="flex"
                                            fullWidth
                                            className="size-0 text-semibold justify-content-end">
                                            {values && values.newAlias}
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        ticketConfirmation: true,
        cancelAction: handlerCancelAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        showSchedulerMessage: false,
        // validate: validateForm,
        titleFormConfirmation: "OTP CODE",
        data: initialValues,
        submitButtonLabel: "forms.servicePayment.confirmation.button",
        validationSchema,
        textBack: "serviceRegistration.returnToPaymentsServices",
        textBackConfirmation: "global.return",
        submitDisabled: !enabledSubmit,
        showFormAcions: true,
        handleCancelPreview: handlerCancelAction,
        shouldEnableSubmit: true,
        hideSecondFactor: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
    field: payServiceSelectors.getField(state),
    massivePayService: payServiceSelectors.getMassivePayService(state),
    selectedBiller: payServiceSelectors.getSelectedBiller(state),
    queryBill: payServiceSelectors.getQueryBill(state),
    fetchingForm: formSelectors.getFetching(state),
    preDataForm: formSelectors.getPreData(state),
    currentLang: i18nSelectors.getLang(state),
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    previewData: formSelectors.getPreviewData(state),
    service: payServiceSelectors.getServiceAction(state),
});

EditAliasService.propTypes = {
    history: shape({}).isRequired,
    isDesktop: bool,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    previewData: shape({}),
    fromBackoffice: bool,
    subtitle: string,
    subtitlePrefix: string,
    fetching: bool,
    field: arrayOf(shape({})),
    selectedBiller: shape({}),
    queryBill: shape({}),
    fetchingForm: bool,
    dispatch: func.isRequired,
    transaction: shape({}),
    aliasExists: bool,
    checkAliasExists: func.isRequired,
    service: shape({}).isRequired,
};

EditAliasService.defaultProps = {
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    subtitle: true,
    subtitlePrefix: true,
    fetching: false,
    isDesktop: false,
    field: [],
    selectedBiller: null,
    queryBill: null,
    fetchingForm: false,
    transaction: null,
    fetchingList: false,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(EditAliasService));
