import React from "react";
import { bool } from "prop-types";
import * as i18n from "util/i18n";
import TextField from "pages/_components/fields/TextField";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import classNames from "classnames";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import Select from "pages/forms/_components/_fields/Select";
import AmountField from "pages/_components/fields/formik/AmountField";
import { Field } from "formik";
import FieldError from "pages/_components/fields/FieldError";
import { fieldInputError } from "./utilForm";

const PayServiceForm = ({
    index,
    values,
    errors,
    ID_FORM,
    genericProps,
    accountOptions,
    creditCardOptions,
    showFieldAmount,
    setFieldValue,
    isDesktop,
}) => {
    const service = values.list[index];
    const amount = service.amountPay;

    const value = {
        amount,
    };

    const { allowPayWithCreditCard, optionsCreditCard: creditCardDeferredOptions } = service?.debt;
    const radiButtonOptions = [];

    if (accountOptions?.options && accountOptions?.options?.length > 0) {
        radiButtonOptions.push({
            id: "account",
            label: i18n.get("servicePayments.pay.debitPaymentMethod.account"),
        });
    }
    if (
        allowPayWithCreditCard &&
        creditCardDeferredOptions &&
        creditCardDeferredOptions.length > 0 &&
        creditCardOptions?.options &&
        creditCardOptions?.options?.length > 0
    ) {
        radiButtonOptions.push({
            id: "creditCard",
            label: i18n.get("servicePayments.pay.debitPaymentMethod.creditCard"),
        });
    }
    // const fieldInputError = (error) =>
    //     error ? (
    //         <Box className="mt-2">
    //             <FieldError error={error} />
    //         </Box>
    //     ) : null;

    const onChangeDebitPaymentMethod = (value) => {
        // setDebitPaymentMethod(value);
        setFieldValue(`list[${index}].debitPaymentMethod`, value);
        setFieldValue(`list[${index}].debitProduct`, "");
        setFieldValue(`list[${index}].creditCardPaymentType`, "");

        if (value === "creditCard" && creditCardOptions?.options?.length === 1) {
            const { idProduct } = creditCardOptions.options[0];
            const elem = creditCardOptions?.options?.find((item) => item?.idProduct && item.idProduct === idProduct);
            const labelProduct = `${elem.franchise} ${elem.shortLabel}`;
            setFieldValue(`list[${index}].debitProduct`, idProduct);
            setFieldValue(`list[${index}].debitProductLabel`, labelProduct);
        }
        if (value === "account" && accountOptions?.options?.length === 1) {
            const { idProduct } = accountOptions.options[0];
            const elem = accountOptions?.options?.find((item) => item?.idProduct && item.idProduct === idProduct);
            const labelProduct = `${elem.numberMask} - ${elem.ownerName} - ${elem.productTypeBackend} - ${elem.currency}`;
            setFieldValue(`list[${index}].debitProduct`, idProduct);
            setFieldValue(`list[${index}].debitProductLabel`, labelProduct);
        }
    };

    const handleChangeCardPaymentType = ({ code, label }) => {
        setFieldValue(`list[${index}].creditCardPaymentType`, code);
        setFieldValue(`list[${index}].creditCardPaymentTypeLabel`, label);
    };

    const onChangeProduct = (value) => {
        // console.log("onChangeProductx value", value);

        let labelProduct = "";

        if (service.debitPaymentMethod === "account") {
            const elem = accountOptions?.options?.find((item) => item?.idProduct && item.idProduct === value);
            labelProduct = `${elem.numberMask} - ${elem.ownerName} - ${elem.productTypeBackend} - ${elem.currency}`;
        }

        if (service.debitPaymentMethod === "creditCard") {
            const elem = creditCardOptions?.options?.find((item) => item?.idProduct && item.idProduct === value);
            labelProduct = `${elem.franchise} ${elem.shortLabel}`;
            setFieldValue(`list[${index}].debitProductData`, null);
        }
        // console.log("---------------------############ labelProduct", labelProduct);
        setFieldValue(`list[${index}].debitProduct`, value);
        setFieldValue(`list[${index}].debitProductLabel`, labelProduct);
    };

    const handleAmountChange = (value) => {
        // console.log("handleAmountChange value", value);
        setFieldValue(`list[${index}].amountPay`, value);
        setFieldValue(`list[${index}].amount.amount`, value);
    };

    return (
        <Row
            gapY="0"
            className={classNames({
                "row-template-1fr": !isDesktop,
            })}>
            {radiButtonOptions && radiButtonOptions.length > 1 && (
                <Col xs={6}>
                    <Box className="data-label-special-mb mt-6">
                        <Text semibold labelKey="servicePayments.pay.debitPaymentMethod" />
                    </Box>
                    <Box fullWidth className="p-0 mt-4">
                        <RadioButtonGroup
                            className="d-flex radio-like-buttons"
                            radioLabelClassName="color-text-boton"
                            name={`list[${index}].debitPaymentMethod`}
                            selectorId={`list[${index}].debitPaymentMethod`}
                            onChange={(value) => onChangeDebitPaymentMethod(value)}
                            options={radiButtonOptions}
                            value={service.debitPaymentMethod}
                            cControlClassName="mr-3"
                            inLineControl={false}
                            radioButtonGroupFlex
                            renderAs="radio"
                            showIcon={false}
                        />
                    </Box>
                </Col>
            )}
            <Col xs={6}>
                <Box className="data-label-special-mb mt-6">
                    <Text semibold labelKey={`servicePayments.pay.debitPaymentMethod.${service.debitPaymentMethod}`} />
                </Box>
                <Box
                    fullWidth
                    className={classNames({
                        "has-error": errors.list?.[index]?.debitProduct,
                    })}>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.ProductselectorCustom}
                        data={service.debitPaymentMethod === "account" ? accountOptions : creditCardOptions}
                        key={`list[${index}].debitProduct`}
                        name={`list[${index}].debitProduct`}
                        idField={`list[${index}].debitProduct`}
                        renderAs="combo"
                        value={service.debitProduct}
                        noLabelEditMode
                        customPlaceholder={i18n.get(`servicePayment.dinamicForm.placeholder.account`)}
                        optionClassName="needsclick"
                        onChangeCustom={(value) => onChangeProduct(value)}
                    />
                    {fieldInputError(errors.list?.[index]?.debitProduct)}
                </Box>
            </Col>
            {service.debitPaymentMethod === "creditCard" &&
                creditCardDeferredOptions &&
                creditCardDeferredOptions.length > 0 && (
                    <Col xs={6}>
                        <Box className="data-label-special-mb mt-6">
                            <Text semibold labelKey="servicePayments.pay.creditCard.paymentType" />
                        </Box>
                        <Select
                            id={`list[${index}].creditCardPaymentType`}
                            name={`list[${index}].creditCardPaymentType`}
                            label="servicePayments.pay.creditCard.paymentType"
                            onChange={(value) => handleChangeCardPaymentType(value)}
                            options={creditCardDeferredOptions}
                            value={service.creditCardPaymentType}
                            valueKey="code"
                            placeholder={i18n.get("global.select")}
                            containerClassName="input-group selector-custom"
                            className={classNames({
                                "has-error": errors.list?.[index]?.creditCardPaymentType,
                            })}
                        />
                        {fieldInputError(errors.list?.[index]?.creditCardPaymentType)}
                    </Col>
                )}
            {showFieldAmount && (
                <Col xs={6}>
                    <Box
                        className={classNames("mt-6", {
                            "has-error": value?.amount <= 0,
                        })}>
                        <Field
                            name={`list[${index}].amount`}
                            component={AmountField}
                            maxLength={15}
                            data={{
                                options: [{ id: 1, label: "USD" }],
                            }}
                            value={value}
                            clearable={false}
                            labelText={i18n.get(`servicePayments.pay.amount`)}
                            bold={false}
                            onInputChange={handleAmountChange}
                            disabled={
                                service?.debt?.paymentMethod === "TOTAL" || service.debitPaymentMethod === "creditCard"
                            }
                            hideCurrency
                            labelNoMarginTop
                            fixedDecimalScale
                        />
                        {value?.amount <= 0 && (
                            <div className="pos-relative">
                                <FieldError
                                    error={i18n.get("servicePayments.registeredServices.list.item.amount.validation")}
                                />
                            </div>
                        )}
                    </Box>
                </Col>
            )}
            <Col xs={6}>
                <Field
                    name={`list[${index}].description`}
                    idForm={ID_FORM}
                    component={TextField}
                    labelText={i18n.get(`${ID_FORM}.description.label`)}
                    placeholderText={i18n.get(`${ID_FORM}.description.placeholder`)}
                    hidePlaceholder
                    labelClassName="text-bold mt-6"
                    className={classNames({
                        "has-error": errors.list?.[index]?.description,
                    })}
                    maxLength={20}
                    pattern="^[a-zA-Z0-9]*$"
                />
            </Col>
        </Row>
    );
};

PayServiceForm.propTypes = {
    isDesktop: bool.isRequired,
};

PayServiceForm.defaultProps = {};

export default PayServiceForm;
