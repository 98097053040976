import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Help from "pages/_components/Help";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import SimpleDocumentField from "pages/_components/fields/SimpleDocumentField";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/usersInvite";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as Yup from "yup";
import { steps } from "./utils/users";
import * as config from "util/config";

const FORM_ID = "administration.users.invite";

const UserInvite = (props) => {
    const { dispatch, fetching, documentTypes, setFieldValue, values, dirty, isValid } = props;

    useEffect(() => {
        dispatch(actions.userInvitePre());
    }, []);

    const handleCancel = () => {
        dispatch(push("/administration/users"));
    };
    const handleBack = () => {
        // history.goBack();
        dispatch(push("/administration/users"));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const { type } = values.document;

        if (type === "CED" && !/^\d*$/.test(value)) {
            const validValue = value.slice(0, -1);
            setFieldValue(name, {
                type: values.document.type,
                document: validValue,
            });
            return false;
        } else {
            const passportRegex = new RegExp(config.get("regex.pattern.valid.passport"));
            return passportRegex.test(value);
        }
    };

    return (
        <>
            <Notification scopeToShow="administrationUserInvite" />
            <Head
                title="administration.users.invite.title"
                onBack={handleBack}
                textBack="administration.users.returnToList"
            />
            <Form>
                <Box className="mb-3">
                    <Box background="white" borderRadius="lg" className="main-container p-7 pb-7 mt-3 box-shadow-small">
                        <Stepper className="stepper-default mb-5" stepsList={steps} currentStep={1} completed={0} />
                    </Box>
                </Box>
                <MainContainer
                    className="main-container border-radius-lg p-7 box-shadow-small background-white"
                    showLoader={fetching}>
                    <Box>
                        <Help
                            titleKey="administration.users.invite.help.title"
                            textKey="administration.users.invite.help.text"
                        />
                    </Box>
                    <Box
                        display="flex"
                        column
                        background="component-background"
                        borderRadius="default"
                        className="pt-6 pb-7">
                        <Row>
                            <Col xs="6" xsOffset="3">
                                <Box className="mb-7">
                                    <Field
                                        autoComplete="off"
                                        clearable={false}
                                        idForm={FORM_ID}
                                        component={SimpleDocumentField}
                                        name="document"
                                        data={{ documentTypes }}
                                        defaultDocumentType="CED"
                                        defaultCountry="EC"
                                        hideDocumentPlaceholder
                                        handleDocumentChange={handleInputChange}
                                        maxLength={values.document.type === "CED" ? 10 : 200}
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                </MainContainer>

                <Box className="main-container p-7">
                    <Row>
                        <Col xs={3} xsOffset={3}>
                            <Button
                                bsStyle="outline"
                                label="global.cancel"
                                loading={fetching}
                                onClick={handleCancel}
                                btnUppercase={false}
                                block
                            />
                        </Col>
                        <Col xs={3}>
                            <Button
                                bsStyle="primary"
                                label="global.continue"
                                loading={fetching}
                                type="submit"
                                btnUppercase={false}
                                block
                                disabled={!(isValid && dirty)}
                            />
                        </Col>
                    </Row>
                </Box>
            </Form>
        </>
    );
};

UserInvite.propTypes = {
    dispatch: func.isRequired,
    documentTypes: arrayOf(shape({ id_country_code: string, id_document_type: string })).isRequired,
    fetching: bool.isRequired,
    // history: shape({}).isRequired,
    dirty: bool.isRequired,
    isValid: bool.isRequired,
    setFieldValue: func.isRequired,
    values: shape({ document: shape({}) }).isRequired,
};

const mapStateToProps = (state) => ({
    documentTypes: selectors.getDocumentTypeList(state),
    defaultCountry: selectors.getDefaultCountry(state),
    fetching: selectors.fetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        mapPropsToValues: () => ({
            document: {
                type: "CED",
                document: "",
            },
        }),
        validationSchema: () =>
            Yup.object().shape({
                document: Yup.object().shape({
                    type: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentType.empty`)),
                    document: Yup.string()
                        .required(i18nUtils.get(`${FORM_ID}.documentNumber.empty`))
                        .when("type", {
                            is: "CED",
                            then: Yup.string().length(10, i18nUtils.get(`${FORM_ID}.documentNumber.length`)),
                        }),
                }),
            }),
        handleSubmit: ({ document }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(actions.userInviteVerify(document, formikBag));
        },
    }),
)(UserInvite);
