import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import * as i18n from "util/i18n";
import * as config from "util/config";
import { func, instanceOf, shape } from "prop-types";
import Selector from "pages/_components/fields/formik/Selector";
import { calculateDaysDifference } from "util/date";
import DateField from "pages/_components/fields/DateField";
import moment from "moment";
import FieldError from "pages/_components/fields/FieldError";
import Tooltip from "pages/_components/Tooltip";

const FORM_ID = "creditCards.corporate.movements.filters";

const MovementsCorporateFilter = ({ showMovFiltersConsumo, initialData, minDate, handleFilter, handleCancel }) => {
    const [errorDate, setErrorDates] = useState(false);
    const [showTooltip, setShowTooltip] = useState("");

    const minDateFrom = minDate;
    const minDateTo = minDate;
    const maxRangeDays = config.getInteger("credicard.corporate.range.maxdays");

    const generateOptionsTransactionType = () => {
        const options = config
            .getArray("corporate.movements.filter.transactionType", ["0", "1", "2", "4", "5"])
            .map((transaction) => ({
                value: transaction,
                label: i18n.get(`corporate.movements.filter.transactionType.${transaction}`),
            }));
        return options;
    };

    // const handleChangeSelect = (option, { values }) => {
    //     if (option !== "period") {
    //         setErrorDates(false);
    //     } else {
    //         const { dateFrom, dateTo } = values;
    //         const days = calculateDaysDifference(dateFrom, dateTo);
    //         setErrorDates(days > 30);
    //     }
    // };

    const handleChangeDateFrom = (dateFrom, { values }) => {
        const { dateTo } = values;
        if (dateTo) {
            const dateToMoment = moment(dateTo);
            const errorDate = dateFrom.isAfter(dateToMoment);
            setErrorDates(errorDate);
            if (!errorDate) {
                setErrorDates(dateToMoment.diff(dateFrom, "days") > maxRangeDays);
            }
        }
    };

    const handleChangeDateTo = (dateTo, { values }) => {
        const { dateFrom } = values;
        if (dateFrom) {
            const dateFromMoment = moment(dateFrom);
            const errorDate = dateFromMoment.isAfter(dateTo);
            setErrorDates(errorDate);
            if (!errorDate) {
                setErrorDates(dateTo.diff(dateFrom, "days") > maxRangeDays);
            }
        }
    };

    return (
        <Formik
            initialValues={{
                transactionType: showMovFiltersConsumo ? "1" : initialData.transactionType,
                dateFrom: showMovFiltersConsumo ? initialData.dateFromConsume : initialData.dateFrom,
                dateTo: showMovFiltersConsumo ? initialData.dateToConsume : initialData.dateTo,
            }}
            onSubmit={(values) => {
                handleFilter(values);
            }}>
            {(formik) => (
                <Form>
                    <Box column fullWidth className={classNames("mb-5")}>
                        {!showMovFiltersConsumo && (
                            <Row>
                                <Field
                                    component={Selector}
                                    options={generateOptionsTransactionType()}
                                    idForm={FORM_ID}
                                    name="transactionType"
                                    placeholder="Seleccionar"
                                />
                            </Row>
                        )}

                        {/* <Row>
                            <Field
                                component={Selector}
                                options={optionsType}
                                handleChange={(selected) => handleChangeSelect(selected, formik)}
                                idForm={FORM_ID}
                                name="selectedType"
                                placeholder="Todos"
                            />
                        </Row> */}

                        <Row
                            gapY="0"
                            className={classNames("px-0", {
                                "d-flex flex-column": !isDesktop,
                            })}>
                            <Col xs={6}>
                                <Box display="flex" className="align-items-center">
                                    <Field
                                        component={DateField}
                                        endDate={formik.values.dateTo}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="dateFrom"
                                        selectsStart
                                        startDate={formik.values.dateFrom}
                                        handleChange={(dateSelect) => handleChangeDateFrom(dateSelect, formik)}
                                        popperPlacement="bottom"
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["bottom"], // don't allow it to flip to be above
                                            },
                                        }}
                                        {...(minDateFrom && { minDate: minDateFrom })}
                                        maxDate={moment()}
                                    />
                                    <Tooltip
                                        buttonId="tooltipDate"
                                        image="images/information.svg"
                                        text={
                                            showMovFiltersConsumo
                                                ? "documents.corporate.info.consume.tooltip"
                                                : "documents.corporate.info.movement.tooltip"
                                        }
                                        position="top-right"
                                        className={
                                            isDesktop ? "corporate-filter-tooltip-web" : "corporate-filter-tooltip-mb"
                                        }
                                        showTooltip={showTooltip === "tooltipDate"}
                                        handleClick={() => {
                                            setShowTooltip("tooltipDate");
                                        }}
                                    />
                                </Box>
                            </Col>
                            <Col xs={6}>
                                <Field
                                    component={DateField}
                                    endDate={formik.values.dateTo}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="dateTo"
                                    selectsEnd
                                    startDate={formik.values.dateFrom}
                                    popperPlacement="bottom"
                                    handleChange={(dateSelect) => handleChangeDateTo(dateSelect, formik)}
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"], // don't allow it to flip to be above
                                        },
                                    }}
                                    // eslint-disable-next-line no-nested-ternary
                                    {...(formik.values.dateFrom
                                        ? { minDate: formik.values.dateFrom }
                                        : minDateTo
                                        ? { minDate: minDateTo }
                                        : moment().add(-6, "months"))}
                                />
                            </Col>
                        </Row>
                        {errorDate && (
                            <Box className="my-3 filter-content-error">
                                <FieldError error={i18n.get("accounts.movements.filter.dates.message")} />
                            </Box>
                        )}
                    </Box>

                    <Box display="flex" column fullWidth className={classNames("mb-5")}>
                        <Row className={classNames("px-0", { "d-flex flex-column-reverse": !isDesktop })}>
                            <Col xs={6}>
                                <Button
                                    type="button"
                                    label="global.cancel"
                                    bsStyle="outline"
                                    onClick={handleCancel}
                                    block
                                />
                            </Col>
                            <Col xs={6}>
                                <Button
                                    type="submit"
                                    label="product.filters.filter"
                                    bsStyle="primary"
                                    disabled={!formik.values.transactionType || errorDate}
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

MovementsCorporateFilter.propTypes = {
    minDate: instanceOf(Date),
    initialData: shape({}).isRequired,
    handleFilter: func.isRequired,
    handleCancel: func.isRequired,
};

export default MovementsCorporateFilter;
