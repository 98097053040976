import React from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Box from "pages/_components/Box";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import Dropdown from "pages/_components/Dropdown";
import { Row, Col } from "react-bootstrap";
import Text from "pages/_components/Text";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { PREFIX } from "../hooks/servicePaymentFormHooks";

const ServiceListMobile = ({ filteredServices, rowsPerPage, hasFilterApplied, options, payService, noRecords }) => {
    const renderItem = ({ id, companyLabel, alias }) => (
        <Box
            key={id}
            className="row-service pl-5 pr-3 py-4"
            borderRadius="md"
            pointer
            onClick={() => {
                payService(id);
            }}>
            <Row>
                <Col xs="5">
                    <Box className="my-2 size-6 color-text-grey">
                        <I18n id={`${PREFIX}.service`} />
                    </Box>
                    <Box className="text-bold">{companyLabel}</Box>
                </Col>

                <Col xs="5" className="text-right">
                    <Box className="my-2 size-6 color-text-grey">
                        <I18n id={`${PREFIX}.alias`} />
                    </Box>
                    <Box className="text-break-all">{alias}</Box>
                </Col>
                <Col xs="2" className="d-flex align-items-center">
                    <Dropdown
                        image="images/icons/dropdown-dots.svg"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        {options.map((item) => (
                            <Button
                                key={item.label}
                                label={item.label}
                                onClick={(e) => {
                                    item.onClick(id);
                                    e.stopPropagation();
                                }}
                                className="dropdown__item-btn"
                                bsStyle="link"
                            />
                        ))}
                    </Dropdown>
                </Col>
            </Row>
        </Box>
    );

    const renderList = ({ services }) => (
        <div className="children-alternate-bg-color">
            {services.map((serv) => (
                <>{renderItem(serv)}</>
            ))}
        </div>
    );
    return (
        <div className="pagination-component">
            {filteredServices && filteredServices.length ? (
                <PaginatedDataTable
                    nameList="services"
                    data={filteredServices}
                    hasFilterApplied={hasFilterApplied}
                    rowsPerPage={rowsPerPage}
                    component={renderList}
                    showNumbersResults={true}
                />
                ) : (
                    <>
                    <Box fullWidth display="flex" alignY="center" className="my-5 mb-7">
                        <Text
                            labelKey="global.pagination.results"
                            color="text"
                            size="5"
                            rows={0}
                            totalRows={0}
                        />
                    </Box>
                    <Box className="mt-10">
                        {noRecords()}
                    </Box>
                    </>
                )}
        </div>
    );
};

ServiceListMobile.propTypes = {
    filteredServices: arrayOf(shape({})),
    rowsPerPage: number.isRequired,
    hasFilterApplied: bool.isRequired,
    options: arrayOf(
        shape({
            label: string,
            onClick: func,
        }),
    ).isRequired,
    noRecords: func,
};

ServiceListMobile.defaultProps = {
    filteredServices: [],
};

export default ServiceListMobile;
