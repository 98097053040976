import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FormTransition from "pages/forms/_components/FormTransition";
import { useGenericProps, useMetaData } from "pages/forms/customForms/hooks/TransferInternalHooks";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as i18n from "util/i18n";
// import * as schedulerUtils from "util/scheduler";
import Button from "pages/_components/Button";
import SideBarModal from "pages/_components/modal/SideBarModal";
import { USD_CURRENCY } from "constants.js";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import FooterMassivePay from "./_components/FooterMassivePay";
import { renderData, renderDataDate, renderDataAmount, renderDataModal } from "./_components/utilForm";
import {
    initialValuesMassive,
    PREFIX,
    useAccountPaySelectorOptions,
    useCreditCardPaySelectorOptions,
    validateMassiveForm,
    validationSchema,
} from "./hooks/servicePaymentFormHooks";
import PayServiceForm from "./_components/PayServiceForm";
import ExitModal from "pages/_components/modal/ExitModal";

const ID_FORM = "servicePayments.massive.pay";
const ID_ACTIVITY = "paymentService.massive.send";
const ID_ACTIVITY_PRE = "paymentService.massive.pre";
const TITLE_FORM = `${ID_FORM}.title`;

const MassiveServicePaymentForm = (props) => {
    const {
        mode,
        preDataForm,
        isDesktop,
        dispatch,
        queryBill,
        selectedBiller,
        fetchingForm,
        field,
        currentLang,
        transaction,
        massivePayService,
        fetchingValidations,
        validationData,
        ...rest
    } = props;

    const [showModalDetail, setShowModalDetail] = useState(false);
    const [payDetail, setPayDetail] = useState({});
    const [serviceDelete, setServiceDelete] = useState(undefined);
    const [firstPre, setFirstPre] = useState(false);
    const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [genericProps] = useGenericProps(props, ID_ACTIVITY);
    const [showExitModal, setShowExitModal] = useState(false);

    const creditCardOptions = useCreditCardPaySelectorOptions(preDataForm?.creditCards);
    const accountOptions = useAccountPaySelectorOptions(preDataForm?.accounts);

    useEffect(() => {
        const activities = ["paymentService.pay.send"];
        dispatch(formActions.preValidationsForm(activities));
    }, []);

    useEffect(() => {
        if (preDataForm?.code === "API040E") {
            dispatch(push("/servicePayments"));

            dispatch(
                notificationActions.showNotification(i18n.get(`returnCode.${preDataForm?.code}`), "warning", [
                    "servicePayments",
                ]),
            );
        }
    }, [preDataForm]);

    useEffect(() => () => dispatch(payServiceActions.cleanCachePayService()), []);

    useEffect(() => {
        if (massivePayService && massivePayService.list && massivePayService.list?.length > 0) {
            if (mode === "edit" && !firstPre) {
                const requestData = { listServicePay: massivePayService.list };

                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
                setFirstPre(true);
            }
        } else if (!transaction?.data) {
            dispatch(push("/servicePayments"));
        }
    }, [mode]);

    const isValidForm = (values) => validateMassiveForm(values);

    const handlerCancelAction = () => {
        setShowExitModal(true);
    };

    const handleAcceptModal = () => {
        dispatch(push("/desktop"));
    };

    const handleHideModal = () => {
        setShowExitModal(false);
    };

    const handlerPreviewCancelAction = () => {
        setShowExitModal(true);
    };
    const renderTicket = () => <></>;

    const renderPayDetail = () => {
        const {
            category,
            alias,
            companyLabel,
            identificationLabel,
            identificationValue,
            amountPay,
            commission,
            debitProductLabel,
            debitPaymentMethod,
            creditCardPaymentTypeLabel,
            debt,
        } = payDetail;
        const { clientName, dueDate } = debt;
        return (
            <>
                <Box className="mt-7">
                    <Text semibold size="1" labelKey={`${ID_FORM}.detail.title`} />
                </Box>

                <Box className="mt-8 mb-3">
                    <Text bold size="6" labelKey={`${ID_FORM}.detail.service.title`} />
                </Box>
                <Box>
                    {renderDataModal({
                        labelKey: "servicePayments.pay.category",
                        data: i18n.get(`servicePayment.category.${category}`),
                    })}
                    {renderDataModal({
                        labelKey: `${PREFIX}.service`,
                        data: companyLabel,
                    })}
                    {renderDataModal({
                        labelKey: "servicePayments.pay.identificationType",
                        data: identificationLabel,
                    })}
                    {renderDataModal({
                        label: identificationLabel,
                        data: identificationValue,
                    })}
                    {renderDataModal({
                        labelKey: `${PREFIX}.alias`,
                        data: alias,
                    })}
                    {renderDataModal({
                        xs: 4,
                        labelKey: "servicePayments.pay.titular",
                        data: clientName || "-",
                    })}
                    {renderDataModal({
                        labelKey: `${PREFIX}.dueDate`,
                        date: dueDate,
                        data: !dueDate && "-",
                    })}
                </Box>

                <Box className="mt-6 mb-4">
                    <Text bold size="6" labelKey={`${ID_FORM}.detail.pay.title`} />
                </Box>
                <Box>
                    {renderDataModal({
                        labelKey: "servicePayments.pay.amountPay",
                        amount: amountPay,
                    })}
                    {renderDataModal({
                        labelKey: "servicePayments.pay.debitPaymentMethod",
                        data: i18n.get(`servicePayments.pay.debitPaymentMethod.${debitPaymentMethod}`),
                    })}
                    {debitPaymentMethod === "account" ? (
                        <>
                            {renderDataModal({
                                xs: 4,
                                labelKey: "servicePayments.pay.debitAccount",
                                data: debitProductLabel,
                            })}
                        </>
                    ) : (
                        <>
                            {renderDataModal({
                                xs: 4,
                                labelKey: "servicePayments.pay.debitCreditCard",
                                data: debitProductLabel,
                            })}
                            {renderDataModal({
                                xs: 4,
                                labelKey: "servicePayments.pay.creditCard.paymentType",
                                data: creditCardPaymentTypeLabel,
                            })}
                        </>
                    )}
                    {renderDataModal({
                        labelKey: "servicePayments.pay.rate",
                        amount: commission,
                    })}
                </Box>

                <Box className="text-center mt-8">
                    <Button
                        bsStyle="outline"
                        label="global.return"
                        className="btn-width-lg"
                        onClick={() => {
                            setShowModalDetail(false);
                        }}
                    />
                </Box>
            </>
        );
    };

    const totalAmount = (services) => {
        let total = 0;
        services.forEach((serv) => {
            total += serv?.debitPaymentMethod === "creditCard" ? serv?.debt?.total : serv.amountPay;
        });
        return total;
    };

    const renderFields = (setFieldValue, values, _setValues, _renderScheduler, errors) => {
        const { totalPay } = massivePayService;

        if ((mode !== "view" && (!preDataForm || fetchingForm)) || fetchingValidations) {
            return <PageLoading loading classicStyle={false} />;
        }

        const handleAcceptDeleteModal = () => {
            setShowModalConfirmDelete(false);
            const index = massivePayService.list.findIndex((e) => e.id === serviceDelete);
            if (index < 0) {
                return;
            }
            const list = massivePayService.list.filter((_, i) => i !== index);
            let totalPaySum = 0;
            list.forEach(({ amountPay }) => {
                totalPaySum += amountPay;
            });

            setServiceDelete(undefined);
            dispatch(payServiceActions.updateListMassivePayment(list, totalPaySum));
        };

        const handleHideDeleteModal = () => {
            setShowModalConfirmDelete(false);
            setServiceDelete(undefined);
        };

        const handleDeleteService = (id) => {
            setServiceDelete(id);
            setShowModalConfirmDelete(true);
        };

        const renderServicePay = (
            { id, companyLabel, alias, debt, amountPay, commission, debitPaymentMethod },
            index,
        ) => {
            const { allowPayWithCreditCard, optionsCreditCard: creditCardDeferredOptions } = debt;
            const radiButtonOptions = [];

            if (accountOptions?.options && accountOptions?.options?.length > 0) {
                radiButtonOptions.push({
                    id: "account",
                    label: i18n.get("servicePayments.pay.debitPaymentMethod.account"),
                });
            }
            if (
                allowPayWithCreditCard &&
                creditCardDeferredOptions &&
                creditCardDeferredOptions.length > 0 &&
                creditCardOptions?.options &&
                creditCardOptions?.options?.length > 0
            ) {
                radiButtonOptions.push({
                    id: "creditCard",
                    label: i18n.get("servicePayments.pay.debitPaymentMethod.creditCard"),
                });
            }

            return (
                <>
                    <Box fullWidth background="background-disabled" borderRadius="lg" className="p-5">
                        <Row customColumns={13}>
                            <Col xs={3}>{renderData(`${PREFIX}.service`, companyLabel)}</Col>
                            <Col xs={3}>{renderData(`${PREFIX}.alias`, alias)}</Col>
                            <Col xs={2}>{renderDataDate(`${PREFIX}.dueDate`, debt?.dueDate)}</Col>
                            <Col xs={2} className="text-right">
                                {renderDataAmount(
                                    "servicePayments.pay.amount",
                                    debitPaymentMethod === "creditCard" ? debt?.total : amountPay,
                                )}
                            </Col>
                            <Col xs={2} className="text-right">
                                {renderDataAmount("servicePayments.pay.commission", commission)}
                            </Col>
                            <Col xs={1} className="d-flex content-center">
                                <Button
                                    className="btn-remove-service"
                                    image="images/icons/deleteTrash.svg"
                                    bsStyle="only-icon"
                                    onClick={() => handleDeleteService(id)}
                                    disabled={values.list?.length <= 1}
                                />
                            </Col>
                        </Row>
                    </Box>
                    <PayServiceForm
                        index={index}
                        values={values}
                        errors={errors}
                        genericProps={genericProps}
                        ID_FORM={ID_FORM}
                        accountOptions={accountOptions}
                        creditCardOptions={creditCardOptions}
                        setFieldValue={setFieldValue}
                        isDesktop={isDesktop}
                    />
                </>
            );
        };

        return (
            <>
                {mode === "edit" && (
                    <Box
                        fullWidth
                        background="white"
                        borderRadius="lg"
                        className="box-shadow-small form-service-payments p-7 mb-login-layout">
                        <Box>
                            <Text bold labelKey={`${ID_FORM}.info`} />
                        </Box>
                        {values.list.map((service, index) => (
                            <Box key={`${service?.id}index${index}`} className="box-shadow-small p-5 mt-7">
                                {renderServicePay(service, index)}
                            </Box>
                        ))}
                        <FooterMassivePay
                            cant={values.list.length}
                            totalPay={totalAmount(values.list)}
                            labelButton="global.continue"
                            typeButton="submit"
                            disabledButton={!isValidForm(values)}
                        />
                        <ConfirmDialog
                            showDialog={showModalConfirmDelete}
                            title={i18n.get(`${ID_FORM}.delete.confirm.text`)}
                            // description={i18n.get("revelock.suspicious.activity.text") || ""}
                            onConfirm={handleAcceptDeleteModal}
                            onCancel={handleHideDeleteModal}
                            confirmLabel="global.delete"
                            textClassName="mx-md-5"
                            contentClassName="pt-7 pb-11 px-12"
                        />
                    </Box>
                )}
                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box background="background-disabled" className="p-5 mb-5" borderRadius="lg">
                            <Row>
                                <Col xs={6} className="d-flex align-items-center">
                                    <Text labelKey="servicePayments.massive.services.totalPay" />
                                </Col>
                                <Col xs={6}>
                                    <Box display="flex" fullWidth className="justify-content-end">
                                        <FormattedAmount
                                            quantity={totalPay}
                                            className="size-1 text-semibold display-inline"
                                            noCurrency
                                        />
                                        &nbsp;
                                        <Text size="1" light defaultValue={USD_CURRENCY} />
                                    </Box>
                                </Col>
                            </Row>
                        </Box>
                        <Box>
                            <Box className="mb-6">
                                <Text size="6" bold labelKey={`${ID_FORM}.confirmation.detail`} />
                            </Box>

                            {values.list.map((item, index) => {
                                const { id, companyLabel, alias, amountPay } = item;

                                return (
                                    <Box
                                        key={`${id}-${index}`}
                                        background="background-disabled"
                                        className="p-5 mb-5"
                                        borderRadius="lg">
                                        <Row>
                                            <Col xs={3}>{renderData(`${PREFIX}.service`, companyLabel, "7")}</Col>
                                            <Col xs={3}>{renderData(`${PREFIX}.alias`, alias, "7")}</Col>
                                            <Col xs={3} className="text-right">
                                                {renderDataAmount(
                                                    "servicePayments.pay.amount",
                                                    amountPay,
                                                    "justify-content-end",
                                                    "7",
                                                )}
                                            </Col>
                                            <Col xs={3} className="d-flex align-items-center justify-content-end">
                                                <Button
                                                    label={`${ID_FORM}.confirmation.btn.detail`}
                                                    className="text-light btn-small"
                                                    bsStyle="outline"
                                                    onClick={() => {
                                                        setPayDetail(item);
                                                        setShowModalDetail(true);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Box>
                                );
                            })}
                        </Box>

                        <SideBarModal
                            show={showModalDetail}
                            dialogClassName="modal-detail-pay-service"
                            onClose={() => {
                                setShowModalDetail(false);
                            }}>
                            <Box className="px-9">{showModalDetail && <>{renderPayDetail()}</>}</Box>
                        </SideBarModal>
                    </>
                ) : null}
                <ExitModal
                    modalShow={showExitModal}
                    acceptFunction={handleAcceptModal}
                    cancelFunction={handleHideModal}
                    headingText={i18n.get("confirm.exit.title")}
                    text={i18n.get("confirm.exit.info")}
                />
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        ticketConfirmation: true,
        cancelAction: handlerCancelAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        showSchedulerMessage: false,
        // validate: validateForm,
        titleFormConfirmation: "OTP CODE",
        data: initialValuesMassive(props),
        submitButtonLabel: "forms.servicePayment.confirmation.button",
        validationSchema,
        textBack: "global.return",
        textBackConfirmation: "global.return",
        showFormAcions: false,
        handleCancelPreview: handlerPreviewCancelAction,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
    field: payServiceSelectors.getField(state),
    massivePayService: payServiceSelectors.getMassivePayService(state),
    selectedBiller: payServiceSelectors.getSelectedBiller(state),
    queryBill: payServiceSelectors.getQueryBill(state),
    fetchingForm: formSelectors.getFetching(state),
    preDataForm: formSelectors.getPreData(state),
    currentLang: i18nSelectors.getLang(state),
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    previewData: formSelectors.getPreviewData(state),
    fetchingValidations: formSelectors.getFetchingValidations(state),
    validationData: formSelectors.getValidationData(state),
});

MassiveServicePaymentForm.propTypes = {
    history: shape({}).isRequired,
    isDesktop: bool,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    fetchingValidations: true,
    validationData: shape({}),
    previewData: shape({}),
    fromBackoffice: bool,
    subtitle: string,
    subtitlePrefix: string,
    fetching: bool,
    field: arrayOf(shape({})),
    fetchingForm: bool,
    dispatch: func.isRequired,
    transaction: shape({}),
    massivePayService: shape({
        list: arrayOf(shape({})),
        totalPay: number,
    }).isRequired,
};

MassiveServicePaymentForm.defaultProps = {
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    subtitle: true,
    subtitlePrefix: true,
    fetching: false,
    isDesktop: false,
    field: [],
    fetchingForm: false,
    transaction: null,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(MassiveServicePaymentForm));
