import DetailHeadInfoCorporate from "pages/creditCards/_components/DetailHeadInfoCorporate";
import Box from "pages/_components/Box";
import Notification from "pages/_components/Notification";
import ProductDetail from "pages/_components/ProductDetail";
import { bool, func, number as int, shape, string, arrayOf, instanceOf, array } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import moment from "moment";

import withRevelockPositionContext from "hoc/withRevelockPositionContext";

import PaginatedTable from "pages/_components/PaginatedTable/PaginatedTable";
import * as config from "util/config";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import { PERMISSION_CREDIT_CARD_POINTS_INQUIRY, PERMISSION_CREDIT_CARD_STATEMENT } from "constants.js";
import Dropdown from "pages/_components/Dropdown";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import SideBarModal from "pages/_components/modal/SideBarModal";
import { dictionaryCreditCardCorporateCode } from "util/creditCards.js";
import { isDesktop, isMobile } from "react-device-detect";
import MovementsCorporateFilter from "./_components/MovementsCorporateFilter";
import CreditCardCorporateMovementsConsumption from "./_components/CreditCardCorporateMovementsConsumption";
import CreditCardCorporateMovements from "./_components/CreditCardCorporateMovements";
import NoProduct from "../desktop/widgets/_components/NoProduct.jsx";
import FooterMassivePay from "pages/servicePayments/_components/FooterMassivePay";
import { push } from "react-router-redux/actions";

const TRANSFER_INTERNAL_PERMISSION = "transferInternal";
const TRANSFER_LOCAL_PERMISSION = "transferLocal";
const TRANSFER_FOREIGN_PERMISSION = "transferForeign";

const PAGE_IDENTIFIER = "creditCards.corporate";

const INICIAL_FILTER_STATE = {
    dateFrom: new Date(),
    dateTo: new Date(),
    dateFromConsume: new Date(),
    dateToConsume: new Date(),
    lastMovements: true,
    cardNumber: "",
};

class CorporateDetail extends Component {
    periods = {
        lastMonth: dateUtils.getLastMonthPeriod(),
        secondLastMonth: dateUtils.getSecondLastMonthPeriod(),
        currentPeriod: dateUtils.getCurrentPeriod(),
    };

    state = {
        selectedFilter: "currentPeriod",
        resetDateFilters: false,
        showSideBarDownloadDetail: false,
        downloadingStatements: false,
        showSideBarSearchStatements: false,
        transitMovementSize: 0,
        showEnabledWallet: false,
        showWallet: false,
        isCardWasDigitized: undefined,
        showMovFilters: false,
        showMovFiltersConsumo: false,
        showAdditionalsFilters: false,
        filters: INICIAL_FILTER_STATE,
        hasFiltersApplied: false,
        hasFiltersConsumoApplied: false,
        additionalCards: array,
        rowsPerPage: config.getInteger("table.rowsPerPage", 10),
        movementType: "TODOS",
        showMovToPay: false,
    };

    componentWillMount() {
        const {
            dispatch,
            match: { params },
        } = this.props;
        // dispatch(creditCardActions.readCreditCard(params?.id));
    }

    componentDidMount(values) {
        const {
            dispatch,
            creditCardCorporate,
            isFilterMovementCorporateApplied,
            isFilterConsumeCorporateApplied,
        } = this.props;
        const { filters } = this.state;
        const filterValues = { ...values };
        const { cardStyle, internalNumber, owner } = creditCardCorporate;
        if (!isFilterMovementCorporateApplied && !isFilterConsumeCorporateApplied) {
            dispatch(
                creditCardActions.corporateMovementCreditCardRequest(
                    cardStyle,
                    "TODOS",
                    { dateFrom: filters?.dateFrom, dateTo: filters?.dateTo },
                    internalNumber,
                    owner,
                    "TODOS",
                ),
            );
        } else {
            this.setState({
                filters: filterValues,
                hasFiltersApplied: isFilterMovementCorporateApplied,
                showMovFilters: false,
                hasFiltersConsumoApplied: isFilterConsumeCorporateApplied,
                showMovFiltersConsumo: false,
                movementType: dictionaryCreditCardCorporateCode(filterValues.transactionType),
            });
        }
    }

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.showOptions());
    };

    handleGoToPay = () => {
        const { dispatch, creditCardCorporate, creditCardCorporateMovementsConsume } = this.props;
        dispatch(creditCardActions.setMovementConsumeScreen("CONSUMETOPAY"));

        const checkedStatements = creditCardCorporateMovementsConsume.filter((item) => item.checkToPay === true);

        checkedStatements.forEach((item) => {
            item.cardStyle = creditCardCorporate.cardStyle;
            item.owner = creditCardCorporate.owner;
        });

        dispatch(creditCardActions.updateMovementConsumeToPay(checkedStatements));

        dispatch(push(`/CreditCardsCorporatePayment?creditCard=${creditCardCorporate.internalNumber}`));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.clearCorporateMovementConsumeRequest());
        this.setState({
            filters: INICIAL_FILTER_STATE,
            hasFiltersConsumoApplied: false,
            hasFiltersApplied: false,
        });
        dispatch(routerActions.goBack());
    };

    handleClearFilterClick = () => {
        const { dispatch, creditCardCorporate } = this.props;
        const { cardStyle, internalNumber, owner } = creditCardCorporate;
        dispatch(
            creditCardActions.corporateMovementCreditCardRequest(
                cardStyle,
                "TODOS",
                { dateFrom: INICIAL_FILTER_STATE?.dateFrom, dateTo: INICIAL_FILTER_STATE?.dateTo },
                internalNumber,
                owner,
                "MOVIMIENTOS",
            ),
        );
        this.setState({
            filters: INICIAL_FILTER_STATE,
            hasFiltersApplied: false,
            movementType: "TODOS",
        });
        dispatch(creditCardActions.corporateCurrentPageMovement(1));
    };

    handleClearFilterConsumoClick = () => {
        const { dispatch, creditCardCorporate } = this.props;
        const { cardStyle, internalNumber, owner } = creditCardCorporate;
        dispatch(
            creditCardActions.corporateMovementCreditCardRequest(
                cardStyle,
                "TODOS",
                { dateFrom: INICIAL_FILTER_STATE?.dateFrom, dateTo: INICIAL_FILTER_STATE?.dateTo },
                internalNumber,
                owner,
                "CONSUMOS",
            ),
        );

        dispatch(creditCardActions.updateMovementConsumeToPay([]));

        this.setState({
            filters: INICIAL_FILTER_STATE,
            hasFiltersConsumoApplied: false,
            movementType: "TODOS",
        });
        dispatch(creditCardActions.corporateCurrentPageConsume(1));
    };

    handleFilterClick = (values) => {
        const { dispatch, creditCardCorporate } = this.props;
        const filterValues = { ...values };
        const { filters } = this.state;
        const { cardStyle, internalNumber, owner } = creditCardCorporate;
        const filterResult = {
            dateFrom: filterValues.dateFrom,
            dateTo: filterValues.dateTo,
            dateFromConsume: filters.dateFromConsume,
            dateToConsume: filters.dateToConsume,
        };
        dispatch(
            creditCardActions.corporateMovementCreditCardRequest(
                cardStyle,
                dictionaryCreditCardCorporateCode(filterValues.transactionType),
                { dateFrom: filterValues?.dateFrom, dateTo: filterValues?.dateTo },
                internalNumber,
                owner,
                "MOVIMIENTOS",
            ),
        );

        this.setState({
            filters: filterValues,
            hasFiltersApplied: true,
            showMovFilters: false,
            showMovToPay: false,
            movementType: dictionaryCreditCardCorporateCode(filterValues.transactionType),
        });
    };

    handleFilterConsumoClick = (values) => {
        const { dispatch, creditCardCorporate } = this.props;
        const filterValues = { ...values };
        const { filters } = this.state;
        const { cardStyle, internalNumber, owner } = creditCardCorporate;
        const filterResult = {
            dateFrom: filters.dateFrom,
            dateTo: filters.dateTo,
            dateFromConsume: filterValues.dateFrom,
            dateToConsume: filterValues.dateTo,
        };
        dispatch(
            creditCardActions.corporateMovementCreditCardRequest(
                cardStyle,
                dictionaryCreditCardCorporateCode(filterValues.transactionType),
                { dateFrom: filterValues?.dateFrom, dateTo: filterValues?.dateTo },
                internalNumber,
                owner,
                "CONSUMOS",
            ),
        );

        this.setState({
            filters: filterResult,
            hasFiltersConsumoApplied: true,
            showMovFiltersConsumo: false,
            showMovToPay: true,
            movementType: dictionaryCreditCardCorporateCode(filterValues.transactionType),
        });
    };

    onFinishDownload = () => {
        this.setState({
            downloadingStatements: false,
        });
    };

    handleClickDownload = (format, type) => {
        const { dispatch, creditCardCorporate } = this.props;
        const { filters, movementType } = this.state;
        const { cardStyle, internalNumber, owner } = creditCardCorporate;
        if (type == "NoConsume") {
            this.setState({
                downloadingStatements: true,
            });
            dispatch(
                creditCardActions.downloadCorporateMovement(
                    creditCardCorporate.numberMask,
                    creditCardCorporate.nameOnCard,
                    cardStyle,
                    movementType,
                    { dateFrom: filters?.dateFrom, dateTo: filters?.dateTo },
                    internalNumber,
                    owner,
                    format,
                    this.onFinishDownload,
                ),
            );
        }
        if (type == "Consume") {
            this.setState({
                downloadingStatements: true,
            });
            dispatch(
                creditCardActions.downloadCorporateMovement(
                    creditCardCorporate.numberMask,
                    creditCardCorporate.nameOnCard,
                    cardStyle,
                    "CONSUMO_PENDIENTE_PAGO",
                    { dateFrom: filters?.dateFromConsume, dateTo: filters?.dateToConsume },
                    internalNumber,
                    owner,
                    format,
                    this.onFinishDownload,
                ),
            );
        }
    };

    renderDownloadCorporateMovementsDocs = (disabled) => {
        const { downloadingStatements } = this.state;
        const { creditCardCorporateMovements } = this.props;
        const otrosMovimientos = creditCardCorporateMovements;
        // if (!downloadingMovements) {
        const documents = [
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickDownload("pdf", "NoConsume"),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickDownload("xls", "NoConsume"),
            },
            {
                label: "global.txtFile",
                onClick: () => this.handleClickDownload("txt", "NoConsume"),
            },
        ];
        return (
            <Dropdown
                image="images/util/arrow-down.svg"
                label="global.download"
                buttonClass={isDesktop ? "btn-download ml-7" : "btn-download"}
                fetching={downloadingStatements}
                imageRight
                disabled={!(otrosMovimientos && otrosMovimientos.length > 0)}
                pullRight>
                {documents.map((item) => (
                    <Button
                        key={item.label}
                        label={item.label}
                        onClick={() => {
                            item.onClick();
                        }}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                ))}
            </Dropdown>
        );
    };

    renderDownloadCorporateConsumeMovementsDocs = (disabled) => {
        const { creditCardCorporateMovementsConsume } = this.props;
        const { downloadingStatements } = this.state;
        const consumos = creditCardCorporateMovementsConsume;
        // if (!downloadingMovements) {
        const documents = [
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickDownload("pdf", "Consume"),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickDownload("xls", "Consume"),
            },
            {
                label: "global.txtFile",
                onClick: () => this.handleClickDownload("txt", "Consume"),
            },
        ];

        return (
            <Dropdown
                image="images/util/arrow-down.svg"
                label="global.download"
                buttonClass={isDesktop ? "btn-download ml-7" : "btn-download"}
                fetching={downloadingStatements}
                imageRight
                disabled={!(consumos && consumos.length > 0)}
                pullRight>
                {documents.map((item) => (
                    <Button
                        key={item.label}
                        label={item.label}
                        onClick={() => {
                            item.onClick();
                        }}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                ))}
            </Dropdown>
        );
    };

    handleShowFilterConsumoClick = () => {
        this.setState({
            showMovFiltersConsumo: true,
        });
    };

    handleShowFilterClick = () => {
        this.setState({
            showMovFilters: true,
        });
    };

    onChangePageMovement = (currentPage) => {
        const { dispatch } = this.props;
        console.log("currentPage", currentPage);
        dispatch(creditCardActions.corporateCurrentPageMovement(currentPage));
    };

    onChangePageConsume = (currentPage) => {
        const { dispatch } = this.props;
        this.setState({
            currentPageCon: currentPage,
        });
        dispatch(creditCardActions.corporateCurrentPageConsume(currentPage));
    };

    handleClickClearConsumeToPay = () => {
        const { dispatch, creditCard } = this.props;
        dispatch(creditCardActions.updateMovementConsumeToPay([]));
        // dispatch(push("/CreditCardsCorporatePayment?creditCard=" + creditCard.internalNumber));
    };

    render() {
        const {
            creditCardCorporate,
            creditCardCorporateMovements,
            creditCardCorporateMovementsConsume,
            creditCardDetail,
            isDesktop,
            dispatch,
            isFetching,
            totalFavorites,
            getSelectProduct,
            currentLang,
            extraDataDetail,
            activeEnvironment,
            showExchangePointForm,
            changedPoints,
            history,
            creditCardInList,
            isTablet,
            currentPage,
            paginatedDataMap,
            itemsMap,
            permissionStatement,
            isAditional,
            additionalCreditCards,
            isFetchingCorporateMovements,
            isFetchingCorporateMovementsConsume,
            isFetchingCorporateMovementsAll,
            permissionInquiryPoints,
            currentPageMov,
            currentPageCon,
            currentTabCorporateDetail,
            movementsConsumeScreen,
            movementsConsumeToPay,
        } = this.props;
        const {
            transitMovementSize,
            downloadingStatements,
            downloadingDeferred,
            downloadingAdditionals,
            showSideBarSearchStatements,
            showSideBarDownloadDetail,
            showMovFilters,
            showMovFiltersConsumo,
            showAdditionalsFilters,
            filters,
            hasFiltersApplied,
            hasFiltersConsumoApplied,
            rowsPerPage,
            movementType,
            showMovToPay,
        } = this.state;

        const secondaryBtn = () => {
            const secondaryBtnArr = [];

            if (isDesktop) {
                const btnPayCard = {
                    bsStyle: "primary",
                    label: "creditCard.buttons.payCard",
                    href: `/CreditCardsCorporatePayment?creditCard=`,
                    onClick: () => this.handleClickClearConsumeToPay,
                    showInfAdditional: true,
                    showInfMain: true,
                    permissionList: ["*"],
                };
                secondaryBtnArr.push(btnPayCard);
            }
            return secondaryBtnArr;
        };

        const onSelectTabCredit = (selectIndex) => {
            const { dispatch } = this.props;
            dispatch(creditCardActions.setCurrentTabCorporateDetail(selectIndex));

            if (selectIndex === 0) {
                dispatch(creditCardActions.setMovementConsumeScreen("MOVIMIENTOS"));
            } else {
                dispatch(creditCardActions.setMovementConsumeScreen("CONSUMOS"));
            }
        };

        const secondaryButtonList = secondaryBtn();

        creditCardCorporateMovements.forEach((item) => {
            item.idUsedCreditCard = creditCardCorporate.internalNumber;
        });
        creditCardCorporateMovementsConsume.forEach((item) => {
            item.idUsedCreditCard = creditCardCorporate.internalNumber;
        });
        // const consumos = creditCardCorporateMovements.filter((item) => item.type === "CONSUMOS" && item.status !== "CANCELADO");
        const consumos = creditCardCorporateMovementsConsume;
        const otrosMovimientos = creditCardCorporateMovements;

        return (
            <>
                <PageLoading loading={isFetchingCorporateMovementsAll} classicStyle={false}>
                    <Notification scopeToShow="creditCard/corporateMovements" />
                    <ProductDetail>
                        <ProductDetail.Header
                            dispatch={dispatch}
                            handleOptionsClick={this.handleClick}
                            isDesktop={isDesktop}
                            onBack={this.handleBack}
                            product={creditCardCorporate}
                            idProduct={creditCardCorporate?.internalNumber}
                            productId={creditCardCorporate?.idProduct}
                            secondaryBtn={secondaryButtonList}
                            totalFavorites={totalFavorites}
                            selectProduct={getSelectProduct}
                            textBack="global.return"
                            renderProductTitle={false}
                            productStatus="creditCard.status.additional.false"
                        />
                        <ProductDetail.Body
                            id="productDetail.body"
                            closeMoreFiltersWhenSiblingClick
                            isDesktop={isDesktop}
                            product={creditCardCorporate}
                            productKind="creditCards"
                            filters={[]}
                            {...(creditCardCorporate.isTherePendingTransaction && {
                                disclaimerStyled: "warning",
                                disclaimerLabelkey: "accounts.disclaimer.pendingTransaction",
                            })}
                            {...{
                                detailHeadInfo: (
                                    <DetailHeadInfoCorporate {...this.props} creditCard={creditCardCorporate} />
                                ),
                            }}
                            onSelect={onSelectTabCredit}
                            selectedIndex={currentTabCorporateDetail}>
                            {creditCardCorporateMovements && (
                                <Box
                                    iconLabel="images/icons/movements.svg"
                                    isDesktop={isDesktop}
                                    keyLabel="creditCard.statements">
                                    <PaginatedDataTable
                                        // handleOpen={() => {
                                        //     const { creditCardCorporate, filters } = this.props;
                                        //     const {cardStyle, internalNumber, owner} = creditCardCorporate;
                                        //     dispatch(creditCardActions.corporateMovementCreditCardRequest(cardStyle, "TODOS", { dateFrom: filters?.dateFrom, dateTo: filters?.dateTo}, internalNumber, owner));
                                        // }}
                                        nameList="statements"
                                        data={creditCardCorporateMovements}
                                        hasFilterApplied="falsex"
                                        rowsPerPage={rowsPerPage}
                                        showFilterModal="true"
                                        hasFiltersApplied={hasFiltersApplied}
                                        filterAction={this.handleShowFilterClick}
                                        renderDownloadDocs={this.renderDownloadCorporateMovementsDocs}
                                        component={CreditCardCorporateMovements}
                                        hasTooltip="true"
                                        textTooltip={i18nUtils.get(
                                            "creditCardCorporate.detail.statement.header.tooltip",
                                            "Al descargar también podrá consultar los campos secuencial y fecha de operación",
                                        )}
                                        isDesktop={isDesktop}
                                        // handleShowFilter={this.handleShowFilterClick}
                                        handleClearFilter={this.handleClearFilterClick}
                                        // renderDownloadDocs={this.renderDownloadMovementDocs}
                                        isFetching={isFetchingCorporateMovements}
                                        emptyMessage={
                                            <NoProduct
                                                text="wally.movements.without.transaction"
                                                showContacts={false}
                                            />
                                        }
                                        onChangePage={this.onChangePageMovement}
                                        startPage={currentPageMov}
                                    />
                                </Box>
                            )}
                            {creditCardCorporateMovementsConsume && (
                                <Box
                                    className={isMobile && "mb-16"}
                                    iconLabel="images/icons/receipt.svg"
                                    isDesktop={isDesktop}
                                    keyLabel="creditCards.corporate.consumption.pending.payment">
                                    <PaginatedDataTable
                                        nameList="statements"
                                        data={creditCardCorporateMovementsConsume}
                                        hasFilterApplied="falsex"
                                        rowsPerPage={rowsPerPage}
                                        showFilterModal="true"
                                        hasFiltersApplied={hasFiltersConsumoApplied}
                                        filterAction={this.handleShowFilterConsumoClick}
                                        renderDownloadDocs={this.renderDownloadCorporateConsumeMovementsDocs}
                                        component={CreditCardCorporateMovementsConsumption}
                                        hasTooltip="true"
                                        textTooltip={i18nUtils.get(
                                            "creditCardCorporate.detail.statement.header.tooltip",
                                            "Al descargar también podrá consultar los campos secuenciales y fecha de operación",
                                        )}
                                        isDesktop={isDesktop}
                                        // handleShowFilter={this.handleShowFilterClick}
                                        handleClearFilter={this.handleClearFilterConsumoClick}
                                        // renderDownloadDocs={this.renderDownloadMovementDocs}
                                        isFetching={isFetchingCorporateMovementsConsume}
                                        emptyMessage={
                                            <NoProduct
                                                text="wally.movements.without.transaction"
                                                showContacts={false}
                                            />
                                        }
                                        startPage={currentPageCon}
                                        onChangePage={this.onChangePageConsume}
                                    />
                                </Box>
                            )}
                            {!creditCardCorporateMovementsConsume && !creditCardCorporateMovements && (
                                <Box>
                                    <NoProduct text="creditCard.corporate.movement.empty" showContacts={false} />{" "}
                                </Box>
                            )}
                        </ProductDetail.Body>
                    </ProductDetail>
                    <Box className={isMobile ? "" : "mt-14"}>
                        {creditCardCorporateMovementsConsume.some((item) => item.checkToPay === true) &&
                            (movementsConsumeScreen === "CONSUMOS" || movementsConsumeScreen === "CONSUMETOPAY") && (
                                <FooterMassivePay
                                    cant={
                                        creditCardCorporateMovementsConsume.filter((item) => item.checkToPay === true)
                                            .length
                                    }
                                    totalPay={creditCardCorporateMovementsConsume.reduce((sum, item) => {
                                        return item.checkToPay === true ? sum + (item.amount || 0) : sum;
                                    }, 0)}
                                    labelButton={`${PAGE_IDENTIFIER}.btn.pay`}
                                    onClick={this.handleGoToPay}
                                    // disabledButton={!isValidAmount()}
                                />
                            )}
                        <SideBarModal
                            show={showMovFilters}
                            onClose={() => {
                                this.setState({ showMovFilters: false });
                            }}
                            dialogClassName="big-modal"
                            title="global.filter">
                            <MovementsCorporateFilter
                                showMovFiltersConsumo={false}
                                minDate={moment().add(-12, "months")}
                                initialData={INICIAL_FILTER_STATE}
                                handleFilter={this.handleFilterClick}
                                handleCancel={() => {
                                    this.setState({ showMovFilters: false });
                                }}
                            />
                        </SideBarModal>

                        <SideBarModal
                            show={showMovFiltersConsumo}
                            onClose={() => {
                                this.setState({ showMovFiltersConsumo: false });
                            }}
                            dialogClassName="big-modal"
                            title="global.filter">
                            <MovementsCorporateFilter
                                showMovFiltersConsumo
                                minDate={moment().add(-24, "months")}
                                initialData={INICIAL_FILTER_STATE}
                                handleFilter={this.handleFilterConsumoClick}
                                handleCancel={() => {
                                    this.setState({ showMovFiltersConsumo: false });
                                }}
                            />
                        </SideBarModal>
                    </Box>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;

    return {
        creditCardCorporate: creditCardSelectors.getCreditCardCorporate(state, id),
        creditCardCorporateMovements: creditCardSelectors.getCorporateMovements(state),
        creditCardCorporateMovementsConsume: creditCardSelectors.getCorporateMovementsConsume(state),
        isFetchingCorporateMovements: creditCardSelectors.isFetchingCorporateMovements(state),
        isFetchingCorporateMovementsConsume: creditCardSelectors.isFetchingCorporateMovementsConsume(state),
        isFetchingCorporateMovementsAll: creditCardSelectors.isFetchingCorporateMovementAll(state),
        isFilterMovementCorporateApplied: creditCardSelectors.isFilterMovementCorporateApplied(state),
        isFilterConsumeCorporateApplied: creditCardSelectors.isFilterConsumeCorporateApplied(state),
        currentPageMov: creditCardSelectors.getCurrentPageMovement(state),
        currentPageCon: creditCardSelectors.getCurrentPageConsume(state),
        currentTabCorporateDetail: creditCardSelectors.getCurrentTabCorporateDetail(state),
        movementsConsumeScreen: creditCardSelectors.getMovementConsumeScreen(state),
        movementsConsumeToPay: creditCardSelectors.getMovementsConsumeToPay(state),
    };
};

CorporateDetail.propTypes = {
    creditCardCorporateMovements: shape({}),
    creditCardCorporateMovementsConsume: shape({}),
    creditCardCorporate: shape({}),
    creditCard: shape({}),
    additionalCreditCards: shape({}),
    creditCardInList: shape({}),
    match: shape({}).isRequired,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    // filters: shape({}).isRequired,
    totalFavorites: int.isRequired,
    getSelectProduct: shape({}),
    currentLang: string,
    extraDataDetail: shape({}),
    creditCards: arrayOf(shape({})),
    activeEnvironment: shape({}),
    showExchangePointForm: bool.isRequired,
    changedPoints: int,
    setLocationCustomPath: string,
    isMobile: bool,
    history: shape({}),
    loggedUser: shape({}),
    isTablet: bool.isRequired,
    currentPage: int,
    paginatedDataMap: instanceOf(Map),
    itemsMap: instanceOf(Map),
    permissionStatement: bool.isRequired,
    permissionInquiryPoints: bool.isRequired,
    movementsConsumeScreen: string,
    movementsConsumeToPay: arrayOf(shape({})),
};

CorporateDetail.defaultProps = {
    creditCardCorporate: {},
    creditCard: {},
    creditCardInList: {},
    getSelectProduct: undefined,
    currentLang: "",
    extraDataDetail: {},
    creditCards: [],
    activeEnvironment: { permissions: [] },
    changedPoints: undefined,
    setLocationCustomPath: "",
    isMobile: false,
    history: {},
    loggedUser: undefined,
    currentPage: undefined,
    paginatedDataMap: undefined,
    itemsMap: undefined,
    movementsConsumeScreen: "",
    movementsConsumeToPay: [],
};

export default connect(mapStateToProps)(withRevelockPositionContext(CorporateDetail));
