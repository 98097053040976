import queryString from "query-string";
import { LOCATION_CHANGE, push, replace, routerActions } from "react-router-redux";
import { call, put, select, takeLatest } from "redux-saga/effects";

import b64toBlob from "b64-to-blob";
import { ACTIVITIES_WITHOUT_PREVIEW_STEP } from "constants.js";
import * as file from "middleware/file";
import * as form from "middleware/form";
import * as wally from "middleware/wally";
import moment from "moment";
import { selectors as checksSelectors } from "reducers/checks";
import { actions as creditCardsActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import transactionLinesTypes from "reducers/form/transactionLinesTypes";
import { actions as beneficiaryActions } from "reducers/frequentDestination/frequentDestination";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionsActions } from "reducers/transactions";
import { actions as productActions } from "reducers/products";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import {
    actions as creditCardOtherBankActions,
    selectors as creditCardOtherBankSelectors,
} from "reducers/creditCardOtherBank";
import { types as templateTypes } from "reducers/template";
import types from "reducers/types/form";
import { actions as wallyActions, selectors as wallySelectors, types as wallyTypes } from "reducers/wally";
import { BENEFICIARY_VALIDATION_PREVIEW_ACTIVITIES } from "util/beneficiaryUtil";
import * as configUtils from "util/config";
import { getDisplay, getMobileOS, isAndroidPlatform, isMobileNativeFunc } from "util/device";
import { downloadMobileFile, downloadPdf, downloadXls } from "util/download";
import { adjustIdFieldErrors, credentialsToUnderscoreFormat, getBeneficiaryName } from "util/form";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import { getTransactionKind } from "util/transaction";
import html2canvas from "html2canvas";
import { actions as softTokenActions } from "reducers/softToken";
import { store } from "store";
import { readCreditCardList } from "./login";

const administrationTicketRoutes = {
    "administration.simple.modify.permissions.send": (id) => `/administration/simple/permissions/${id}/ticket`,
    "administration.medium.modify.permissions.send": (id) => `/administration/medium/permissions/${id}/ticket`,
    "administration.users.blockunblock.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.users.delete.send": (id) => `/administration/users/actions/${id}/ticket`,
    "administration.groups.blockunblock.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.groups.delete.send": (id) => `/administration/groups/actions/${id}/ticket`,
    "administration.medium.modify.channels.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/channels/${id}/ticket`,
    "administration.medium.modify.signature.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signature/${id}/ticket`,
    "administration.signatures.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.signatures.non.monetary.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.signatures.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.signatures.delete.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/signaturesSchemes/${id}/ticket`,
    "administration.user.detail.groups.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupsOfUser/${id}/ticket`,
    "administration.users.invite.send": (id) => `/administration/medium/userInvite/${id}/ticket`,
    "administration.advanced.group.modify.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
    "administration.advanced.group.create.send": (id, administrationScheme) =>
        `/administration/${administrationScheme}/groupFormData/${id}/ticket`,
    "administration.restrictions.manage.send": (id) => `/administration/restrictions/manage/${id}/ticket`,
    "administration.restrictions.user.delete.send": (id) => `/administration/restrictions/user/delete/${id}/ticket`,
    "administration.medium.modify.contacts.send": (id) => `/administration/restrictions/user/delete/${id}/ticket`,
};
const sagas = [
    takeLatest(LOCATION_CHANGE, readForm),
    takeLatest(types.PREVIEW_FORM_REQUEST, previewForm),
    takeLatest(types.SEND_FORM_REQUEST, sendForm),
    takeLatest(LOCATION_CHANGE, readTransaction),
    takeLatest(LOCATION_CHANGE, readTransactionFromBackoffice),
    takeLatest(types.SAVE_DRAFT_REQUEST, saveDraftTransaction),
    takeLatest(types.CANCEL_TRANSACTION_PRE_REQUEST, cancelTransactionPre),
    takeLatest(types.CANCEL_TRANSACTION_REQUEST, cancelTransaction),
    takeLatest(types.MODIFY_TRANSACTION_REQUEST, modifyTransaction),
    takeLatest(types.SIGN_TRANSACTION_PREVIEW_REQUEST, signTransactionPreview),
    takeLatest(types.SIGN_TRANSACTION_REQUEST, signTransaction),
    takeLatest(types.READ_TRANSACTION_REQUEST, readTransaction),
    takeLatest(types.SEND_FORM_DATA_FAILURE, logout),
    takeLatest(transactionLinesTypes.LIST_TRANSACTION_LINES_REQUEST, listTransactionLinesRequest),
    takeLatest(types.DOWNLOAD_TICKET_REQUEST, downloadTicket),
    takeLatest(types.SHARE_TICKET, shareTicket),
    takeLatest(types.PRE_FORM_REQUEST, preForm),
    takeLatest(types.PRE_CUSTOM_FORM_REQUEST, preCustomForm),

    takeLatest(types.BANK_DESCRIPTION_REQUEST, getBankDescriptionData),
    takeLatest(types.CONFIRM_MOVEMENT_PENDING_WALLY, confirmMovementPendingWally),
    takeLatest(types.CONFIRM_SUSPEND_CHECK, confirmSuspendCheck),
    takeLatest(types.DELETE_CREDIT_CARD_OTHER_BANK, deleteCreditCardOtherBank),
    takeLatest(types.CONFIRM_PAYMENT_FAST_RECHARGE, confirmPaymentFastRecharge),

    takeLatest(types.DOWNLOAD_IMG_TICKET_REQUEST, downloadImageTicket),
    takeLatest(types.SHARE_IMG_TICKET, shareImageTicket),
    takeLatest(types.PRE_VALIDATIONS_FORM_REQUEST, preValidationsForm),
];

const activitiesNoTransactional = ["requestTransfers.wally.send"];

export default sagas;

function* readForm({ payload }) {
    const {
        state = {
            shouldLoadForm: true,
        },
        pathname,
        search,
    } = payload;
    const [, route, idForm] = pathname.split("/");

    if (route === "form" && state.shouldLoadForm) {
        const { query: params } = queryString.parseUrl(search);
        const response = yield call(form.readForm, idForm, params);

        if (response.type === "W") {
            yield put({ type: types.READ_FORM_FAILURE, notification: { type: "error", code: response.data.code } });
        } else {
            const { form: formMetadata, formData } = response.data.data;

            yield put({ type: templateTypes.LOAD_TEMPLATE_LIST, idForm });
            yield put({ type: types.READ_FORM_SUCCESS, idForm, formMetadata, formData });
        }
    }
}

function* previewForm({ payload }) {
    const { idForm, idActivity, idTransaction, values, formikBag, idActivityPreview } = payload;
    const index = idActivity.lastIndexOf(".send");
    const previewActivity = idActivityPreview ? idActivityPreview : `${idActivity.substring(0, index)}.preview`;
    const { type, data } = yield call(form.preview, idForm, previewActivity, idTransaction, values);

    const {
        props: { metadata },
    } = formikBag;
    let needsAdditionalSteptoEdit = false;
    if (metadata && metadata.fieldList) {
        needsAdditionalSteptoEdit = metadata.fieldList.filter((i) => i.idField === "overdraft").length > 0;
    }
    const mode = !needsAdditionalSteptoEdit ? "preview" : "edit-step2";
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        if (data.error) {
            yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
            if (data.error && data.error.details) {
                formikBag.setErrors(adjustIdFieldErrors(data.error.details));
            }
            formikBag.setSubmitting(false);
        } else {
            const response = yield call(form.listCredentialsGroups, idForm, idActivity);

            yield put({
                type: types.PREVIEW_FORM_SUCCESS,
                idForm,
                credentialsGroups: response.data.data.groups,
                submitAction: formActions.sendForm,
                submitActionParams: { idForm, idActivity, idTransaction, values },
                previewData: data,
            });
            yield put(formActions.setData(values));
            formikBag.setSubmitting(false);

            yield put({
                type: types.SET_MODE,
                mode,
                prevMode: "edit",
            });
        }
    } else if (type === "W") {
        if (data.code === "API805W") {
            const errors = { alreadyRegistered: "alreadyRegistered" };
            formikBag.setErrors(errors);
            formikBag.setSubmitting(false);
            return;
        }
        const errors = adjustIdFieldErrors(data.data);

        if (data.code !== "COR020W" && !data?.data?.snackbar) {
            yield put(notificationActions.showNotification(data.message, "error", ["form"]));
        } else if (data.data.NO_FIELD) {
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["form"]));
        } else if (data?.data?.snackBarSameSteep) {
            yield put(notificationActions.showNotification(data?.data?.snackBarSameSteep, "error", ["form"]));
        } else if (errors) {
            formikBag.setErrors(errors);
        } else {
            yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
        }

        if (data?.data?.snackbar) {
            yield put(notificationActions.showNotification(data?.data?.snackbar, "error", ["form"]));
        }

        formikBag.setTouched({ beneficiary: { number: true } });
        formikBag.setSubmitting(false);
    } else {
        const alertConfirmation = data?.data?.alertConfirmation;
        const disclaimerList = data?.data?.disclaimerList;
        const submitDirectly = alertConfirmation?.submit !== undefined ? alertConfirmation?.submit : true;

        // TODO a WARNING here must be treated as an ERROR, right?
        const response = yield call(form.listCredentialsGroups, idForm, idActivity);

        let valuesDef = { ...values };

        if (idActivity === "checks.block.send") {
            valuesDef = { ...valuesDef, rate: data?.data?.clientRate };
        }
        if (idActivity === "creditCard.otherBanks.payment.send") {
            valuesDef = { ...valuesDef, rate: data?.data?.rate };
        }

        /**
         * Validate save beneficiary
         */
        if (BENEFICIARY_VALIDATION_PREVIEW_ACTIVITIES.includes(previewActivity)) {
            const saveBeneficiary = data?.data?.saveBeneficiary;
            const { beneficiary } = valuesDef;
            const beneficiaryUpdate = beneficiary ? { ...beneficiary, saveBeneficiary } : beneficiary;
            valuesDef = { ...values, beneficiary: beneficiaryUpdate };
        }
        if (disclaimerList && disclaimerList.length > 0) {
            for (let i = 0; i < disclaimerList.length; i++) {
                const disclaimer = disclaimerList[i];
                if (disclaimer && disclaimer.level && disclaimer.message) {
                    yield put(
                        notificationActions.showNotification(disclaimer.message, disclaimer.level, ["form"], false),
                    );
                }
            }
        }

        /**
         * Validate alert confirm transaction
         */

        if (alertConfirmation) {
            yield put({
                type: types.PREVIEW_FORM_CONFIRM,
                idForm,
                credentialsGroups: response.data.data.groups,
                submitAction: formActions.sendForm,
                submitActionParams: {
                    idForm,
                    idActivity,
                    idTransaction,
                    values: valuesDef,
                    confirmationFetching: true,
                },
                previewData: data.data,
                alertConfirmation,
                submitDirectly,
                formikBagConfirmation: formikBag,
            });
            yield put({
                type: types.SET_MODE,
                mode: "edit",
                prevMode: "",
            });
        } else {
            yield put({
                type: types.PREVIEW_FORM_SUCCESS,
                idForm,
                credentialsGroups: response.data.data.groups,
                submitAction: formActions.sendForm,
                submitActionParams: { idForm, idActivity, idTransaction, values: valuesDef },
                previewData: data.data,
            });

            yield put({
                type: types.SET_MODE,
                mode,
                prevMode: "edit",
            });
        }

        yield put(formActions.setData(values));
        formikBag.setSubmitting(false);
    }
}

function* downloadRefLetter(contentType, content, fileName) {
    if (!contentType || !content) {
        yield;
        return;
    }

    const contentTypeMap = new Map([
        ["pdf", "application/pdf"],
        ["xls", "application/vnd.ms-excel"],
    ]);

    const contenTypeDef = contentTypeMap.get(contentType);
    if (!contenTypeDef || contenTypeDef === "") {
        yield;
        return;
    }

    if (isMobileNativeFunc()) {
        const fileBlob = b64toBlob(content, contenTypeDef);
        downloadMobileFile(fileBlob, fileName || "referenceLetter", contenTypeDef);
        yield;
        return;
    }
    downloadPdf(fileName, content);
}

function* sendForm({ payload }) {
    const { idForm, idActivity, idTransaction, values, credentials, formikBag } = payload;
    let dataValues = values;
    const fullOwnerName = yield select((state) => sessionSelectors.getUserFullName(state));
    const beneficiaryDescription = getBeneficiaryName(values, idActivity, fullOwnerName);
    if (beneficiaryDescription) {
        dataValues = { ...values, beneficiaryDescription };
    }
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        const response = yield call(form.send, idForm, idActivity, idTransaction, dataValues, credentials);
        const { data, status } = response;
        if (status === 200 && typeof data === "string") {
            yield put(routerActions.replace(`/transaction/${data}`));
        } else {
            yield put({
                type: types.SEND_FORM_DATA_FAILURE,
                code: "",
            });
            if (data && data.error && data.error.message) {
                if (data.error.details && data.error.details.transactionId) {
                    yield put(routerActions.replace(`/transaction/${data.error.details.transactionId}`));
                } else {
                    yield put(notificationActions.showNotification(data.error.message, "error", ["form"]));
                }
            } else {
                yield put(notificationActions.showNotification(data[Object.keys(data)[0]], "error", ["form"]));
            }
        }
        formikBag.setSubmitting(false);
    } else {
        const { data, type } = yield call(form.send, idForm, idActivity, idTransaction, dataValues, credentials);
        if (type === "W") {
            const hasIncorrectCredentials = Object.keys(credentials).some((key) => data.data[key]);
            if (hasIncorrectCredentials) {
                formikBag.setErrors(adjustIdFieldErrors(data.data));
                yield put({
                    type: types.SEND_FORM_CREDENTIAL_FAILURE,
                    code: data.code,
                });
                if (idActivity === "transfers.approve.wally.send") {
                    yield put(notificationActions.showNotification(data.data.amount, "error", ["form"], false));
                }
            } else if (data.data.snackBarSameSteep) {
                yield put(notificationActions.showNotification(data.data.snackBarSameSteep, "error", ["form"], false));
            } else if (data.code === "COR586W" && idActivity === "transfers.foreign.send") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("forms.transfers.foreign.estimatedAmount.insufficient"),
                        "error",
                        ["form"],
                        false,
                    ),
                );
                yield put({
                    type: types.SEND_FORM_DATA_INSUFFICIENT_FOUND,
                });
            } else if (idActivity === "serviceRegistration.send" && data.code === "API707W") {
                yield put({
                    type: types.SEND_FORM_CREDENTIAL_FAILURE,
                    code: data.code,
                });
                formikBag.setSubmitting(false);
                yield put(sessionActions.logoutUserBlocked());
                return;
            } else if (idActivity === "paymentService.send" && data.code !== "COR055W" && data.code !== "COR065W") {
                const { idTransactionPay } = data.data;
                yield put(
                    transactionsActions.setActionSecondaryByPath(
                        "servicePayments.ticket.action.goToPayments",
                        null,
                        "/servicePayments",
                    ),
                );
                yield put(replace(`/transaction/${idTransactionPay}`));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "creditcard.pay.own.send" && data.code !== "COR055W" && data.code !== "COR065W") {
                const { idTransaction } = data;
                yield put(replace(`/transaction/${idTransaction}`));
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/desktop"));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "checks.block.send" && data.code !== "COR055W" && data.code !== "COR065W") {
                const { idTransaction } = data;
                yield put(
                    transactionsActions.setActionSecondaryByPath(
                        "checks.block.ticket.newRequest",
                        null,
                        "/blockChecks",
                    ),
                );
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (
                idActivity === "creditCard.otherBanks.payment.send" &&
                data.code !== "COR055W" &&
                data.code !== "COR065W"
            ) {
                const { idTransaction } = data;
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(
                    transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/creditCardOtherBanks"),
                );
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (
                idActivity === "nonRegisteredServicePayment.send" &&
                data.code !== "COR055W" &&
                data.code !== "COR065W"
            ) {
                const { idTransactionPay } = data.data;
                yield put(
                    transactionsActions.setActionSecondaryByPath(
                        "servicePayments.ticket.action.goToPayments",
                        null,
                        "/servicePayments",
                    ),
                );
                yield put(replace(`/transaction/${idTransactionPay}`));
                formikBag.setSubmitting(false);
                return;
            } else if (data.code === "API725W" && idActivity === "check.request.send") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("check.request.error.max.request.exceeded"),
                        "error",
                        ["form"],
                        false,
                    ),
                );
            } else {
                yield put({
                    type: types.SEND_FORM_DATA_FAILURE,
                    code: data.code,
                });
                yield put(notificationActions.showNotification(data.message, "error", ["form"], false));
                if (data?.data?.snackbar) {
                    yield put(notificationActions.showNotification(data?.data?.snackbar, "error", ["form"]));
                }
            }

            formikBag.setSubmitting(false);
        } else {
            if (idActivity === "reference.letter.send") {
                const { contentType, content, fileName, noValidWarning } = data.data;
                yield call(downloadRefLetter, contentType, content, fileName);
                yield put(replace("/desktop"));
                if (noValidWarning) {
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("reference.letter.warning.someAccountsAreNotValid", null, {
                                MONTHS: configUtils.getInteger("form.reference.letter.field.account.monthToBeValid"),
                            }),
                            "warning",
                            ["desktop"],
                        ),
                    );
                }
            } else if (idActivity === "beneficiary.create.send" || idActivity === "beneficiary.update.send") {
                if (values?.referencePage) {
                    yield put(beneficiaryActions.setBeneficiarySelected(values?.beneficiary));
                    yield put(replace(values.referencePage));
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(idActivity.replace("send", "success")),
                            "success",
                            [values.referencePage],
                            true,
                            1000,
                        ),
                    );
                } else {
                    yield put(replace("/frequentDestination"));
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(idActivity.replace("send", "success")),
                            "success",
                            ["frequentDestination", "desktop", "menu"],
                            true,
                            1000,
                        ),
                    );
                }
            } else if (idActivity === "token.pin.send") {
                formikBag.setSubmitting(false);
                console.log("Form sagas: token.pin.send values: ", values?.pinCode, formikBag, dataValues);
                yield put(softTokenActions.sendTokenPin(values?.pinCode));
                yield put(softTokenActions.setActivationStep("confirmation"));
                yield put(
                    routerActions.push({
                        pathname: "/activateSoftToken",
                    }),
                );
            } else if (idActivity === "paymentService.massive.send") {
                // pago de servicios masivo dirigo a pantalla de pagos reaizados
                const { listProcessed, total } = data.data;
                yield put(transactionsActions.setHiddenBackButton(false));
                yield put(payServiceActions.setListMassivePaymentProcessed(listProcessed, total));
                yield put(replace("/servicePayments/massive/result"));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "paymentService.send") {
                const { idTransactionPay } = data.data;
                yield put(replace(`/transaction/${idTransactionPay}`));
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(
                    transactionsActions.setActionSecondaryByPath(
                        "servicePayments.ticket.action.goToPayments",
                        null,
                        "/servicePayments",
                    ),
                );
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "nonRegisteredServicePayment.send") {
                const { idTransactionPay } = data.data;
                yield put(replace(`/transaction/${idTransactionPay}`));
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(
                    transactionsActions.setActionSecondaryByPath(
                        "servicePayments.ticket.action.goToPayments",
                        null,
                        "/servicePayments",
                    ),
                );
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "check.request.send") {
                const { idTransaction } = data;
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(
                    transactionsActions.setActionSecondaryByPath(
                        "check.request.newRequest",
                        null,
                        "/checksRequestForm",
                        false,
                        "replace",
                    ),
                );
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "serviceRegistration.send") {
                const {
                    values: { isNewPay },
                } = payload;
                const {
                    idTransaction,
                    data: { service },
                } = data;
                yield put(transactionsActions.setHiddenBackButton(true));

                if (isNewPay) {
                    yield put(transactionsActions.setInvertBtnStyle());
                    yield put(payServiceActions.setPreDataServicePay(service));
                    yield put(
                        transactionsActions.setActionSecondaryByPath(
                            "servicePayments.registeredServices.list.option.pay",
                            null,
                            `/servicePayments/registered/${service.category}/pay/${service.id}`,
                            true,
                        ),
                    );
                } else {
                    yield put(
                        transactionsActions.setActionSecondaryByPath(
                            "serviceRegistration.registerAnotherService",
                            null,
                            "/servicePayments/registerService/form",
                        ),
                    );
                }
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "editAliasService.send") {
                const { idTransaction } = data;
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/desktop"));
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "creditCard.otherBanks.enroll.send") {
                const { idTransaction } = data;
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(
                    transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/creditCardOtherBanks"),
                );
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "creditCard.otherBanks.delete.send") {
                const { idTransaction } = data;
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(
                    transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/creditCardOtherBanks"),
                );
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "creditCard.otherBanks.payment.send") {
                const { idTransaction } = data;
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(
                    transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/creditCardOtherBanks"),
                );
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "checks.block.validate") {
                const { idTransaction } = data;
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(
                    transactionsActions.setActionSecondaryByPath("check.request.newRequest", null, "/blockChecks"),
                );
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "checks.block.send") {
                const { idTransaction } = data;
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(
                    transactionsActions.setActionSecondaryByPath(
                        "checks.block.ticket.newRequest",
                        null,
                        "/blockChecks",
                    ),
                );
                yield put(replace(`/transaction/${idTransaction}`));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "creditcard.pay.own.send") {
                const { idTransaction } = data;
                yield put(replace(`/transaction/${idTransaction}`));
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/desktop"));
                formikBag.setSubmitting(false);
                return;
            } else if (idActivity === "creditcard.corporate.pay.send") {
                const { idTransaction } = data;
                yield put(replace(`/transaction/${idTransaction}`));
                yield put(transactionsActions.setHiddenBackButton(true));
                yield put(transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/desktop"));
                formikBag.setSubmitting(false);
                return;
            } else {
                let transaction;
                let usesJointAccount = false;
                if (activitiesNoTransactional.includes(idActivity)) {
                    transaction = {
                        idActivity,
                        data: dataValues,
                        idTransactionStatus: "FINISHED",
                    };
                } else {
                    const tid = data?.idTransaction || idTransaction;
                    const transactionResponse = yield call(form.readTransaction, tid);
                    transaction = transactionResponse.data.data?.transaction;
                    usesJointAccount = transactionResponse.data.data?.usesJointAccount;
                }

                if (idActivity === "report.renew.card.send" || idActivity === "report.replace.card.send") {
                    yield put(productActions.syncEnviromentProduct(false, null, false));
                    yield call(readCreditCardList, dataValues?.productId);
                }
                yield put({
                    type: types.SEND_FORM_SUCCESS,
                    transaction,
                    usesJointAccount,
                });

                if (
                    idActivity === "creditCard.recharge.creditCardLocal.send" ||
                    idActivity === "creditCard.payment.creditCardLocal.send" ||
                    idActivity === "transfers.payService.send" ||
                    idActivity === "creditCard.cashAdvance.send"
                ) {
                    // invalidate cache for creditcards
                    yield put(creditCardsActions.invalidateCache());
                }
                if (idActivity === "creditCard.payment.creditCardLocal.send") {
                    yield call(readCreditCardList, dataValues.creditCard);
                }

                if (idActivity !== "beneficiary.create.send" && values?.beneficiary) {
                    yield put(beneficiaryActions.setBeneficiarySelected(null));
                }
            }

            if (idActivity === "transfers.wally.send" || idActivity === "transfers.approve.wally.send") {
                yield put(wallyActions.userWallyRequest());
                if (!data?.data?.isRegistered) {
                    yield put(
                        notificationActions.showNotification(i18n.get("wally.error.register.transfer"), "error", [
                            "form",
                        ]),
                    );
                } else {
                    const responseWally = yield call(wally.getPendingTransactionsWallyQuantity);
                    if (responseWally.status !== 304 && responseWally.type === "I") {
                        yield put({
                            type: wallyTypes.REFRESH_PENDING_TRANSACTIONS_WALLY_QUANTITY_SUCCESS,
                            pendingTransactionsWallyQuantity: responseWally.data.data.pendingTransactionsWallyQuantity,
                        });
                    }
                }
            }

            // if (idActivity === "transfers.payService.send") {
            //     // guardo pago de servicio
            //     if (payload.values.savePayment) {
            //         const responseCreatePayService = yield call(
            //             payService.createPayService,
            //             payload.values.paymentUser,
            //         );
            //         if (responseCreatePayService && responseCreatePayService.status === 200) {
            //             yield put(payServiceActions.listPayServiceRequest(true));
            //         }
            //     }
            //     // edito cuando es recarga rapida
            //     if (
            //         payload.values?.savePayment === false &&
            //         payload.values?.isFastRecharge &&
            //         !payload.values.isLinkFastRecharge
            //     ) {
            //         const responseUpdatePayService = yield call(
            //             payService.updatePayService,
            //             payload.values.paymentUser,
            //         );
            //         if (responseUpdatePayService && responseUpdatePayService.status === 200) {
            //             yield put(payServiceActions.listPayServiceRequest(true));
            //         }
            //     }
            // }

            formikBag.setSubmitting(false);
            const notification = data?.data?.notification;
            if (notification) {
                yield put(notificationActions.showNotification(notification, "success", ["form"], false));
            }
        }

        const pathRedirectToWally = yield select((state) => wallySelectors.getPathRedirectToWally(state));
        if (values.isFromWally && pathRedirectToWally) {
            yield put(routerActions.replace(pathRedirectToWally));
            yield put(wallyActions.userWallyRequest());
            const transactionType = type === "W" ? "error" : "success";
            yield put(
                notificationActions.showNotification(
                    i18n.get(`forms.transferInternal.toWally.${transactionType}.notification`),
                    transactionType,
                    ["pendingTransactionWally", "form"],
                    false,
                ),
            );
        }
        if (payload.values.beneficiary?.saveBeneficiary) {
            if (data?.data?.beneficiarySaved) {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("transfer.beneficiary.succesfully.disclaimer"),
                        "success",
                        ["form"],
                        false,
                    ),
                );
            } else {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("transfer.create.beneficiary.error.disclaimer"),
                        "error",
                        ["form"],
                        false,
                    ),
                );
            }
        }
    }
}

function* readTransaction({ payload, isCanceledTransaction = false }) {
    const [, route, idTransaction, referenceNumber] = payload.pathname.split("/");
    if (route === "transaction") {
        let transactionResponse;
        if (idTransaction === "historic" && referenceNumber) {
            const detailTransaction = yield call(form.readTransactionHistoric, referenceNumber);
            if (detailTransaction.type === "W") {
                yield put({
                    type: types.READ_TRANSACTION_FAILURE,
                    notification: { type: "error", code: transactionResponse.data.code },
                });
            } else {
                const { transactionData } = detailTransaction?.data?.data;
                const transactionStatus = transactionData.TrnStatus === "FALLIDA" ? "FAILED" : "FINISHED";
                const currentTransactionWithData = {
                    idTransactionStatus: transactionStatus,
                    idTransaction: transactionData?.RegistryNumber,
                    idActivity: "historic.transaction",
                    data: { ...transactionData },
                };

                yield put({
                    type: types.READ_TRANSACTION_SUCCESS,
                    idForm: currentTransactionWithData.idForm,
                    transaction: currentTransactionWithData,
                    childrenTransactions: null,
                    parentTransaction: null,
                    formMetadata: { fieldList: [], formNameMap: [] },
                });
            }
        } else {
            transactionResponse = yield call(form.readTransaction, idTransaction);
            if (transactionResponse.type === "W") {
                yield put({
                    type: types.READ_TRANSACTION_FAILURE,
                    notification: { type: "error", code: transactionResponse.data.code },
                });
            } else {
                const {
                    transaction: { idForm, formVersion, idActivity },
                    usesJointAccount,
                } = transactionResponse.data.data;

                if (idForm === null) {
                    // const fromPending = payload?.state?.fromPending || false;
                    yield readTransactionFormIsNull(
                        idActivity,
                        transactionResponse,
                        idTransaction,
                        usesJointAccount,
                        isCanceledTransaction,
                        // fromPending,
                    );
                } else {
                    const formResponse = yield call(form.readForm, idForm, {
                        idTransactionToRead: idTransaction,
                        formVersion,
                    });

                    if (formResponse.type === "W") {
                        yield put({
                            type: types.READ_FORM_FAILURE,
                            notification: { type: "error", code: formResponse.data.code },
                        });
                    } else {
                        yield requestReadTransactionSuccess(transactionResponse, formResponse, usesJointAccount);
                    }
                }
            }
        }
    } else if (route === "transactionWally") {
        const dataTransactionWally = payload.state;
        const currentTransactionWithData = {
            idTransactionStatus: "FINISHED",
            idTransaction: dataTransactionWally?.transactionCode,
            idActivity: dataTransactionWally?.idActivity,
            data: { ...dataTransactionWally },
        };

        yield put({
            type: types.READ_TRANSACTION_SUCCESS,
            idForm: currentTransactionWithData.idForm,
            transaction: currentTransactionWithData,
            childrenTransactions: null,
            parentTransaction: null,
            formMetadata: { fieldList: [], formNameMap: [] },
        });
    }
}

function* requestReadTransactionSuccess(
    transactionResponse,
    formResponse = null,
    usesJointAccount = false,
    isCanceledTransaction = false,
) {
    const { children, parent } = transactionResponse.data.data;
    let { transaction, emailUserCreator } = transactionResponse.data.data;
    transaction = { ...transaction, dispatcher: transactionResponse.data.data.dispatcher };
    yield put({
        type: types.READ_TRANSACTION_SUCCESS,
        idForm: transaction.idForm,
        transaction,
        childrenTransactions: children,
        parentTransaction: parent,
        formMetadata: formResponse?.data?.data?.form || { fieldList: [], formNameMap: [] },
        usesJointAccount,
        emailUserCreator,
    });
    if (isCanceledTransaction) {
        const titleKind = getTransactionKind(transaction?.idActivity);
        yield put(
            notificationActions.showNotification(
                i18n.get(`forms.cancelTransaction.${titleKind}.confirmationMessage`),
                "success",
                ["form", "desktop"],
            ),
        );
    }
}

function* readTransactionFormIsNull(
    idActivity,
    transactionResponse,
    idTransaction,
    usesJointAccount,
    isCanceledTransaction = false,
    fromPending = false,
) {
    if (idActivity in administrationTicketRoutes) {
        // const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
        // yield put(routerActions.replace(administrationTicketRoutes[idActivity](idTransaction, administrationScheme)));
        // yield put({
        //     type: types.READ_TRANSACTION_SUCCESS,
        //     idForm: currentTransactionWithData.idForm,
        //     transaction: currentTransactionWithData,
        //     childrenTransactions: null,
        //     parentTransaction: null,
        //     formMetadata: { fieldList: [], formNameMap: [] },
        // });
        // yield requestReadTransactionSuccess(transactionResponse, null, usesJointAccount, isCanceledTransaction);

        const { transaction, parent, children } = transactionResponse.data.data;
        yield put({
            type: types.READ_TRANSACTION_TICKET_SUCCESS,
            transaction,
            parentTransaction: parent,
            childrenTransactions: children,
        });
        yield put(replace(`/administration/${idTransaction}/ticket`, { fromPending }));
    } else {
        yield requestReadTransactionSuccess(transactionResponse, null, usesJointAccount, isCanceledTransaction);
    }
}

function* readTransactionFromBackoffice({ payload }) {
    if (payload.pathname === "/forms/backoffice/ticket") {
        const { query } = queryString.parseUrl(payload.search);
        const exchangeToken = query._exchangeToken;

        const { data } = yield call(form.readTransactionFromBackoffice, exchangeToken);

        yield put({
            type: types.READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS,
            idForm: data.data.transaction.idForm,
            data: data.data.transactionData,
            formMetadata: data.data.form,
            transaction: data.data.transaction,
        });
    }
}

function* saveDraftTransaction({ payload }) {
    const { idForm, data, idActivityDraft, idTransaction } = payload;
    const response = yield call(form.saveDraft, idForm, data, idActivityDraft, idTransaction);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("forms.saveDraft.errorMessage"), "error", ["form"]));
        yield put({ type: types.SAVE_DRAFT_FAILURE });
    } else {
        const confirmationMessage = i18n.get("forms.saveDraft.confirmationMessage");
        yield put(routerActions.push("/desktop"));
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["accounts", "desktop"]));
        yield put({ type: types.SAVE_DRAFT_SUCCESS, idForm, data: response.data.data });
    }
}

function* cancelTransactionPre({ payload }) {
    const { idActivity, idTransaction, idForm, fromPending } = payload;
    const { type, data } = yield call(form.listCredentialsGroups, idForm, idActivity);

    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.cancelTransaction.pre.error"), "error", ["form"]),
        );
        yield put({
            type: types.CANCEL_TRANSACTION_PRE_ERROR,
        });
    } else {
        const { groups } = data.data;
        yield put(push(`/administration/${idTransaction}/reject`, { fromPending }));
        yield put({
            type: types.CANCEL_TRANSACTION_PRE_SUCCESS,
            credentialsGroups: groups,
        });
    }
}

function* cancelTransaction({ payload }) {
    const { idForm, scheduled, idTransaction, credentials, formikBag } = payload;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

    // const { scheduled } = formikBag.props;
    const {
        data: { code, data },
        type,
    } = yield call(
        form.cancelTransaction,
        idTransaction,
        {
            ...credentialsWithUnderscore,
        },
        idForm,
    );
    if (type === "W") {
        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }
        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["form"],
                ),
            );
            return;
        }
        const hasIncorrectCredentials = Object.keys(credentials).some((key) => data[key]);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(data);
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("forms.cancelTransaction.errorMessage"), "error", [
                    "form",
                ]),
            );
        }
    } else {
        yield put({
            type: types.CANCEL_TRANSACTION_SUCCESS,
        });
        // yield put(
        //     formActions.readTransaction(
        //         window.location.pathname.includes("/transaction")
        //             ? window.location
        //             : { pathname: `/transaction/${idTransaction}` },
        //         scheduled,
        //     ),
        // );
        yield put(transactionsActions.setFromAction());
        yield put(replace(`/administration/${idTransaction}/ticket`, { fromPending: true, fromAction: true }));
    }

    formikBag.setSubmitting(false);
}

function* downloadTicket({ idTicket, format, idForm }) {
    const { type, data } = yield call(form.downloadTicket, idTicket, format, idForm);
    if (type === "W") {
        yield put({ type: types.DOWNLOAD_TICKET_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_TICKET_SUCCESS });
    }
}

function* shareTicket({ idTicket, format, idForm }) {
    const { type, data } = yield call(form.downloadTicket, idTicket, format, idForm);
    const fileName = "Transaction Ticket";
    if (type === "W") {
        yield put({ type: types.SHARE_TICKET_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    } else {
        yield put({ type: types.SHARE_TICKET_SUCCESS });
        let contentData = `data:text/pdf;base64,${data.data.content}`;
        if (getMobileOS(getDisplay()) === "iOS") {
            contentData = `data:text/pdf:${fileName}'.pdf;base64,${data.data.content}`;
        }
        let options = {
            files: [contentData],
        };

        if (isMobileNativeFunc() && isAndroidPlatform()) {
            options = { ...options, message: fileName, subject: fileName };
        }

        window.plugins.socialsharing.shareWithOptions(options, null, null);
    }
}

function* modifyTransaction({ idTransaction }) {
    const response = yield call(form.moveToDraftTransaction, idTransaction);
    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("forms.modifyTransaction.errorMessage"), "error", ["form"]),
        );
        yield put({ type: types.CANCEL_TRANSACTION_FAILURE });
    } else {
        yield put(formActions.readTransaction({ pathname: `/transaction/${idTransaction}` }));
    }
}

function* signTransactionPreview({ payload }) {
    const { idForm, idActivity, idTransaction, ticketData, values, fromPending } = payload;
    const credentialsResponse = yield call(form.listCredentialsGroups, idForm, idActivity);
    const objectData = {
        type: types.SIGN_TRANSACTION_PREVIEW_SUCCESS,
        idForm,
        credentialsGroups: credentialsResponse.data.data.groups,
        submitAction: formActions.signTransaction,
        submitActionParams: { idForm, idActivity, idTransaction, values },
        ticketData,
        mode: "preview",
        fromPending,
    };

    if (ACTIVITIES_WITHOUT_PREVIEW_STEP.indexOf(idActivity) === -1) {
        const { type, data } = yield call(
            form.signPreview,
            idForm,
            idActivity.replace(".send", ".preview"),
            idTransaction,
        );

        if (type === "W") {
            yield put({
                type: types.SIGN_TRANSACTION_PREVIEW_FAILURE,
                code: data.code,
            });
            if (Object.keys(data.data).length) {
                yield put(notificationActions.showNotification(Object.values(data.data)[0], "error", ["form"]));
            } else {
                yield put(notificationActions.showNotification(data.message, "error", ["form"]));
            }
        } else if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
            yield put({ ...objectData, previewData: data });
        } else {
            yield put({ ...objectData, previewData: data.data });
        }
    } else {
        yield put(objectData);
    }
}

function* signTransaction({ payload }) {
    const { idForm, idActivity, idTransaction, credentials, formikBag } = payload;
    const { data, type } = yield call(form.sign, idForm, idActivity, idTransaction, credentials);

    if (type === "W") {
        if (data.code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }

        if (data.code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["form"],
                ),
            );
            return;
        }

        const hasIncorrectCredentials = Object.keys(credentials).some((key) => data.data[key]);

        if (hasIncorrectCredentials) {
            formikBag.setErrors(adjustIdFieldErrors(data.data));
            yield put({
                type: types.SEND_FORM_CREDENTIAL_FAILURE,
                code: data.code,
            });
        } else {
            yield put({
                type: types.SIGN_TRANSACTION_PREVIEW_FAILURE,
                code: data.code,
            });
            yield put(notificationActions.showNotification(data.message, "error", ["form"]));
        }

        formikBag.setSubmitting(false);
    } else {
        const transactionResponse = yield call(form.readTransaction, idTransaction);
        const { transaction, usesJointAccount } = transactionResponse.data.data;
        yield put({
            type: types.SEND_FORM_SUCCESS,
            transaction,
            idTransaction,
            usesJointAccount,
        });
        formikBag.setSubmitting(false);

        // if (data.code && data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE) {
        //     yield put(
        //         notificationActions.showNotification(data.message, "warning", ["form", "administration", "ticket"]),
        //     );
        // }
        yield put(
            notificationActions.showNotification(i18n.get("transaction.signed.message"), "success", [
                "form",
                "administration",
                "ticket",
            ]),
        );
        //  else {
        //     yield put(
        //         notificationActions.showNotification(i18n.get("forms.send.confirmationMessage"), "success",["form", "desktop", "ticket"]),
        //     );
        // }

        // const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
        // yield put(routerActions.replace(administrationTicketRoutes[idActivity](idTransaction, administrationScheme)));
        // yield put(routerActions.replace()));
        yield put(replace(`/administration/${idTransaction}/ticket`, { fromPending: true, fromAction: true }));
    }
}

function* listTransactionLinesRequest({ payload }) {
    const response = yield call(file.listTransactionLines, payload);
    if (response.type === "W") {
        yield put(transactionLinesActions.listTransactionLinesFailure());
    } else {
        yield put(transactionLinesActions.listTransactionLinesSuccess(response.data.data));
    }
}

function* logout({ code }) {
    if (code === "API010W") {
        yield put(sessionActions.logout());
        yield put(notificationActions.showNotification(i18n.get("returnCode.API010W"), "error", ["externalLayout"]));
    }
}

function* preForm({ values, alertOpts }) {
    const { idActivity, requestData } = values;
    const { data } = yield call(form.pre, idActivity, requestData);
    if (data.error) {
        yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
    } else {
        if (data.code === "API400W") {
            // Error message to show alert toast configurable
            const message = alertOpts?.message || i18n.get("returnCode.API040E");
            const level = alertOpts?.level || "warning";
            const scope = alertOpts?.scope || "desktop";
            const location = alertOpts?.location; // ex: /desktop
            if (location) {
                yield put(routerActions.replace(location));
            }
            yield put(notificationActions.showNotification(message, level, [scope], null, null, null, false));
        }

        yield put(formActions.preFormSuccess(null, data.data));
    }
}

function* preCustomForm({ permissionList }) {
    const { data } = yield call(form.preCustomForm, permissionList);
    if (data.error) {
        yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
    } else {
        const { formProducts, configurationParams, transactionData } = data?.data;
        yield put(formActions.preCustomFormSuccess(formProducts, configurationParams, transactionData));
    }
}

function* getBankDescriptionData({ codeBank, codeBankType, idComponent, setFieldValue }) {
    const response = yield call(form.getBankDescriptionData, codeBank, codeBankType);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({
            type: types.BANK_DESCRIPTION_FAILURE,
        });
    } else {
        const { type, data } = response;
        if (type === "W") {
            if (data && data.code && data.code === "API532W") {
                yield put(
                    notificationActions.showNotification(i18n.get("bank.description.notfound"), "error", [
                        "form",
                        "menu",
                        "createFrequentDestination",
                        "frequentDestination.detailModifyDelete",
                    ]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]),
                );

                yield put(routerActions.replace("/desktop"));
            }
            yield put({
                type: types.BANK_DESCRIPTION_FAILURE,
            });
        } else {
            const responseBank = response.data?.data?.bankDescriptionData;
            yield put({
                type: types.BANK_DESCRIPTION_SUCCESS,
                payload: responseBank ? { ...responseBank, idComponent, setFieldValue } : responseBank,
            });
        }
    }
}

function* confirmMovementPendingWally({ idActivity }) {
    const accountWally = yield select((state) => wallySelectors.getAccountWally(state));
    const movement = yield select((state) => wallySelectors.getSelectedBeneficiary(state));
    const values = {
        debitAccountId: accountWally?.idProduct,
        creditAcctId: movement.accountId,
        scheduler: {
            selectedOption: schedulerUtils.TODAY,
        },
        amount: movement.amount,
        creditReference: movement.creditReference,
        transactionDate: movement.transactionDate,
        xferId: movement.transactionXFerId,
        beneficiaryFullName: movement.fullName,
    };

    const response = yield call(form.listCredentialsGroups, null, idActivity);

    yield put({
        type: types.PREVIEW_FORM_SUCCESS,
        idForm: undefined,
        credentialsGroups: response.data.data.groups,
        submitAction: formActions.sendForm,
        submitActionParams: {
            idForm: undefined,
            idActivity,
            idTransaction: undefined,
            values,
        },
        previewData: { ...values },
    });

    yield put({
        type: types.SET_MODE,
        mode: "preview",
        prevMode: "edit",
    });

    yield put(formActions.setData({ ...values }));
}

function* confirmSuspendCheck({ idActivity }) {
    const dataCheckToSuspend = yield select((state) => checksSelectors.getDataCheckToSuspend(state));

    const activeEnvironment = yield select((state) => sessionSelectors.getActiveEnvironment(state));
    const loggedUser = yield select((state) => sessionSelectors.getUser(state));

    const commissionAmount =
        configUtils.get(`checks.suspend.commission.amount.${activeEnvironment?.type}.${loggedUser?.bankType}`) || "0";

    const values = {
        ...dataCheckToSuspend,
        commissionTotalAmount: commissionAmount,
        scheduler: {
            selectedOption: schedulerUtils.TODAY,
            transactionDate: moment(),
        },
    };

    const response = yield call(form.listCredentialsGroups, null, idActivity);

    yield put({
        type: types.PREVIEW_FORM_SUCCESS,
        idForm: undefined,
        credentialsGroups: response.data.data.groups,
        submitAction: formActions.sendForm,
        submitActionParams: {
            idForm: undefined,
            idActivity,
            idTransaction: undefined,
            values,
        },
        previewData: { ...values },
    });

    yield put({
        type: types.SET_MODE,
        mode: "preview",
        prevMode: "edit",
    });

    yield put(formActions.setData({ ...values }));
}

function* deleteCreditCardOtherBank({ idActivity }) {
    const creditCardData = yield select((state) => creditCardOtherBankSelectors.getCreditCardData(state));

    const values = {
        idCreditCard: creditCardData.id,
        scheduler: {
            selectedOption: schedulerUtils.TODAY,
            transactionDate: moment(),
        },
    };

    const response = yield call(form.listCredentialsGroups, null, idActivity);

    yield put({
        type: types.PREVIEW_FORM_SUCCESS,
        idForm: undefined,
        credentialsGroups: response.data.data.groups,
        submitAction: formActions.sendForm,
        submitActionParams: {
            idForm: undefined,
            idActivity,
            idTransaction: undefined,
            values,
        },
        previewData: { ...values },
    });

    yield put({
        type: types.SET_MODE,
        mode: "preview",
        prevMode: "edit",
    });

    yield put(formActions.setData({ ...values }));
}

function* confirmPaymentFastRecharge({ idActivity }) {
    const preData = yield select((state) => formSelectors.getPreData(state));
    const creditCards = yield select((state) => creditCardSelectors.getList(state));
    const queryBill = yield select((state) => payServiceSelectors.getQueryBill(state));
    const paymentUser = queryBill?.paymentUser;
    const accountSelected = preData?.debitAccountList?.filter((el) => el.idProduct === paymentUser?.idProduct);
    const creditCardSelected = creditCards?.filter((el) => el.idProduct === paymentUser?.idProduct);
    const tax = queryBill?.biller.hasTax ? queryBill?.biller.tax : 0;
    const totalTax = (tax * paymentUser.amountQuantity) / 100;
    let isDataCharged = false;

    const values = {
        SubTotal: { currency: paymentUser.amountCurrency, quantity: paymentUser.amountQuantity },
        TotalTax: { currency: paymentUser.amountCurrency, quantity: totalTax },
        TotalPmt: { currency: paymentUser.amountCurrency, quantity: paymentUser.amountQuantity },
        TotalBalance: { currency: paymentUser.amountCurrency, quantity: paymentUser.amountQuantity + totalTax },
        amount: { currency: paymentUser.amountCurrency, quantity: paymentUser.amountQuantity + totalTax },
        typeAccount: paymentUser?.productType,
        paymentUser: {
            ...paymentUser,
            billInqRq: JSON.stringify(paymentUser.billInqRq),
            pmtUtilAddRq: JSON.stringify(paymentUser.pmtUtilAddRq),
        },
        biller: queryBill?.biller,
        savePayment: false,
        isFastRecharge: false,
        isLinkFastRecharge: true,
        pmtUtilAddRq: JSON.stringify(paymentUser.pmtUtilAddRq),
        scheduler: {
            selectedOption: schedulerUtils.TODAY,
            transactionDate: moment(),
            valueDate: moment(),
        },
    };
    if (paymentUser?.productType === "debitAccount") {
        values.creditCard = null;
        values.creditCardData = null;
        values.debitAccount = paymentUser?.idProduct;
        if (accountSelected.length > 0) {
            values.debitAccountData = accountSelected[0];
            isDataCharged = true;
        }
    } else {
        values.creditCard = paymentUser?.idProduct;
        values.debitAccount = null;
        values.debitAccountData = null;
        if (creditCardSelected.length > 0) {
            values.creditCardData = creditCardSelected[0];
            isDataCharged = true;
        }
    }

    if (isDataCharged) {
        const response = yield call(form.listCredentialsGroups, null, idActivity);

        yield put({
            type: types.PREVIEW_FORM_SUCCESS,
            idForm: undefined,
            credentialsGroups: response.data.data.groups,
            submitAction: formActions.sendForm,
            submitActionParams: {
                idForm: undefined,
                idActivity,
                idTransaction: undefined,
                values,
            },
            previewData: { ...values },
        });

        yield put({
            type: types.SET_MODE,
            mode: "preview",
            prevMode: "edit",
        });

        yield put(formActions.setData({ ...values }));
    }
    yield put(payServiceActions.isFetchingFastRecharge(false));
}

function* downloadImageTicket({ idTicket, image }) {
    const contentImg = image.current;

    const canvas = yield html2canvas(contentImg);
    const imgData = canvas.toDataURL("image/png");

    const { type, data } = yield call(form.downloadImageTicket, idTicket, imgData);
    if (type === "W") {
        yield put({ type: types.DOWNLOAD_IMG_TICKET_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["ticket"]));
    } else {
        const { content, fileName } = data.data;

        downloadPdf(fileName, content);

        yield put({ type: types.DOWNLOAD_IMG_TICKET_SUCCESS });
    }
}

function* shareImageTicket({ idTicket, image }) {
    const contentImg = image.current;

    const canvas = yield html2canvas(contentImg);
    const imgData = canvas.toDataURL("image/png");
    const { type, data } = yield call(form.downloadImageTicket, idTicket, imgData);
    const fileName = "Transaction Ticket";
    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transaction/details"]),
        );
    } else {
        let contentData = `data:text/pdf;base64,${data.data.content}`;
        if (getMobileOS(getDisplay()) === "iOS") {
            contentData = `data:text/pdf:${fileName}'.pdf;base64,${data.data.content}`;
        }
        let options = {
            files: [contentData],
        };

        if (isMobileNativeFunc() && isAndroidPlatform()) {
            options = { ...options, message: fileName, subject: fileName };
        }

        window.plugins.socialsharing.shareWithOptions(options, null, null);
    }
}

function* preValidationsForm({ activities }) {
    const { data } = yield call(form.preValidationsForm, activities);
    if (data.error) {
        yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
    } else {
        yield put(formActions.preValidationsSuccess(data?.data));
    }
}
