import React from "react";
import { shape, string } from "prop-types";
import * as userUtil from "pages/administration/utils/users";
import * as signaturesUtil from "../../utils/signaturesScheme";
import * as groupUtil from "../../utils/groups";

const AdditionalDataTicket = ({ transactionData, groups }) => {
    const renderAdditionalDataTicket = () => {
        const { idActivity, data } = transactionData;
        switch (idActivity) {
            case "administration.signatures.create.send":
            case "administration.signatures.non.monetary.create.send":
            case "administration.signatures.modify.send":
            case "administration.signatures.delete.send": {
                const { signatureLevelsCounts, signatureType, topAmount, functionalGroups } = data;
                return signaturesUtil.renderAdditionalData(
                    "advanced",
                    signatureLevelsCounts,
                    signatureType,
                    topAmount,
                    functionalGroups,
                    groups || [],
                );
            }
            case "administration.advanced.group.create.send":
            case "administration.advanced.group.modify.send": {
                const { description, permissions } = data;
                return groupUtil.renderAdditionalData(description, permissions, groups || []);
            }
            case "administration.medium.modify.channels.send": {
                const { fullName, documentType, documentNumber, capsInfo, capsProd, products } = data;
                return (
                    <>
                        {userUtil.renderAdditionalDataLimits(fullName || "-", documentType, documentNumber || "-")}
                        {userUtil.renderAdditionalDataChannelCaps(capsInfo)}
                        {capsProd &&
                            capsProd?.length &&
                            products && capsProd?.length > 0 &&
                            userUtil.renderAdditionalDataCaps(capsProd, products)}
                    </>
                );
            }
            case "administration.medium.modify.contacts.send": {
                const { emailUpdate, mobileNumberUpdate } = data;
                return userUtil.renderAdditionalDataContacts(emailUpdate, mobileNumberUpdate);
            }
            case "administration.users.invite.send": {
                const { docType, docNumber, email, prefix, mobilePhone, signatureLevel, groups: roles } = data;
                return userUtil.renderAdditionalData(
                    `${docType} ${docNumber}`,
                    email,
                    `+${prefix} ${mobilePhone}`,
                    signatureLevel,
                    roles,
                );
            }
            case "administration.medium.modify.signature.send": {
                const { signatureLevel } = data;
                return userUtil.renderAdditionalDataSignature(signatureLevel);
            }
            case "administration.users.delete.send":
            case "administration.users.blockunblock.send": {
                const { userNameList: users } = data;
                if (users && users.length > 0) {
                    return userUtil.renderNameDocumentAction(users);
                }
                return <></>;
            }
            default:
                return <></>;
        }
    };

    return renderAdditionalDataTicket();
};

AdditionalDataTicket.propTypes = {
    transactionData: shape({
        data: shape({}),
        idActivity: string,
        idTransaction: string,
    }).isRequired,
};

export default AdditionalDataTicket;
